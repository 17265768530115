import { useEffect, useState } from 'react';
import { Modal, Form, Button, Row, Col } from 'reactstrap';
import { apiMedia } from 'global/api.global';
import '../global/GlobalCSS.css';
import { queryKeyes } from 'shared/queryKeys';
import { useQuery } from 'react-query';
import { ciiEventDocuments } from 'VesselMaster/vesselMaster.hooks';
import CIIAdjustSingleFile from './CIIAdjustSingleFile';
import { errorToast, infoToast, successToast } from './Toasts';
import Check from '../Media/check.png';
import { MediaBaseURL } from 'global/api.global';

interface event {
    id: number,
    cii_adjustment: number,
    vessel_reporting_cii_adjustment: number,
    cii_adjustment_name: string
}

interface CIIAdjustmentFileUploadType {
    state: boolean,
    event: event,
    togBackDropCIIUpload: any
}

const CIIAdjustmentFileUpload = ({
    state,
    event,
    togBackDropCIIUpload
}: CIIAdjustmentFileUploadType) => {
    /** State variables */
    const [modalState, setModalState] = useState(state);
    /** State variables end */

    /**
     * Documents for the CII Event
     */
    let { data: documentsSubmit, isLoading: documentsLoading, isError: documentsError } = useQuery(
        [queryKeyes.vessel.CIIEventsSupportingFileData.key, event.id],
        async () => {
            return await ciiEventDocuments(event.id)
        }, {
        staleTime: Infinity
    });

    /** useEffect */
    useEffect(() => {
        setModalState(state);
    }, [state])
    //do we need this code?
    // useEffect(() => {
    //     const updateDosumentsSubmit = async () => {
    //         await apiGlobal
    //             .get(`vessel_reporting_cii_adjustment_supporting_file/get_supporting_file_data_by_vessel_reporting_cii_adjustment/?vessel_reporting_cii_adjustment_id=${event.id}`)
    //             .then(response => {
    //                 documentsSubmit = response.data;
    //                 return response.data;
    //             }).catch(err => {
    //                 if (errResponse.includes(err.response.status)) {
    //                     errorToast("Internal error occured, please contact the admin");
    //                 }
    //             })
    //     }; updateDosumentsSubmit()
    // }, [event.id])
    /** useEffect end */

    /** Open close modal */
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_backdrop() {
        setModalState(!modalState);
        removeBodyCss();
    }

    /** File upload function */
    const CIIAdjustmentFileUploadSubmit = async () => {
        try {
            if (documentsSubmit.length > 0) {
                const response = await Promise.all(documentsSubmit.map(async (submitFile: any, index: number) => {
                    const formData = new FormData();
                    Object.keys(submitFile).forEach(key => {
                        formData.append(key, submitFile[key]);
                    });
                    if (submitFile?.vessel_reporting_cii_adjustment_supporting_file_name && submitFile?.file_status === true) {
                        infoToast("Uploading! Please wait...")
                        await apiMedia.put(`/vessel_reporting_cii_adjustment_supporting_file/${submitFile.id}/`, formData);
                    }
                    return submitFile;
                }));
                if (response) {
                    successToast("Success! Files uploaded!");
                    togBackDropCIIUpload();
                } else {
                    errorToast("Error! Unable to upload file(s)");
                }
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Modal
            size='md'
            isOpen={modalState}
            toggle={() => {
                tog_backdrop();
            }}
            backdrop={"static"}
            id="staticBackdrop"
        >
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                    Upload documents of {event.cii_adjustment_name} event
                </h5>
                <button
                    onClick={() => {
                        setModalState(false);
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Form className="needs-validation"
                    onSubmit={(e) => {
                        e.preventDefault();
                        CIIAdjustmentFileUploadSubmit();
                        return false;
                    }}>

                    {(documentsLoading) &&
                        <Row>
                            <Col><div>
                                <div className="alert-border-left mb-0 alert alert-info alert-dismissible fade show" role="alert">
                                    <button type="button" className="btn-close" aria-label="Close"></button>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Loading Document List!
                                </div>
                            </div>
                            </Col>
                        </Row>
                    }

                    {(documentsError) &&
                        <Row>
                            <Col><div>
                                <div className="alert-label-icon label-arrow alert alert-danger alert-dismissible fade show" role="alert">
                                    <button type="button" className="btn-close" aria-label="Close"></button>
                                    <i className="mdi mdi-block-helper label-icon"></i>
                                    <strong>Danger</strong> - Unable to process request!
                                </div>
                            </div>
                            </Col>
                        </Row>
                    }
                    {((!documentsLoading && !documentsError) && documentsSubmit) && (
                        documentsSubmit.map((list: any, index: number) => {
                            return (
                                <div key={index} className='mb-5'>
                                    <Row key={index}>
                                        <Col sm={9}>
                                            <h6 className='mb-0'>{list.cii_document_name}
                                                {list.vessel_reporting_cii_adjustment_supporting_file_name !== null &&
                                                    <img src={Check} alt='Check' className='ml-1' height={30} width={30} />
                                                }
                                            </h6>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6}>
                                            <CIIAdjustSingleFile uploadFile={list} />
                                            {list.vessel_reporting_cii_adjustment_supporting_file_name !== null &&
                                                <a href={`${MediaBaseURL}${list.vessel_reporting_cii_adjustment_supporting_file_name}`}
                                                    target="_blank" rel="noopener noreferrer"
                                                >
                                                    {`${list.cii_document_name} document`}
                                                </a>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }))
                    }
                    <Row>
                        <Col>
                            <div className=" mt-3 mb-5">
                                <Button type="submit" color='primary' className='pos-end'>Save</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal >
    )
}
export default CIIAdjustmentFileUpload