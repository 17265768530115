import React, { useState } from 'react'
import { Tooltip } from 'reactstrap';

export default function ErrorTooltip(props: any) {
    const [tbottom, settbottom] = useState(false);
    const { message, target, open } = props
    return (
        <Tooltip
            placement="bottom"
            id={target}
            isOpen={open}
            target={target}
            toggle={() => {
                settbottom(!tbottom);
            }}
            style={{ background: '#fd625e' }}
            className='redArrow'
        >
            <p style={{ color: 'white' }} className='mb-0'>{message}</p>
        </Tooltip>
    )
}
