import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import React, { useState } from 'react';
import { Card, CardHeader, Col, Row, TabContent, TabPane } from 'reactstrap';
import { EventPrecedence, ReportingTypeConstant } from 'shared/constants';
import AnchorageDetailsComponent from './partials/AnchorageDetailsComponent';
import PilotDetailsComponent from './partials/PilotDetailsComponent';
import TugsUsageComponent from './partials/TugsUsageComponent';
import OtherDetailsComponent from './partials/OtherDetailsComponenet';
import PortActivitiesComponent from './partials/PortActivitiesComponent';

interface LfondsAddInformationType {
    activeTab: number,
    toggleTab: any,
    record: any
    ReportID: number,
    VesselID: number,
    VoyageID: number,
    vessel: any
}

const LfondsAddInformation = ({
    activeTab,
    toggleTab,
    record,
    ReportID,
    VesselID,
    VoyageID,
    vessel
}: LfondsAddInformationType) => {
    /** State variables */
    const [activeAddTab, setactiveAddTab] = useState(1);
    /** State variables end */

    /** Change wizard's tabs */
    const toggleAddTab = (tab: number) => {
        if (activeAddTab !== tab) {
            if (tab >= 1 && tab <= (record && record?.reporting_type === ReportingTypeConstant.ATSEA ? 1 :
                ((record?.event_precedence_id === EventPrecedence.DROP_ANCHOR) ||
                    (record?.event_precedence_id === EventPrecedence.HEAVE_ANCHOR)) ? 5 :
                    ((record?.event_precedence_id === EventPrecedence.DEPARTURE_FROM_BERTH) ||
                        (record?.event_precedence_id === EventPrecedence.DEPARTURE_FROM_DRIFTING_PORT)) ? 4 : 3)) {
                setactiveAddTab(tab);
            }
        }
    }
    return (
        <Card className='deck-card-body mb-0 border-0'>
            <CardHeader className='px-0 py-2'>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Additional Information</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            <div id="basic-pills-wizard" className="twitter-bs-wizard">
                <TabContent activeTab={activeAddTab}>
                    <TabPane tabId={record && ((record?.event_precedence_id === EventPrecedence.DROP_ANCHOR) ||
                        (record?.event_precedence_id === EventPrecedence.HEAVE_ANCHOR)) ? 1 : 0}>
                        <AnchorageDetailsComponent
                            record={record}
                            toggleTab={toggleTab}
                            activeTab={activeTab}
                            activeAddTab={activeAddTab}
                            toggleAddTab={toggleAddTab}
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                        />
                    </TabPane>
                    <TabPane tabId={record && record?.reporting_type === ReportingTypeConstant.ATSEA ? 0 :
                        ((record?.event_precedence_id === EventPrecedence.DROP_ANCHOR) ||
                            (record?.event_precedence_id === EventPrecedence.HEAVE_ANCHOR)) ? 2 : 1}>
                        <PilotDetailsComponent
                            record={record}
                            activeAddTab={activeAddTab}
                            toggleAddTab={toggleAddTab}
                            activeTab={activeTab}
                            toggleTab={toggleTab}
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                        />
                    </TabPane>
                    <TabPane tabId={record && record?.reporting_type === ReportingTypeConstant.ATSEA ? 0 :
                        ((record?.event_precedence_id === EventPrecedence.DROP_ANCHOR) ||
                            (record?.event_precedence_id === EventPrecedence.HEAVE_ANCHOR)) ? 3 : 2}>
                        <TugsUsageComponent
                            activeAddTab={activeAddTab}
                            toggleAddTab={toggleAddTab}
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                        />
                    </TabPane>
                    <TabPane tabId={record && record?.reporting_type === ReportingTypeConstant.ATSEA ? 0 :
                        ((record?.event_precedence_id === EventPrecedence.DROP_ANCHOR) ||
                            (record?.event_precedence_id === EventPrecedence.HEAVE_ANCHOR)) ? 4 :
                            ((record?.event_precedence_id === EventPrecedence.DEPARTURE_FROM_BERTH) ||
                                (record?.event_precedence_id === EventPrecedence.DEPARTURE_FROM_DRIFTING_PORT)) ? 3 : 0}>
                        <PortActivitiesComponent
                            activeAddTab={activeAddTab}
                            toggleAddTab={toggleAddTab}
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                        />
                    </TabPane>
                    <TabPane tabId={record && record?.reporting_type === ReportingTypeConstant.ATSEA ? 1 :
                        ((record?.event_precedence_id === EventPrecedence.DROP_ANCHOR) ||
                            (record?.event_precedence_id === EventPrecedence.HEAVE_ANCHOR)) ? 5 :
                            ((record?.event_precedence_id === EventPrecedence.DEPARTURE_FROM_BERTH) ||
                                (record?.event_precedence_id === EventPrecedence.DEPARTURE_FROM_DRIFTING_PORT)) ? 4 : 3}>
                        <OtherDetailsComponent
                            activeAddTab={activeAddTab}
                            toggleAddTab={toggleAddTab}
                            activeTab={activeTab}
                            toggleTab={toggleTab}
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                            record={record}
                            vessel={vessel}
                        />
                    </TabPane>
                </TabContent>
            </div>
        </Card>
    )
}

export default LfondsAddInformation