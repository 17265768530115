import ErrorTooltip from 'Components/ErrorTooltip';
import Loading from 'Components/Loading';
import { successToast } from 'Components/Toasts';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import env from 'environment/env';
import { Field, FieldProps, Formik } from 'formik';
import { dataTimeFormat } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import React, { useEffect } from 'react'
import { useQuery } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, Input, Label, Row } from 'reactstrap'
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { loadProactiveAddDetailsObject, loadProactivePreviousAddDetailsObject } from 'VesselMaster/vesselMaster.hooks';
import * as Yup from 'yup';

const OtherDetailsComponent = ({
    activeTab,
    toggleTab,
    ReportID,
    VesselID,
    VoyageID,
    activeAddTab,
    toggleAddTab,
    record,
    lastRecord
}: any) => {
    /** useQueries */
    /** Additional details object used for edit */
    const { data: AdditionalDetailsObject, isLoading: AdditionalDetailsObjectLoading, isError: AdditionalDetailsObjectError } = useQuery(
        [queryKeyes.vessel.ProactiveAddDetailsObject.key, VesselID, ReportID],
        async () => {
            return await loadProactiveAddDetailsObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** Additional details' previous object used for edit */
    const { data: PreviousAddDetailsObject, isLoading: PreviousAddDetailsObjectLoading, isError: PreviousAddDetailsObjectError } = useQuery(
        [queryKeyes.vessel.ProactivePreviousAddDetailsObject.key, VesselID, ReportID],
        async () => {
            return await loadProactivePreviousAddDetailsObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** useQueries end */

    /** Assign values to Additional info's initial object */
    const getInitialAdditionalInfo = () => {
        if (AdditionalDetailsObject && AdditionalDetailsObject[0]?.id > 0) {
            return AdditionalDetailsObject[0];
        } else {
            return ({
                hull_cleaning_date: PreviousAddDetailsObject ? PreviousAddDetailsObject?.hull_cleaning_date : null as Date,
                is_there_any_changes_since_last_report: false,
                average_torque: 0,
                lng_foe: null,
                foe_factor: null,
                vessel: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID
            })
        }
    }

    /** useEffect */
    useEffect(() => {
        OtherDetailsFormik.initialValues = getInitialAdditionalInfo()
    }, [PreviousAddDetailsObject, AdditionalDetailsObject])
    /** useEffect end */

    /** Other details' formik object */
    const OtherDetailsFormik = {
        initialValues: getInitialAdditionalInfo(),
        validationSchema: Yup.object().shape({
            is_there_any_changes_since_last_report: Yup.boolean(),
            hull_cleaning_date: Yup.date()
                .max(new Date(dataTimeFormat(record?.reporting_time_utc)), commonValidationMessages.maxDateCurrentRecord)
                .when('is_there_any_changes_since_last_report', {
                    is: true,
                    then: (schema) => schema.required(commonValidationMessages.required),
                    otherwise: (schema) => schema.nullable()
                }),
        })
    }

    /** Other details' submit function */
    const otherDetailsSubmit = (values: any) => {
        /** Additional info submit */
        if (AdditionalDetailsObject && AdditionalDetailsObject[0]?.id > 0) {
            apiGlobal.put(`proactive_additional_details/${AdditionalDetailsObject[0]?.id}/`, values).then((res: any) => {
                if (res.status === 200) {
                    successToast("Data saved successfully!");
                    queryClient.invalidateQueries(queryKeyes.vessel.ProactiveAddDetailsObject.key);
                    if (env?.form_validation === true) {
                        toggleTab(activeTab + 1);
                    }
                }
            })
        } else {
            apiGlobal.post(`proactive_additional_details/`, values).then((res: any) => {
                if (res.status === 201) {
                    successToast("Data saved successfully!");
                    queryClient.invalidateQueries(queryKeyes.vessel.ProactiveAddDetailsObject.key);
                    if (env?.form_validation === true) {
                        toggleTab(activeTab + 1);
                    }
                }
            })
        }
    }
    return (
        <Card className='p-0 mb-0 border-0'>
            <CardHeader className='p-2'>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Other Details</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(AdditionalDetailsObjectLoading || PreviousAddDetailsObjectLoading) &&
                <Loading message="Loading required data!" />}
            {(AdditionalDetailsObjectError || PreviousAddDetailsObjectError) && getInitialAdditionalInfo()}
            {!(AdditionalDetailsObjectLoading || PreviousAddDetailsObjectLoading) && !(PreviousAddDetailsObjectError) &&
                <Formik
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(false);
                        otherDetailsSubmit(values);
                    }}
                    initialValues={OtherDetailsFormik.initialValues}
                    validationSchema={OtherDetailsFormik.validationSchema}
                >
                    {props => (
                        <Form onSubmit={props?.handleSubmit} autoComplete="off" noValidate>
                            <CardBody className='px-2 py-0 mt-2'>
                                <Row className='mb-2'>
                                    <Col sm={3} className='d-flex align-items-center'>
                                        <div className="form-check">
                                            <Field name="is_there_any_changes_since_last_report"
                                                className="form-check-input mt-2"
                                                type="checkbox"
                                                id="is_there_any_changes_since_last_report"
                                                checked={props?.values.is_there_any_changes_since_last_report}
                                            />
                                            <Label for='is_there_any_changes_since_last_report' className='mb-0'>
                                                Is there any change in hull cleaning date since last report?
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col sm={3}>
                                        <Label className={`mb-0 ${props?.values?.is_there_any_changes_since_last_report === true ? 'asteric' : null}`}
                                            for='hull_cleaning_date'>
                                            Last under water hull cleaning done
                                        </Label>
                                        <Field name='hull_cleaning_date'>
                                            {({ field }: FieldProps) => (
                                                <>
                                                    <Input
                                                        type="date"
                                                        id='hull_cleaning_date'
                                                        name={field.name}
                                                        className='datetimepicker text-uppercase max-width-8'
                                                        max={dataTimeFormat(record?.reporting_time_utc, true)}
                                                        onBlur={props?.handleBlur}
                                                        onChange={(e) => props?.handleChange(e)}
                                                        value={dataTimeFormat(props?.values?.hull_cleaning_date, true, true)}
                                                        disabled={!(props?.values.is_there_any_changes_since_last_report)}
                                                        defaultValue={props?.values?.hull_cleaning_date}
                                                    />
                                                    {props?.errors && props?.touched?.hull_cleaning_date &&
                                                        props?.errors?.hull_cleaning_date && env?.form_validation === true &&
                                                        <ErrorTooltip
                                                            target='hull_cleaning_date'
                                                            message={props?.errors?.hull_cleaning_date}
                                                            open={props?.errors && props?.errors?.hull_cleaning_date ? true : false}
                                                        />
                                                    }
                                                </>
                                            )}
                                        </Field>
                                    </Col>
                                    <Col sm={{ size: 3, offset: 1 }}>
                                        <Label className='mb-0' for='foe_factor'>FOE factor</Label>
                                        <Field
                                            type="text"
                                            name='foe_factor'
                                            id='foe_factor'
                                            className='form-control text-right max-width-7'
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className='p-2 py-3'>
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5">
                                        <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={() => {
                                            if (env?.form_validation === false) {
                                                toggleTab(activeTab + 1);
                                            }
                                        }}>
                                            Next<i className="bx bx-chevron-right ms-1" />
                                        </Button>
                                        <Button type="button" color="primary" className="btn_size_cstm" onClick={(e) => {
                                            toggleAddTab(activeAddTab - 1);
                                        }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug values={[props?.values, props?.errors, OtherDetailsFormik.initialValues]} />
                        </Form>
                    )}
                </Formik>
            }
        </Card>
    )
}

export default OtherDetailsComponent