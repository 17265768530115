import React from 'react'
import { Row, Col, Label } from 'reactstrap'
import { useSelector } from 'react-redux';
import '../global/GlobalCSS.css';

export default function VesselDetailsHeader() {
    const { Vessels, VesselID } = useSelector(
        (state: any) => state.Reporting
    );
    const record = Vessels.find((rec: any) => rec.id === VesselID)

    return (
        <Row className='justify-content-end py-1'>
            <Col lg={9} className='d-flex align-items-center'>
                <h2 className='page_title pos-center text-uppercase mb-0'>{record?.vessel_name}</h2>
            </Col>
            <Col lg={3} className='text-end pad-right-20'>
                <Row>
                    <Col sm={12} className='p-0'>
                        <strong className='mb-0 card-title-desc'>{record?.IMO_number}</strong>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className='p-0'>
                        <strong className='card-title-desc'>{record?.vessel_type}</strong>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

