import axios, { AxiosError } from "axios";
import env from "../environment/env";

export const BaseURL = env.base_url;
export const MediaBaseURL = env.media_base_url ?? "";
const authData = JSON.parse(sessionStorage.getItem("authData") ?? "[]");
let localToken: string = "";
if (authData) {
  localToken = `${env.token_prefix} ${authData.token ?? ""}`;
}
export const GlobalToken = localToken;
const apiGlobal = axios.create({
  baseURL: `${BaseURL}`,
  headers: {
    "Content-type": "application/json",
    Authorization: `${GlobalToken}`,
  },
  //withCredentials: true
});

export const apiMedia = axios.create({
  baseURL: `${BaseURL}`,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `${GlobalToken}`,
  },
});

export const handleApiError = (error: AxiosError) => {
  try {
    //errorToast('API Request failed:' + error.message);
    console.error("API Request failed:", error.message);
    if (axios.isAxiosError(error)) {
      const status = error.response?.status;
      if (status === 404) {
        console.error("Resource not found");
      } else if (status === 500) {
        console.error("Internal server error");
      } else if (status === 400) {
        console.error("Bad request");
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export default apiGlobal;
