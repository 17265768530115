import React from 'react';
import { Popover, PopoverBody, Button } from 'reactstrap';

const DeletePopOver = ({
    target,
    onClick,
    state,
    setState
}: any) => {
    /** Open close popover */
    function handlePopoverState() {
        setState(!state)
    }
    return (
        <Popover
            placement="top"
            isOpen={state}
            target={target}
            toggle={() => {
                setState(!state);
            }}
        >
            <PopoverBody>
                Are you sure you want to delete this record?<br />
                <Button type="button" color="primary" className='mr-2 mt-3 popover_btn' onClick={onClick}>Yes</Button>
                <Button type="button" color="primary" className='mt-3' onClick={() => handlePopoverState()} >No</Button>
            </PopoverBody>
        </Popover>
    )
}

export default DeletePopOver