import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from "redux-saga";
import reducers from './reducers';
import rootSaga from "./sagas";
import { composeWithDevTools } from 'redux-devtools-extension';
import log from '../utils/middlewares/LogErrorMiddleware';
import ApiMiddleware from '../utils/middlewares/ApiMiddleware';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

export function configureStore(initialState: any) {
    const store = createStore(
        reducers,
        initialState,
        composeEnhancers(
            applyMiddleware(...middlewares, log, ApiMiddleware)
        ),
    );
    sagaMiddleware.run(rootSaga);
    return store;
}

