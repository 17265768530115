import { apiCall } from "../middlewares/ApiActions";
import apiGlobal, { apiMedia } from "../../global/api.global";

const ApiMiddleware = ({ dispatch }: any) => (next: any) => async (action: any) => {
    if (action.type === 'api/Mediacall') {
        const { url, method, data, onStart, onSuccess, onError } = action.payload;
        if (onStart) dispatch({ type: onStart })

        try {
            const response = await apiMedia.request({
                method,
                url,
                data,
            })
            dispatch({ type: onSuccess, payload: response.data });
        } catch (error: any) {
            const msg = error.message || 'Error occured';
            if (onError) dispatch({ type: onError, payload: { error: msg } })
            dispatch({ type: "SHOW_ERROR", payload: { error: msg } })
        }
    } else {
        if (action.type !== apiCall.type) return next(action);

        const { url, method, data, onStart, onSuccess, onError } = action.payload;
        if (onStart) dispatch({ type: onStart })

        try {
            const response = await apiGlobal.request({
                method,
                url,
                data,
            })
            dispatch({ type: onSuccess, payload: response.data });
        } catch (error: any) {
            const msg = error.message || 'Error occured';
            if (onError) dispatch({ type: onError, payload: { error: msg } })
            dispatch({ type: "SHOW_ERROR", payload: { error: msg } })
        }
    }
}
export default ApiMiddleware;