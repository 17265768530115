import React, { useState } from 'react'
import { Tooltip } from 'reactstrap';

export default function ToolTip(props: any) {
    const [tbottom, settbottom] = useState(false);
    const { message, target } = props
    return (
        <Tooltip
            placement="bottom"
            id={target}
            isOpen={tbottom}
            target={target}
            toggle={() => {
                settbottom(!tbottom);
            }}
        >
            {message}
        </Tooltip>
    )
}
