import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '..';
import VesselReportingInfo from './VesselReportingInfo';
import CreateVesselReport from './CreateVesselReport';

const EditVesselReport = () => {
    const { VesselState, ReportID } = useSelector((state: RootState) => state.Reporting);

    return (
        <>
            {VesselState === 'EDIT' &&
                <React.Fragment>
                    <CreateVesselReport reportId={ReportID} />
                </React.Fragment >
            }
            {VesselState === 'VESSEL_REPORTING' && <VesselReportingInfo />}
        </>
    )
}

export default EditVesselReport