import React from 'react'

function Loading({message}:{message:string}) {
  return (
    <div
      className="alert-border-left mb-0 alert alert-info alert-dismissible fade show"
      role="alert">
      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
      <strong>Loading</strong> - {message}
    </div>
  )
}

export default Loading;