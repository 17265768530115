export const TooltipMsg = {
  CreateReport: [
    {
      target: "voyage_number_msg",
      message: "Number of a voyage",
    },
    {
      target: "position_lat_deg_lbl",
      message: "Enter the degree, minutes and seconds of latitude",
    },
    {
      target: "position_long_deg_lbl",
      message: "Enter the degree, minutes and seconds of longitude",
    },
    {
      target: "reporting_time_utc_msg",
      message:"Enter the date and time of the event. The value have to be greater than last report timing.",
    },
    {
      target: "time_difference_msg",
      message: "Select the sign based on the difference between in LT & UTC",
    },
    {
      target: "no_of_hours_retarded_msg",
      message: "Number of hours retarded or advanced since last report",
    },
    {
      target: "noon_report_msg",
      message: 'Select "Yes" if the event is a noon report. Note: In case time duration since last report is more than 24 hours, selection of noon report is imperative.',
    },
    {
      target: "cii_adjustment_report_msg",
      message:'Select "Yes" in case the event is the beginning or end of the "FCvoyage" related event.',
    },
    {
      target: "reporting_type_msg",
      message: 'Reporting type - "At Sea" or "In port"',
    },
    {
      target: "reporting_event_msg",
      message: "Select an appropriate event from the list.",
    },
    {
      target: "reporting_operation_mode_msg",
      message: `'Operation since last report can be, Sea passage, Manoeuvring,
            Anchor stay,
            Drifting,
            At Berth`,
    },
    {
      target: "is_strait_canal_transit_lbl",
      message: 'Select "Yes" in case the vessel is in strait canal transit.',
    },
    {
      target: "load_condition_msg",
      message: "Vessel load condition can be Laden/ Ballast",
    },
    {
      target: "remaining_distance_msg",
      message: 'Enter the remaining distance to reach next port in unit "nm"',
    },
    {
      target: "strait_canal",
      message: "List of strait canals",
    },
    {
      target: "is_this_idle_port_stay_msg",
      message: "Select Yes if the present port is idle port",
    },
    {
      target: "is_this_cargo_loading_discharging_msg",
      message: "Select Yes if cargo loading/cargo discharging operation is being carried out",
    },
  ],
  Weather_Data: [
    {
      target: "steaming_time_msg",
      message: "Steaming time is the time difference between current report and previous report.",
    },
    {
      target: "distance_travelled_msg",
      message: "Enter distance travelled since last report",
    },
    {
      target: "avg_ground_speed_msg",
      message: "",
    },
    {
      target: "avg_water_speed_msg",
      message: "Avg log speed since last report.",
    },
    {
      target: "wind_direction_msg",
      message: `Select approriate direction.
                Wind direction is measured relative to true north (not magnetic north) and is reported from where the wind is blowing. 
                An easterly wind blows from the east or 90 degrees, a southerly from the south or 180 degrees and a westerly from the west or 270 degrees.`,
    },
    {
      target: "wind_speed_msg",
      message: "Enter wind speed in measuring unit knots.",
    },
    {
      target: "beaufort_scale_msg",
      message:"The Beaufort Scale is an empirical measure that relates wind speed to observed conditions at sea.",
    },
    {
      target: "swell_height_msg",
      message: "Input Swell height in meters. ",
    },
    {
      target: "swell_direction_msg",
      message: `Select approriate direction. 
                Swell direction is the direction from which the swell is moving. It is given as a geographical direction, either in degrees, or in points of the compass,
                such as NNW or SW swell, and like winds,
                the direction given is generally the direction the swell is coming from.`,
    },
    {
      target: "ocean_current_speed_msg",
      message: "Input current speed in Knots.",
    },
    {
      target: "ocean_wave_height_msg",
      message: "Input wave height in meters. ",
    },
    {
      target: "ocean_current_direction_msg",
      message: `Select approriate direction. 
                Current direction is the direction to which the current is moving. 
                It is given as a geographical direction, either in degrees,
                or in points of the compass, such as NNW or SW.
                An easterly current flows towards the east or 90 degrees,
                a southerly to the south or 180 degrees and a westerly to the west or 270 degrees.`,
    },
    {
      target: "period_in_bad_weather_msg",
      message: "No. of hours above BF5 since last report.",
    },
    {
      target: "air_temperature_msg",
      message: "Air temperature is a measure of how hot or cold the air is. It is the most commonly measured weather parameter",
    },
    {
      target: "sea_temperature_msg",
      message: "Sea surface temperature (SST) is the water temperature close to the ocean surface.",
    },
    {
      target: "gyro_course_msg",
      message:"It employs an electrically powered, fast-spinning gyroscope wheel and frictional forces.",
    },
    {
      target: "barometric_pressure_msg",
      message: " The barometer denotes the atmospheric pressure.",
    },
  ],
  CP_Warranties: [
    {
      target: "ordered_speed_msg",
      message: "Enter ordered speed in knots",
    },
    {
      target: "formCheck1",
      message:"Tick only if there is any change in the ordered speed since last report.",
    },
    {
      target: "date_time_of_change_msg",
      message: "Enter the Date and time since the change is adopted.",
    },
    {
      target: "lng_foe_factor_msg",
      message: "Enter Fuel Oil Equivalent factor for LNG",
    },
  ],
  Draft_and_Displacement: [
    {
      target: "fwd_draft_msg",
      message: "Enter forward draft in meters",
    },
    {
      target: "draft_mid_msg",
      message: "Enter midship draft in meters",
    },
    {
      target: "draft_aft_msg",
      message: "Enter aft draft in meters",
    },
    {
      target: "trim_msg",
      message: "The difference between the forward and aft drafts of a ship is termed its trim.",
    },
    {
      target: "displacement_msg",
      message: "Enter total displacement in m-tonnes",
    },
    {
      target: "cargo_detail_msg",
      message: "Enter the name and specifications of the cargo being carried.",
    },
    {
      target: "adjustment_reason_msg",
      message: "Enter the reason for the cargo adjustment",
    },
    {
      target: "cargo_adjusted_msg",
      message: "Enter the amount of cargo adjusted",
    },
    {
      target: "amount_of_cargo_loaded_msg",
      message:"Enter the amount of cargo loaded since the last report. Unit m-tonne.",
    },
    {
      target: "amount_of_cargo_discharged_msg",
      message: "Enter the amount of cargo discharged since the last report. Unit m-tonne.",
    },
    {
      target: "total_amount_of_cargo_onboard_msg",
      message: "Enter the total amount of cargo present on board. Unit m-tonne.",
    },
    {
      target: "bl_figure_msg",
      message: "Enter Cargo figures as mentioned in the Bill of Lading.",
    },
    {
      target: "bill_of_lading_msg",
      message: 'Select "Yes" only in case Bill of lading is issued since last report',
    },
    {
      target: "ship_figure_of_bl_figure_msg",
      message: "Enter Ship figures as mentioned in the Bill of Lading.",
    },
    {
      target: "cargo_survey_msg",
      message: 'Select "Yes" in case cargo survey carried out at the completion of cargo operation.',
    },
    {
      target: "surveyor_figure_of_bl_figure_msg",
      message: "Enter Surveyor figures as mentioned in the Bill of Lading.",
    },
    {
      target: "permanent_msg",
      message: "A weight permanently installed in an ship to bring its center of gravity into allowable limits",
    },
    {
      target: "addition_msg",
      message: "Additional weight in a ship to provide hydrostatic stability",
    },
    {
      target: "total_msg",
      message: "Total weight of ballast tank in a ship.",
    },
    {
      target: "loaded_msg",
      message: "Loaded weight of a ballast tank.",
    },
    {
      target: "discharged_msg",
      message: "Discharged weight of a ballast tank.",
    },
  ],
  Reefer_Container: [
    {
      target: "no_of_reefer_containers_loaded_msg",
      message: "Enter the number of reefer containers loaded since the last report.",
    },
    {
      target: "no_of_reefer_containers_discharged_msg",
      message: "Enter the number of reefer containers discharged since the last report.",
    },
    {
      target: "total_reefer_containers_onboard_msg",
      message: "Enter total number of reefer containers present onboard.",
    },
  ],
  ETADD: [
    {
      target: "arrival_port_msg",
      message: "Select port name.",
    },
    {
      target: "masters_eta_at_pilot_station_msg",
      message: "Enter expected date and time of arrival",
    },
    {
      target: "is_arrival_port_in_eca_msg",
      message: 'Select "Yes" only if the arrival port is within the ECA region and the vessel is yet to enter the ECA.',
    },
    {
      target: "expected_time_of_arrival_at_eca_msg",
      message: "Enter expected date and time of entering ECA",
    },
    {
      target: "departure_port_msg",
      message: "Select port name ",
    },
    {
      target: "master_etd_from_present_port_msg",
      message: "Enter expected date and time of departure",
    },
    {
      target: "expected_time_of_depature_from_eca_msg",
      message: "Enter expected date and time of departing ECA",
    },
    {
      target: "vessel_transit_msg",
      message:'Select "Yes" only if the vessel will be transiting a particular ECA and not calling any port in the same region.',
    },
    {
      target: "date_and_time_of_arrival_at_eca_msg",
      message: "Enter expected date & time only incase the vessel is yet to enter the ECA.",
    },
    {
      target: "date_and_time_of_departure_from_eca_msg",
      message: "Enter expected date & time only incase the vessel is yet to exit the ECA.",
    },
    {
      target: "is_departure_port_in_eca_msg",
      message: 'Select "Yes" only if the departure port is within the ECA region and the vessel is yet to exit the ECA.',
    },
  ],
  FuelConsumption: [
    {
      target: "is_shore_supply_available_msg",
      message: "Select Yes if shore supply is available for the ship.",
    },
    {
      target: "is_shore_supply_used_msg",
      message: "Select Yes if shore supply is used for the ship.",
    },
    {
      target: "time_on_shore_supply_msg",
      message: "Enter the time of shore supply has been provided.",
    },
    {
      target: "quantity_of_shore_supply_msg",
      message:"Enter how many quantity of shore supply being used for the ship.",
    },
    {
      target: "method_used_to_measure_fuel_oil_consumption_msg",
      message:"Select the method which is used to measure fuel oil consumption.",
    },
    {
      target: "initial_rob_msg",
      message: "Showing the quantity of LNG cargo ROB before consumption",
    },
    {
      target: "cargo_rob_msg",
      message: "Showing the quantity of LNG cargo ROB after consumption",
    },
  ],
  Bunkering: [
    {
      target: "fuel_type_name_msg",
      message: "Select the type of the fuel",
    },
    {
      target: "vessel_fuel_name_msg",
      message: "Select the fuel",
    },
    {
      target: "quantity_bunkered_msg",
      message: "Ensure the value matches with the BDN",
    },
    {
      target: "density_msg",
      message: "Ensure the value matches with the BDN",
    },
    {
      target: "sulphur_percentage_msg",
      message: "Ensure the value matches with the BDN",
    },
    {
      target: "viscosity_msg",
      message: "Ensure the value matches with the BDN",
    },
    {
      target: "port_of_bunkering_msg",
      message: "Select the port",
    },
    {
      target: "date_of_bunkering_msg",
      message: "Enter the date of bunkering.",
    },
  ],
  Fresh_Water: [
    {
      target: "amount_of_fresh_water_produced_msg",
      message: "Enter the amount of Fresh water produced since the last report.",
    },
    {
      target: "amount_of_fresh_water_received_msg",
      message: "Enter the amount of Fresh water received onboard since the last report.",
    },
    {
      target: "amount_of_domestic_water_consumed_msg",
      message:"Enter the amount of Freshwater consumed onboard for domestic purposes since the last report.",
    },
    {
      target: "amount_of_distilled_water_consumed_msg",
      message:"Enter the amount of distilled water consumed onboard since the last report.",
    },
  ],
  Energy_Parameter: [
    {
      target: "is_energy_meter_functional_msg",
      message: "Select Yes if Energy meter is not functional.",
    }
  ],
  Voyage_Paramters: [
    {
      target: "slip_msg",
      message:"The value will be calculated once the steaming time will be updated in the report",
    },
    {
      target: "set_rpm_msg",
      message:"Enter the Set RPM of M/E in case of fixed pitch propeller In case of CPP enter SET Propeller RPM",
    },
    {
      target: "avg_rpm_msg",
      message:'In case of revolution counter not working select the setting icon from the "action" column and enter the average propeller RPM',
    },
    {
      target: "total_revolutions_msg",
      message:'Enter total revolutions since last report. In case of revolution counter not working select the setting icon from the "action" column and enter the average propeller RPM',
    },
    {
      target: "propeller_pitch_msg",
      message: `In case of FPP values is derived from the settings.
            In case of CPP please enter the pitch setting`,
    },
    {
      target: "is_revolution_msg",
      message: "Select Yes if revolution counter is not functional.",
    },
  ],
  Special_Operations: [
    {
      target: "is_kWh_meter_functional_msg",
      message: "Select Yes if kwh meter is not functional.",
    },
  ],
};
