import ErrorTooltip from 'Components/ErrorTooltip';
import Loading from 'Components/Loading';
import { errorToast, successToast } from 'Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import { loadAnchorageObject } from 'VesselMaster/vesselMaster.hooks';
import env from 'environment/env';
import { Field, FieldProps, Formik } from 'formik';
import apiGlobal from 'global/api.global';
import React from 'react';
import { useQuery } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import { Row, Col, Label, Input, Form, Button, CardFooter, CardBody, CardHeader, Card } from 'reactstrap';
import { EventPrecedence } from 'shared/constants';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import * as Yup from 'yup';

interface AnchorageDetailsComponentType {
    record: any,
    toggleTab: any,
    activeTab: any,
    activeAddTab: number,
    toggleAddTab: any
    VesselID: number,
    VoyageID: number,
    ReportID: number
}

const AnchorageDetailsComponent = ({
    record,
    toggleTab,
    activeTab,
    activeAddTab,
    toggleAddTab,
    VesselID,
    VoyageID,
    ReportID
}: AnchorageDetailsComponentType) => {
    /** Queries */
    /** Anchorage Details Object used for edit */
    const { data: AnchorageObject, isLoading: AnchorageObjectLoading, isError: AnchorageObjectError } = useQuery(
        [queryKeyes.vessel.AnchorageObject.key, VesselID, ReportID],
        async () => {
            return await loadAnchorageObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** Queries end */

    /** Assign values to initial object of Achorage Details */
    const getInitailAnchorageValues = () => {
        if (AnchorageObject && AnchorageObject.length > 0) {
            return AnchorageObject;
        } else {
            return ({
                anchored: '',
                anchorage_number: '',
                reason_for_anchoring: '',
                let_go_anchor: null,
                commence_heaving_anchor: null,
                anchor_aweigh: null,
                vessel_name: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID
            })
        }
    }

    /** Anchorage Detail's formik object */
    const AnchorageDetailsFormik = {
        initialValues: getInitailAnchorageValues(),
        validationSchema: Yup.object().shape({
            anchorage_number: Yup.string().max(50),
            reason_for_anchoring: Yup.string().max(250),
        })
    }
    return (
        <Card className='p-0 mb-0'>
            <CardHeader className='p-2'>
                <div className="text-center mb-4">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Anchorage Details</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {AnchorageObjectLoading && <Loading message='Loading required data!' />}
            {AnchorageObjectError && getInitailAnchorageValues()}
            {!AnchorageObjectLoading &&
                <Formik
                    onSubmit={(values: any, actions: any) => {
                        actions.setSubmitting(false);
                        const handleResponse = (response: any) => {
                            if (response.status === 201 || response.status === 200) {
                                successToast("Data saved successfully!");
                            }
                        }
                        if (AnchorageObject && AnchorageObject.length > 0) {
                            values.map((val: any) => {
                                apiGlobal.put(`/lfonds_anchorage_details/${val.id}/`, val).then(res => {
                                    handleResponse(res);
                                    queryClient.invalidateQueries(queryKeyes.vessel.AnchorageObject.key);
                                }).catch(err => {
                                    if (errResponse.includes(err.response.status)) {
                                        errorToast("Internal error occured, please contact the admin");
                                    }
                                })
                                return '';
                            })
                        } else {
                            apiGlobal.post(`/lfonds_anchorage_details/`, values).then(res => {
                                handleResponse(res);
                                queryClient.invalidateQueries(queryKeyes.vessel.AnchorageObject.key);
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            })
                        }
                    }}
                    initialValues={AnchorageDetailsFormik.initialValues}
                    validationSchema={AnchorageDetailsFormik.validationSchema}
                >
                    {({ values, errors, handleSubmit, handleChange, setErrors }:
                        { values: any, errors: any, handleSubmit: any, handleChange: any, setErrors: any}) => (
                        <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                            <CardBody className='px-2 py-0 mt-2'>
                                <Row className='mb-3'>
                                    <Col lg={3}>
                                        <Label className='mb-0' for="reason_for_anchoring">Reason for anchoring</Label>
                                        <Field
                                            name='reason_for_anchoring'
                                            as="textarea"
                                            className="form-control"
                                            id='reason_for_anchoring'
                                            rows={4}
                                            autoFocus
                                        />
                                        {errors?.reason_for_anchoring && errors?.reason_for_anchoring && env?.form_validation === true &&
                                            <ErrorTooltip
                                                target='reason_for_anchoring'
                                                message={errors?.reason_for_anchoring}
                                                open={errors?.reason_for_anchoring && errors?.reason_for_anchoring ? true : false}
                                            />
                                        }
                                    </Col>
                                    <Col lg={3}>
                                        <Label className='mb-0' for="anchored">Anchored</Label>
                                        <Field name="anchored">
                                            {({ field }: FieldProps) => (
                                                <Input
                                                    name={field.name}
                                                    type="time"
                                                    className="form-control"
                                                    id={"anchored"}
                                                    onChange={(e: any) => handleChange(e)}
                                                />
                                            )}
                                        </Field>
                                        {errors.anchored && env?.form_validation === true &&
                                            <ErrorTooltip
                                                target={'anchored'}
                                                message={errors.anchored}
                                                open={errors.anchored ? true : false}
                                            />
                                        }
                                    </Col>
                                    <Col lg={3}>
                                        <Label className='mb-0' for="anchorage_number">Anchorage number</Label>
                                        <Field
                                            name='anchorage_number'
                                            type="text"
                                            className="form-control"
                                            id='anchorage_number'
                                        />
                                        {errors.anchorage_number && env?.form_validation === true &&
                                            <ErrorTooltip
                                                target={'anchorage_number'}
                                                message={errors.anchorage_number}
                                                open={errors.anchorage_number ? true : false}
                                            />
                                        }
                                    </Col>
                                    <Col lg={3}>
                                        <Label className='mb-0' for="let_go_anchor">Let go anchor</Label>
                                        <Field name="let_go_anchor">
                                            {({ field }: FieldProps) => (
                                                <Input
                                                    name={field.name}
                                                    type="time"
                                                    className="form-control"
                                                    id={'let_go_anchor'}
                                                    onChange={(e: any) => handleChange(e)}
                                                />
                                            )}
                                        </Field>
                                        {errors.let_go_anchor && env?.form_validation === true &&
                                            <ErrorTooltip
                                                target={'let_go_anchor'}
                                                message={errors.let_go_anchor}
                                                open={errors.let_go_anchor ? true : false}
                                            />
                                        }
                                    </Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col lg={3}>
                                        <Label className='mb-0' for='commence_heaving_anchor'>Commence heaving anchor</Label>
                                        <Field name="commence_heaving_anchor">
                                            {({ field }: FieldProps) => (
                                                <Input
                                                    name={field.name}
                                                    type="time"
                                                    className="form-control"
                                                    id={'commence_heaving_anchor'}
                                                    onChange={(e: any) => handleChange(e)}
                                                    disabled={record && record?.event_precedence_id === EventPrecedence.DROP_ANCHOR ? true : false}
                                                />
                                            )}
                                        </Field>
                                        {errors.commence_heaving_anchor && env?.form_validation === true &&
                                            <ErrorTooltip
                                                target={'commence_heaving_anchor'}
                                                message={errors.commence_heaving_anchor}
                                                open={errors.commence_heaving_anchor ? true : false}
                                            />
                                        }
                                    </Col>
                                    <Col lg={3}>
                                        <Label className='mb-0' for='anchor_aweigh'>Anchor aweigh</Label>
                                        <Field name="anchor_aweigh">
                                            {({ field }: FieldProps) => (
                                                <Input
                                                    name={field.name}
                                                    type="time"
                                                    className="form-control"
                                                    id={'anchor_aweigh'}
                                                    onChange={(e: any) => handleChange(e)}
                                                    disabled={record && record?.event_precedence_id === EventPrecedence.DROP_ANCHOR ? true : false}
                                                />
                                            )}
                                        </Field>
                                        {errors.anchor_aweigh && env?.form_validation === true &&
                                            <ErrorTooltip
                                                target={'anchor_aweigh'}
                                                message={errors.anchor_aweigh}
                                                open={errors.anchor_aweigh ? true : false}
                                            />
                                        }
                                    </Col>
                                </Row>
                            </CardBody>

                            <CardFooter className='p-2'>

                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5">
                                        <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={(e) => {
                                            toggleTab(activeTab + 1);
                                        }}>Next <i className="bx bx-chevron-right ms-1" /></Button>
                                        <Button type="button" color="primary" className="btn_size_cstm " onClick={(e) => {
                                            setErrors({});
                                            toggleTab(activeTab - 1);
                                        }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug values={[values, errors, AnchorageDetailsFormik.initialValues]} />
                        </Form>
                    )}
                </Formik >
            }
        </Card>
    )
}

export default AnchorageDetailsComponent