import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Input, Label, Button, Form } from "reactstrap";
import AsyncSelect from 'react-select/async';
import apiGlobal from '../global/api.global';
import * as Yup from "yup";
import { Field, FieldProps, Formik } from "formik";
import '../global/GlobalCSS.css';
import Layout from '../HorizontalMenu/Menu';
import Navbar from '../HorizontalMenu/Navbar';
import VesselDetailsHeader from '../Components/VesselDetailsHeader';
import { useDispatch, useSelector } from 'react-redux';
import { VesselState, getVesselsAction, setVesselState } from "../Store/Generic/ReportingSlice";
import VoyageInformation from './VoyageInformation';
import { errorToast, successToast } from '../Components/Toasts';
import { PortConstant, VoyageConstant } from 'shared/constants';
import { loadOtherPorts, loadVoyageObject } from 'VesselMaster/vesselMaster.hooks';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { dataTimeFormat, searchCountries, searchPorts } from 'GenericForms/Helper';
import Loading from 'Components/Loading';
import { queryClient } from 'react-query/queryClient';
import env from 'environment/env';
import ErrorTooltip from 'Components/ErrorTooltip';

const CreateVoyage = (voyageId?: any) => {
    /** State variables */
    const dispatch = useDispatch();
    const { VesselState, VesselID } = useSelector(
        (state: any) => state.Reporting
    );
    const [otherPort, setOtherPort] = useState(false);
    const [addOtherPort, setAddOtherPort] = useState(false);
    /** State variables end */

    /** useQueries */
    /** Voyage object used for edit */
    const { data: VoyageObject, isLoading: VoyageObjectLoading, isError: VoyageObjectError } = useQuery(
        [queryKeyes.vessel.VoyageObject.key, voyageId.voyageId],
        async () => {
            return await loadVoyageObject(voyageId.voyageId);
        },
        { staleTime: Infinity }
    )
    /** Other Ports from master */
    const { data: OtherPortsObject, isLoading: OtherPortsLoading }:
        { data: any, isLoading: any, isError: any } = useQuery(
            [queryKeyes.masters.OtherPortMaster.key],
            async () => {
                return await loadOtherPorts();
            },
            { staleTime: Infinity }
        )
    /** useQueries end */

    /** useEffect start */
    useEffect(() => {
        dispatch(getVesselsAction('vessel_master' as string));
    }, [dispatch]);
    /** useEffect end */

    /** Assigning initial object to voyage's formik object */
    const getInitialValues = () => {
        if (VoyageObject && VoyageObject?.id > 0) {
            return VoyageObject;
        } else {
            return ({
                voyage_number: "",
                voyage_status: VoyageConstant.NEWVOYAGE,
                departure_date_time: null,
                departure_port: null,
                other_port: null as number,
                other_port_name: null as string,
                other_country: null as number,
                other_country_name: null as string,
                vessel_name: VesselID
            })
        }
    }

    /**useEffect start */
    useEffect(() => {
        if (VoyageObject && VoyageObject?.id > 0) {
            if (VoyageObject?.port_precedence_id === PortConstant.OTHER) {
                setOtherPort(true);
            }
        }
    }, [VoyageObject])
    /** useEffect end */

    /** Voyage Information formik object */
    const VoyageFormik = {
        initialValues: getInitialValues(),
        validationSchema: Yup.object({
            voyage_number: Yup.string().matches(
                /^[A-Za-z0-9-/]{1,50}$/, "Please enter upto 50 alphabets/numbers only"
            ).required(commonValidationMessages.required),
            departure_port: Yup.string().required(commonValidationMessages.required),
            departure_date_time: Yup.string().required(commonValidationMessages.required),
            other_port: Yup.string().when('$fieldAvailability', (field: any, schema) => {
                return !(otherPort === true && OtherPortsObject && OtherPortsObject?.length > 0)
                    ? schema.nullable()
                    : schema.required(commonValidationMessages.required);
            }),
            other_port_name: Yup.string().when('$fieldAvailability', (field: any, schema) => {
                return !(addOtherPort === true || !OtherPortsObject)
                    ? schema.nullable()
                    : schema.required(commonValidationMessages.required);
            }),
            other_country: Yup.number().when('$fieldAvailability', (field: any, schema) => {
                return !(addOtherPort === true || !OtherPortsObject)
                    ? schema.nullable()
                    : schema.required(commonValidationMessages.required);
            }),
        })
    }

    const handleVesselState = (record: VesselState) => {
        dispatch(setVesselState(record))
    }

    /** POST request for Voyage Information */
    const postVoyage = (values: any) => {
        apiGlobal.post(`/voyage_information/`, values).then(res => {
            if (res.status === 201) {
                successToast("Data saved successfully!");
                queryClient.invalidateQueries(queryKeyes.vessel.VoyageObject.key);
                handleVesselState('VOYAGE_REPORTING');
            }
        })
            .catch(err => {
                errorToast(err.response.data.error[0]);
            });
    }

    /** PUT request for Voyage Information */
    const putVoyage = (values: any) => {
        apiGlobal.put(`/voyage_information/${values?.id}/`, values).then(res => {
            if (res.status === 200) {
                successToast("Data saved successfully!");
                queryClient.invalidateQueries(queryKeyes.vessel.VoyageObject.key);
                handleVesselState('VOYAGE_REPORTING');
            }
        })
            .catch(err => {
                errorToast(err.response.data.error[0]);
            });
    }

    /** Voyage submit function */
    const voyageSubmit = (values: any) => {
        if (VoyageObject && VoyageObject?.id > 0) {
            putVoyage(values);
        } else {
            postVoyage(values);
        }
    }

    const customStyle = {
        control: (styles: any) => ({
            ...styles,
            borderColor: '#858484cc'
        })
    }

    return (
        <>
            <Layout children={Navbar} />
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={2}>
                            <button
                                color='primary'
                                className='btn btn-primary mb-3'
                                onClick={() => {
                                    handleVesselState('VOYAGE_REPORTING');
                                }}>
                                <i className="bx bx-chevron-left me-1" />
                                Back
                            </button>
                        </Col>
                        <Col sm={10}>
                            <VesselDetailsHeader />
                        </Col>
                    </Row>
                    <div id='owner_table'>
                        <h4 className='mb-3'>Voyage Reporting</h4>
                        {(VoyageObjectLoading || OtherPortsLoading) && <Loading message="Loading required data!" />}
                        {VoyageObjectError && getInitialValues()}
                        {!(VoyageObjectLoading || OtherPortsLoading) &&
                            <Formik
                                onSubmit={(values, actions) => {
                                    actions.setSubmitting(false);
                                    voyageSubmit(values);
                                }}
                                initialValues={VoyageFormik.initialValues}
                                validationSchema={VoyageFormik.validationSchema}
                            >
                                {(props: any) => (
                                    <Form onSubmit={props?.handleSubmit} autoComplete='off' noValidate>
                                        <Row className='mb-2'>
                                            <Col sm={3}>
                                                <Label for="voyage_number" className='required_field asteric mb-0'>Voyage number</Label>
                                                <Field
                                                    type="text"
                                                    className="form-control"
                                                    id="voyage_number"
                                                    onBlur={props.handleBlur}
                                                    name="voyage_number"
                                                />
                                                {props?.errors?.voyage_number && props?.touched?.voyage_number && env?.form_validation === true &&
                                                    <ErrorTooltip
                                                        target={'voyage_number'}
                                                        message={props?.errors?.voyage_number}
                                                        open={props?.errors?.voyage_number ? true : false}
                                                    />
                                                }
                                            </Col>
                                            <Col sm={3}>
                                                <Label className='asteric mb-0' for='departure_port'>Depature port name</Label>
                                                <Field name='departure_port'>
                                                    {({ field, form }: FieldProps) => (
                                                        <AsyncSelect
                                                            name={field.name}
                                                            inputId={'departure_port'}
                                                            cacheOptions
                                                            defaultOptions
                                                            loadOptions={(e: any) => searchPorts(e)}
                                                            getOptionLabel={(e: any) => e.port_name}
                                                            getOptionValue={(e: any) => e.id}
                                                            onBlur={props.handleBlur}
                                                            onChange={(e: any) => {
                                                                form.setFieldValue(field.name, e?.id);
                                                                if (e?.precedence_id === PortConstant.OTHER && addOtherPort === false) {
                                                                    setOtherPort(true);
                                                                } else if (e?.precedence_id !== PortConstant.OTHER) {
                                                                    setOtherPort(false);
                                                                    setAddOtherPort(false);
                                                                    form.setFieldValue(`other_port`, null);
                                                                    form.setFieldValue(`other_port_name`, null);
                                                                    form.setFieldValue(`other_country`, null);
                                                                }
                                                            }}
                                                            defaultValue={{
                                                                id: props?.values?.departure_port,
                                                                port_name: props?.values?.departure_port_name
                                                            }}
                                                            menuPortalTarget={document.body}
                                                            styles={customStyle}
                                                            noOptionsMessage={(e: any) => {
                                                                if (e?.inputValue?.toString()?.length > 2) {
                                                                    return 'Please select the Other option and enter the port name in the textbox provided'
                                                                }
                                                                return 'Please enter the first 3 characters of port name';
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                                {props?.errors?.departure_port && props?.touched?.departure_port && env?.form_validation === true &&
                                                    <ErrorTooltip
                                                        target={'departure_port'}
                                                        message={props?.errors?.departure_port}
                                                        open={props?.errors?.departure_port ? true : false}
                                                    />
                                                }
                                            </Col>
                                            <Col sm={3}>
                                                <Label className='asteric mb-0' for='departure_date_time'>Departure date & time(UTC)</Label><br />
                                                <Field name='departure_date_time'>
                                                    {({ field }: FieldProps) => (
                                                        <Input
                                                            type="datetime-local"
                                                            id='departure_date_time'
                                                            max={'9999-12-31T00:00'}
                                                            name={field.name}
                                                            onBlur={props.handleBlur}
                                                            onChange={(e: any) => props?.handleChange(e)}
                                                            className='datetimepicker text-uppercase mt-0 max-width-15'
                                                            defaultValue={dataTimeFormat(props?.values?.departure_date_time, false, true)}
                                                        />
                                                    )}
                                                </Field>
                                                {props?.errors?.departure_date_time && props?.touched?.departure_date_time && env?.form_validation === true &&
                                                    <ErrorTooltip
                                                        target={'departure_date_time'}
                                                        message={props?.errors?.departure_date_time}
                                                        open={props?.errors?.departure_date_time ? true : false}
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                        {otherPort === true && OtherPortsObject && OtherPortsObject?.length > 0 &&
                                            <Col sm={3}>
                                                <Label className="mb-0 asteric" for='other_port_name_dropdown'>Other port</Label>
                                                <Field name="other_port">
                                                    {({ field, form }: FieldProps) => (
                                                        <AsyncSelect
                                                            name={field.name}
                                                            inputId='other_port_name_dropdown'
                                                            cacheOptions
                                                            defaultOptions
                                                            loadOptions={() => loadOtherPorts()}
                                                            getOptionLabel={(e: any) => e.port_name}
                                                            getOptionValue={(e: any) => e.id}
                                                            onBlur={() => form.setFieldTouched(field.name, true)}
                                                            onChange={(selectedOption: any) => {
                                                                form.setFieldValue(field.name, selectedOption?.id);
                                                                form.setFieldValue(`other_port_name`, selectedOption?.port_name);
                                                                form.setFieldValue(`other_country`, selectedOption?.country);
                                                            }}
                                                            defaultValue={VoyageObject && VoyageObject?.id > 0 &&
                                                            {
                                                                id: VoyageObject?.other_port,
                                                                port_name: VoyageObject?.other_port_name
                                                            }}
                                                            menuPortalTarget={document.body}
                                                            styles={customStyle}
                                                            noOptionsMessage={(e: any) => {
                                                                if (e?.inputValue?.toString()?.length > 2) {
                                                                    return 'Please select the Other option and enter the port name in the textbox provided'
                                                                }
                                                                return 'Please enter the first 3 characters of port name';
                                                            }}
                                                        />)}
                                                </Field>
                                                <p className='link_color_blue pointer' onClick={() => {
                                                    setAddOtherPort(true);
                                                    setOtherPort(false);
                                                    props?.setFieldValue(`other_port`, null);
                                                    props?.setFieldValue(`other_port_name`, null);
                                                    props?.setFieldValue(`other_country`, null);
                                                }}>Click here to add new port</p>
                                            </Col>
                                        }
                                        {(addOtherPort === true || !OtherPortsObject) &&
                                            <Row>
                                                <Col sm={3}>
                                                    <Label className='mb-0 asteric'>Other port name</Label>
                                                    <Field name={`other_port_name`}>
                                                        {({ field, form }: FieldProps) => (
                                                            <Input
                                                                type='text'
                                                                name={`other_port_name`}
                                                                id='other_port_name'
                                                                className='form-control'
                                                                onBlur={(e: any) => props?.handleChange(e)}
                                                                defaultValue={props?.values?.other_port_name}
                                                            />
                                                        )}
                                                    </Field>
                                                    {props?.errors && props?.touched && props?.touched?.other_port_name &&
                                                        props?.errors?.bunkering?.other_port_name && env?.form_validation === true &&
                                                        <ErrorTooltip
                                                            target={`other_port_name`}
                                                            message={props?.errors?.other_port_name}
                                                            open={(props?.errors && props?.errors?.other_port_name) ? true : false}
                                                        />
                                                    }
                                                </Col>
                                                <Col sm={3}>
                                                    <Label className='mb-0 asteric'>Other country name</Label>
                                                    <Field name={`other_country`}>
                                                        {({ field, form }: FieldProps) => (
                                                            <AsyncSelect
                                                                name={field.name}
                                                                id={'other_country'}
                                                                cacheOptions
                                                                defaultOptions
                                                                loadOptions={(e: any) => searchCountries(e)}
                                                                getOptionLabel={(e: any) => e.country_name}
                                                                getOptionValue={(e: any) => e.id}
                                                                onBlur={(e) => {
                                                                    form.setFieldTouched(field.name, true);
                                                                }}
                                                                onChange={(e: any) => {
                                                                    form.setFieldTouched(field.name, true);
                                                                    form.setFieldValue(field.name, e?.id);
                                                                }}
                                                                defaultValue={VoyageObject && VoyageObject?.id > 0 && {
                                                                    id: props?.values?.other_country,
                                                                    country_name: props?.values?.other_country_name
                                                                }}
                                                                styles={customStyle}
                                                                noOptionsMessage={(e: any) => {
                                                                    return 'Please enter the first 3 characters of country name';
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    {props?.errors && props?.touched && props?.touched?.other_country &&
                                                        props?.errors?.other_country && env?.form_validation === true &&
                                                        <ErrorTooltip
                                                            target={`other_country`}
                                                            message={props?.errors?.other_country}
                                                            open={(props?.errors && props?.errors?.other_country) ? true : false}
                                                        />
                                                    }
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                                <Button type="submit" color="primary" className='btn_size4_5_cstm'>Save</Button>
                                                <Button type="reset" color="danger" className='btn_size4_5_cstm' onClick={() => {
                                                    props?.resetForm();
                                                }}>Reset</Button>
                                            </div>
                                        </Row>
                                        <FormValuesDebug values={[props?.values, props?.errors, VoyageFormik.initialValues]} />
                                    </Form>
                                )}
                            </Formik>
                        }
                    </div>
                </Container>
            </div>
            {VesselState === 'VOYAGE_REPORTING' && <VoyageInformation />}
        </>
    )
}

export default CreateVoyage