import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";

// Redux
import { Link } from "react-router-dom";

// users
import user1 from "../../assets/images/users/avatar-1.jpg";

//redux
import { clearLogout, getAuthData } from "utils/auth/authUtils";
import { successToast } from "Components/Toasts";
import env from "environment/env";

const ProfileMenu = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  const [username, setusername] = useState("User");

  useEffect(() => {
    const getAuthUser = getAuthData();


    if (getAuthUser) {
      setusername(getAuthUser['first_name']);
    }
  }, []);

  const handleLogout = () => {
    clearLogout();
    successToast('Success! Redirecting to Dashboard', env['base_name']);
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {username}<br />
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">

          <Link to={"/contacts-profile"} className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            Profile
          </Link>
          <div className="dropdown-divider" />
          <Link to={'/login'} onClick={handleLogout} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};
export default (ProfileMenu);
