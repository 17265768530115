import { sortArrayObject } from 'GenericForms/Helper';
import env from '../../environment/env'
import { Collapse } from 'reactstrap';
import classnames from "classnames";
import { useState } from 'react';

interface FormValuesDebugType {
    values: any[]
}

/**
 * Debug Tool for the formik values or any other object to be checked while development 
 * @param values 
 * @returns 
 */
const FormValuesDebug = ({ values }: FormValuesDebugType) => {
    const [openBool, setOpenBool] = useState(false);
    const toggleDebugValues = () => {
        setOpenBool(!openBool);
    }
    return (
        <>
            {env?.debugSetting &&
                <>
                    <div className="accordion" id="accordion">
                        <div className="accordion-item">
                            <h5 className="accordion-header" id="headingOne">
                                <button
                                    className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        { collapsed: !openBool },
                                        "p-2",
                                        "colour-grey",
                                        "align-middle"
                                    )}
                                    type="button"
                                    onClick={toggleDebugValues}
                                    style={{ cursor: "pointer" }}
                                >
                                    <h6 className='mb-0'>Debug values</h6>
                                </button>
                            </h5>
                        </div>
                    </div>
                    <Collapse isOpen={openBool} className="accordion-collapse">
                        <div className="accordion-body">
                            {values.map((object: any, index: number) => {
                                return (<pre key={index}>
                                    {
                                        JSON.stringify(sortArrayObject(object), null, 4)
                                    }
                                </pre>)
                            })
                            }
                        </div>
                    </Collapse>
                </>
            }
        </>
    )
}

export default FormValuesDebug;