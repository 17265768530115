import ToolTip from 'Components/ToolTip';
import React, { useEffect, useState } from 'react'
import { Label, Col, Button, Row } from 'reactstrap';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import VoyageAdjustmentPopup from './VoyageAdjustmentPopup';
import { AdjustmentApprovalStatus, Roles } from 'shared/constants';
import { hasRole } from 'utils/auth/authUtils';
import VoyageAdjustmentTableHeadComponent from './VoyageAdjustmentTableHeadComponent';
import AdjustmentPaginationComponent from 'Adjustments/AdjustmentPaginationComponent';
import ValidateIcon from '../../Media/VLD1.png'

const PendingVoyageAdjustment = ({ active }: any) => {
    /** State variables start */
    const [tableDetails, setTableDetails] = useState({
        size: 5,
        index: 0,
        ciiAdjustment: []
    })
    const [count, setCount] = useState(0);
    const [adjustmentPopup, setAdjustmentPopup] = useState(false);
    const [eventState, setEventState] = useState<any>({});
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    /** State varibles end */

    /** Load CII adjustments events pending for approval of the selected vessel */
    const loadData = async () => {
        await apiGlobal.get(`vessel_reporting_cii_adjustment/get_cii_adjustment_by_vessel/?vessel_id=${VesselID}`).then(response => {
            setTableDetails(prevDetails => ({
                ...prevDetails,
                ciiAdjustment: response.data.filter((item: any) => item.user_approved === AdjustmentApprovalStatus.PENDING)
            }))
            setCount(count => count + 1);
        }).catch(err => {
            setTableDetails(prevDetails => ({
                ...prevDetails,
                ciiAdjustment: []
            }))
        })
    };

    /** useEffect start */
    useEffect(() => {
        loadData();
    }, [VesselID, active])
    /** useEffect end */

    /** Open-close modal */
    function tog_backdrop() {
        setAdjustmentPopup(!adjustmentPopup);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    /** Consider enum 'loading, sts_discharging, port_discharging' whichever op pass it to pop up & hit url based on the op */

    return (
        <>
            {tableDetails?.ciiAdjustment?.length === 0 ?
                <div className='text-center'>
                    <Label className='mb-3'>No Voyage Adjustments available for this vessel</Label>
                </div> :
                <>
                    <Row>
                        <Col lg={12}>
                            <div className="table-responsive">
                                <table className="table mb-0" key={count}>
                                    <VoyageAdjustmentTableHeadComponent />
                                    <tbody>
                                        {tableDetails && tableDetails?.ciiAdjustment?.slice(tableDetails?.index * tableDetails?.size,
                                            tableDetails?.index * tableDetails?.size + tableDetails?.size)?.map((cii: any, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className='nopadtop align-middle p-2 text-center'>{index + 1}</td>
                                                        <td className='nopadtop align-middle p-2'>
                                                            {cii?.cii_end_date_time !== null ?
                                                                <>
                                                                    <b>{cii?.cii_begin_date_time?.toString().substring(0, 10)}&nbsp;
                                                                        {cii?.cii_begin_date_time?.toString().substring(11, 16)}</b> to
                                                                    <b> {cii?.cii_end_date_time?.toString().substring(0, 10)}&nbsp;
                                                                        {cii?.cii_end_date_time?.toString().substring(11, 16)}</b>
                                                                </>
                                                                : <><strong>Currently in progress</strong></>}
                                                        </td>
                                                        <td className='nopadtop align-middle p-2'>{cii?.cii_adjustment_name}</td>
                                                        {!(hasRole(Roles.CHIEFENGINEER)) &&
                                                            <>
                                                                <td className='nopadtop d-flex flex-warp p-2 gap-2 justify-content-center'>
                                                                    <img
                                                                        src={ValidateIcon}
                                                                        alt="Validate Icon"
                                                                        id={`cii_validate${index}`}
                                                                        className='text-centre pointer'
                                                                        height="30"
                                                                        onClick={() => {
                                                                            setAdjustmentPopup(true);
                                                                            setEventState(cii);
                                                                        }}
                                                                    />
                                                                </td>
                                                                <ToolTip target={`cii_validate${index}`} message='Validate Voyage Adjustment' />
                                                            </>
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                        {adjustmentPopup === true &&
                                            <VoyageAdjustmentPopup
                                                state={adjustmentPopup}
                                                setState={setAdjustmentPopup}
                                                toggle={tog_backdrop}
                                                event={eventState}
                                                target={`popup${eventState?.id}`}
                                                root="pending"
                                            />
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                    {tableDetails?.ciiAdjustment?.length > tableDetails?.size &&
                        <AdjustmentPaginationComponent
                            count={count}
                            setCount={setCount}
                            tableDetails={tableDetails}
                            setTableDetails={setTableDetails}
                        />
                    }
                </>
            }
        </>
    )
}

export default PendingVoyageAdjustment