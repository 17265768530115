import Layout from 'HorizontalMenu/Menu';
import Navbar from 'HorizontalMenu/Navbar';
import React from 'react';
import { Container } from 'reactstrap';
import underConstruction from '../Media/underConstruction.jpg'

const VesselPerformance = () => {
    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <div className="page-content">
                <Container fluid>
                    <img src={underConstruction} alt="" height="800" className='pos-center' />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default VesselPerformance