/**
 * Voyage state contants
 */
export const VoyageConstant = {
  NEWVOYAGE: 1,
  ONGOING: 2,
  CLOSED: 3,
};

/**
 * Operation Precedence id
 */
export const OperationPrecedence = {
  SEA_PASSAGE: 1,
};

/**
 * Fixed precedence id for noon event
 */
export const EventPrecedence = {
  NOON: 1,
  CII_ADJUSTMENT_EVENT: 2,
  DROP_ANCHOR: 3,
  AT_BERTH: 4,
  HEAVE_ANCHOR: 5,
  END_OF_SEA_PASSAGE: 6,
  BEGINNING_OF_SEA_PASSAGE: 7,
  DEPARTURE_FROM_DRIFTING_PORT: 8,
  DEPARTURE_FROM_BERTH: 9,
};

/**
 * Fixed operation for strait canal transit
 */
export const ReportingOpConstant = {
  MANEUVERING: 4,
  SEA_PASSAGE: 1,
};

/**
 * Reporting type id
 */
export const ReportingTypeConstant = {
  INPORT: "In Port",
  ATSEA: "At Sea",
};

/**
 * Different engine status
 */
export const EngineStatus = {
  standByEngine: "Stand By Engine",
  finishWithEngine: "Finish With Engine",
  ringFullAway: "Ring Full Away",
};

/**
 * Load condition constant
 */
export const LoadConditionConstant = {
  LADEN: 1,
  BALLAST: 2,
};

/**
 * Role constance for rolebase system
 */
export const Roles = {
  ES_ADMIN: "ES_ADMIN", //admin = 1
  MANAGER: "MANAGER", //Shore Officer = 2
  MASTER: "MASTER", //DecOfficer = 3
  CHIEFENGINEER: "CHIEFENGINEER", //CENG = 4
};

/**
 * List of STD Auto fields returned from the API
 */
export const AutoFields = {
  CREATEDBY: "created_by",
  CREATEDON: "created_on",
  MODIFIEDBY: "modified_by",
  MODIFIEDON: "modified_on",
  ID: "id",
};

// Vessel Types
export const VesselTypeConstant = {
  TANKER: "Tanker",
  CONTAINER: "Container",
  GAS_CARRIER: "Gas Carrier",
  LNG_CARRIER: "LNG Carrier",
  GENERAL_CARGO_SHIP: "General Cargo Ship",
};

/**
 * Vessel machinery constants
 */
export const VesselMachineryConstant = {
  MAIN_ENGINE: 1,
  AUXILIARY_ENGINE: 2,
  EGCS: 3,
  SCR: 4,
  EGR: 5,
  SHAFT_GENERATOR: 6,
  HPPED: 7,
};

export const DO_ENGG_ReportingStatus = {
  NEW: 1,
  SAVED: 2,
  COMPLETE: 3,
};

export const ReportingEventName = {
  END_SEA_PASSAGE: "End of Sea Passage",
  BEGINING_SEA_PASSAGE: "Beginning of Sea Passage",
};

export const ReportingTypes = {
  IN_PORT: "In Port",
  AT_SEA: "At Sea",
};

/** Fuel sub type */
export const FuelSubTypes = {
  HFO: 1,
  LFO: 2,
  LNGB: 3,
};

/** Viscosity constant used to determine fuel sub types for calculations */
export const ViscosityConstant = 80;

/** Reporting events */
export const ReportingEvents = {
  NOON_FWE_IP: 1,
  NOON_SBE_IP: 2,
  NOON_FWE_AS: 3,
  NOON_RFA_AS: 4,
  NOON_SBE_AS: 5,
  EOSP: 7,
  DROP_ANCHOR: 8,
  HEAVE_ANCHOR: 9,
  AT_BERTH: 10,
  DEPARTURE_FROM_BERTH: 11,
  DRIFTING_IP: 12,
  DEPARTURE_FROM_DRIFTING_IP: 13,
  BOSP: 14,
  HASP: 15,
  DRIFTING_AS: 16,
  DEPARTURE_FROM_DRIFTING_AS: 17,
  ROSP: 18,
  CII_ADJUSTMENT: 19,
};

/** Fuel Types */
export const FuelTypes = {
  FUEL_OIL: 1,
  DIESEL_OIL: 2,
  LNG_BUNKER: 3,
  LNG_CARGO: 4,
};

/** STS operations */
export const STSOperations = {
  LOADING: 1,
  DISCHARGING: 2,
  PORT_DISCHARGING: 3,
};

/** Statuses for adjustment approval */
export const AdjustmentApprovalStatus = {
  PENDING: "pending",
  APPROVED: "approved",
  DISAPPROVED: "disapproved",
};

/** Special Operations constant */
export const SpecialOpsConstant = {
  CARGO_COOLING: 1,
  CARGO_DISCHARGING: 2,
  CARGO_RELIQUIFICATION: 3,
  REEFER_CONTAINER: 4,
  CARGO_HEATING: 5,
  TANK_CLEANING: 6,
};

export const ReportingStatusConstant = {
  OPEN: "open",
  SENT: "sent",
  VALIDATED: "validated",
};

export const PortConstant = {
  OTHER: 1,
};

/** Additional Information constant */
export const AddInfoConstant = {
  LFONDS_DECK: "lfonds_deck",
  LFONDS_ENGG: "lfonds_engg",
  PROACTIVE_DECK: "proactive_deck",
  PROACTIVE_ENGG: "proactive_engg",
  NONE: "none",
};

export const PropellerTypeConstant = {
  FPP: "fixed_pitch_propeller",
};
