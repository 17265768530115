import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import { loadSTSAdjustmentApprovalData } from 'VesselMaster/vesselMaster.hooks';
import React from 'react'
import { useQuery } from 'react-query';
import { Button, Card, CardBody, CardHeader, Col, Input, Modal, Row } from 'reactstrap';
import { queryKeyes } from 'shared/queryKeys';

interface STSAdjustmentPopupType {
    state: boolean,
    setState: any,
    toggle: any,
    event: any,
    target: string,
}

const STSAdjustmentPopup = ({
    state,
    setState,
    toggle,
    event,
    target,
}: STSAdjustmentPopupType) => {
    /** Queries */
    /** Vessel specific voyages */
    const { data: STSApproval, isLoading: STSApprovalLoading, isError: STSApprovalError } = useQuery(
        [queryKeyes.vessel.STSAdjustmentApprovalData.key, event?.id],
        async () => {
            return await loadSTSAdjustmentApprovalData(event?.id);
        },
        { staleTime: Infinity }
    )
    /** Queries end */
    return (
        <Modal
            isOpen={state}
            toggle={() => {
                toggle();
            }}
            backdrop={"static"}
            id={target}
            size='xl'
        >
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                    Select applicable STS Adjustment events
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                        setState(false);
                    }}
                    aria-label="Close"
                />
            </div>
            <div className="modal-body">
                {STSApprovalLoading && <Loading message='Loading required data!' />}
                {STSApprovalError && <ErrorComponent message='Error loading required data!' />}
                {(!STSApprovalLoading && !STSApprovalError) &&
                    <>
                        <Card className='mb-3'>
                            <CardHeader>
                                <h5 className='mb-0'>Voyage Pre STS Operation</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="table-responsive mb-3">
                                    <table className="table mb-0">
                                        <thead className="table-light">
                                            <tr>
                                                <th className='p-2'>Applicable</th>
                                                <th className='p-2'>Reporting event</th>
                                                <th className='p-2'>Distance(nm)</th>
                                                <th className='p-2'>Time(hr)</th>
                                                <th colSpan={2} className='text-center p-2'>FC</th>
                                                <th className='p-2'>FC<sub>(elec + boiler + other)</sub></th>
                                                <th className='p-2'>Remark</th>
                                            </tr>
                                            <tr>
                                                <th className='p-2' colSpan={4}></th>
                                                <th className='p-2'>{STSApproval?.pre_sts[0]?.fuel_consumption[0]?.fuel_sub_type_name}</th>
                                                <th className='p-2'>{STSApproval?.pre_sts[0]?.fuel_consumption[1]?.fuel_sub_type_name}</th>
                                                <th className='p-2' colSpan={2}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {STSApproval?.pre_sts?.map((pre: any, index: number) => {
                                                return (
                                                    <tr className='p-2'>
                                                        <td>
                                                            <Input
                                                                type='checkbox'
                                                                id={`sts_adjustment`}
                                                                name={`adjustment.sts_adjustment`}
                                                            />
                                                        </td>
                                                        <td className='p-2'>{pre?.reporting_event_name}</td>
                                                        <td className='p-2'>{pre?.distance_travelled}</td>
                                                        <td className='p-2'>{pre?.time_duration_since_last_report / 60}</td>
                                                        <td className='p-2'>{pre?.fuel_consumption[0]?.fuel_consumption}</td>
                                                        <td className='p-2'>{pre?.fuel_consumption[1]?.fuel_consumption}</td>
                                                        <th className='p-2'>FC<sub>(elec + boiler + other)</sub></th>
                                                        <td className='p-2'>
                                                            <Input
                                                                type='textarea'
                                                                id={`${index}post_sts_adjustment`}
                                                                name={`adjustment.post_sts_adjustment`}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th className='p-2' colSpan={2}>Total</th>
                                                <th className='p-2'>{STSApproval?.pre_sts?.reduce((sum: number, item: any) =>
                                                    sum + (item.distance_travelled || 0), 0)}</th>
                                                <th className='p-2'>{STSApproval?.pre_sts?.reduce((sum: number, item: any) =>
                                                    sum + ((item.time_duration_since_last_report / 60) || 0), 0)}</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <Row>
                                    <Col lg={{ size: 2, offset: 10 }}>
                                        <Button type='button' className='btn justify_right' color='primary'>Approve</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className='mb-3'>
                            <CardHeader>
                                <h5 className='mb-0'>STS Operation</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="table-responsive mb-3">
                                    <table className="table mb-0">
                                        <thead className="table-light">
                                            <tr>
                                                <th className='p-2'>Applicable</th>
                                                <th className='p-2'>Reporting event</th>
                                                <th className='p-2'>Distance(nm)</th>
                                                <th className='p-2'>Time(hr)</th>
                                                <th colSpan={2} className='text-center p-2'>FC</th>
                                                <th className='p-2'>FC<sub>(elec + boiler + other)</sub></th>
                                                <th className='p-2'>Remark</th>
                                            </tr>
                                            <tr>
                                                <th className='p-2' colSpan={4}></th>
                                                <th className='p-2'>{STSApproval?.sts_operation[0]?.fuel_consumption[0]?.fuel_short_name}</th>
                                                <th className='p-2'>{STSApproval?.sts_operation[0]?.fuel_consumption[1]?.fuel_short_name}</th>
                                                <th className='p-2' colSpan={2}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {STSApproval?.sts_operation?.map((pre: any, index: number) => {
                                                return (
                                                    <tr>
                                                        <td className='p-2'>
                                                            <Input
                                                                type='checkbox'
                                                                id={`sts_adjustment`}
                                                                name={`adjustment.sts_adjustment`}
                                                            />
                                                        </td>
                                                        <td className='p-2'>{pre?.reporting_event_name}</td>
                                                        <td className='p-2'>{pre?.distance_travelled}</td>
                                                        <td className='p-2'>{pre?.time_duration_since_last_report / 60}</td>
                                                        <td className='p-2'>{pre?.fuel_consumption[0]?.fuel_consumption}</td>
                                                        <td className='p-2'>{pre?.fuel_consumption[1]?.fuel_consumption}</td>
                                                        <th className='p-2'>FC<sub>(elec + boiler + other)</sub></th>
                                                        <td className='p-2'>
                                                            <Input
                                                                type='textarea'
                                                                className='p-1'
                                                                id={`${index}post_sts_adjustment`}
                                                                name={`adjustment.post_sts_adjustment`}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th className='p-2' colSpan={2}>Total</th>
                                                <th className='p-2'>{STSApproval?.sts_operation?.reduce((sum: number, item: any) =>
                                                    sum + (item.distance_travelled || 0), 0)}</th>
                                                <th className='p-2'>{STSApproval?.sts_operation?.reduce((sum: number, item: any) =>
                                                    sum + ((item.time_duration_since_last_report / 60) || 0), 0)}</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <Row>
                                    <Col lg={{ size: 2, offset: 10 }}>
                                        <Button type='button' className='btn justify_right' color='primary'>Approve</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <h5 className='mb-0'>Voyage Post STS Operation</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="table-responsive mb-3">
                                    <table className="table mb-0">
                                        <thead className="table-light">
                                            <tr>
                                                <th className='p-2'>Applicable</th>
                                                <th className='p-2'>Reporting event</th>
                                                <th className='p-2'>Distance(nm)</th>
                                                <th className='p-2'>Time(hr)</th>
                                                <th colSpan={2} className='text-center p-2'>FC</th>
                                                <th className='p-2'>FC<sub>(elec + boiler + other)</sub></th>
                                                <th className='p-2'>Remark</th>
                                            </tr>
                                            <tr>
                                                <th className='p-2' colSpan={4}></th>
                                                <th className='p-2'>{STSApproval?.post_sts[0]?.fuel_consumption[0]?.fuel_sub_type_name}</th>
                                                <th className='p-2'>{STSApproval?.post_sts[0]?.fuel_consumption[1]?.fuel_sub_type_name}</th>
                                                <th className='p-2' colSpan={2}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {STSApproval?.post_sts?.map((post: any, index: number) => {
                                                return (
                                                    <tr>
                                                        <td className='p-2'>
                                                            <Input
                                                                type='checkbox'
                                                                id={`sts_adjustment`}
                                                                name={`adjustment.sts_adjustment`}
                                                            />
                                                        </td>
                                                        <td className='p-2'>{post?.reporting_event_name}</td>
                                                        <td className='p-2'>{post?.distance_travelled}</td>
                                                        <td className='p-2'>{post?.time_duration_since_last_report / 60}</td>
                                                        <td className='p-2'>{post?.fuel_consumption[0]?.fuel_consumption}</td>
                                                        <td className='p-2'>{post?.fuel_consumption[1]?.fuel_consumption}</td>
                                                        <td className='p-2'>FC<sub>(elec + boiler + other)</sub></td>
                                                        <td className='p-2'>
                                                            <Input
                                                                type='textarea'
                                                                className='p-2'
                                                                id={`${index}post_sts_adjustment`}
                                                                name={`adjustment.post_sts_adjustment`}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th className='p-2' colSpan={2}>Total</th>
                                                <th className='p-2'>{STSApproval?.post_sts?.reduce((sum: number, item: any) =>
                                                    sum + (item.distance_travelled || 0), 0)}</th>
                                                <th className='p-2'>{STSApproval?.post_sts?.reduce((sum: number, item: any) =>
                                                    sum + ((item.time_duration_since_last_report / 60) || 0), 0)}</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <Row>
                                    <Col lg={{ size: 2, offset: 10 }}>
                                        <Button type='button' className='btn justify_right' color='primary'>Approve</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </>
                }
            </div>
        </Modal >
    )
}

export default STSAdjustmentPopup