import { formatBytes } from 'GenericForms/Helper';
import Dropzone from 'react-dropzone';
import { Label } from 'reactstrap';

const CIIAdjustSingleFile = (uploadFile: any) => {
    /** Show uploaded files */
    function handleAcceptedFiles(files: any) {
        files.map((file: any) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        uploadFile.uploadFile.vessel_reporting_cii_adjustment_supporting_file_name = (files[0]);
        uploadFile.uploadFile.file_status = true;
    }

    return (
        <div>
            <Dropzone
                onDrop={(acceptedFiles) => {
                    handleAcceptedFiles(acceptedFiles);
                }}
            >
                {({ getRootProps, getInputProps }) => (
                    <div className="dropzone custom-dropzone mt-3" >
                        <div
                            className="dz-message needsclick font_16"
                            {...getRootProps()}
                        >
                            <input name={`file[]`} {...getInputProps()} />
                            <div className="cloud-icon">
                                <i className="display-6 text-muted bx bx-cloud-upload" />
                            </div>
                            {uploadFile.uploadFile.file_status === true ?
                                <Label>Click here to replace</Label>
                                : <Label className='mb-3'>Click here to upload.</Label>
                            }
                        </div>
                    </div>
                )}
            </Dropzone>
        </div>
    )
}

export default CIIAdjustSingleFile