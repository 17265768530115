import React from 'react'
import { Modal } from 'reactstrap';

const PopUp = ({
    state,
    setState,
    body,
    title,
    size
}: any) => {
    const handlePopUpState = () => {
        setState(!state);
    }

    return (
        <Modal
            size={size??'xl'}
            isOpen={state}
            toggle={() => {
                handlePopUpState();
            }}
            backdrop={"static"}
            id="staticBackdrop"
        >
            <div className="modal-header p-2">
                <h5 className="modal-title" id="staticBackdropLabel">
                    {title}
                </h5>
                <button
                    onClick={() => {
                        setState(false);
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body p-1">
                {body}
            </div>
        </Modal>
    )
}

export default PopUp