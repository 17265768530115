import React, { useEffect } from 'react';
import { Col, Row, Card, CardBody, Form, Input, CardFooter, Button } from "reactstrap";
import { fetchMachinaryOptions } from '../../../vesselMaster.hooks';
import { useQuery } from 'react-query';
import { successToast, errorToast } from '../../../../Components/Toasts';
import { queryKeyes } from 'shared/queryKeys';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import apiGlobal from 'global/api.global';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import * as Yup from "yup";
import { errResponse } from 'GenericForms/Helper';
import { useSelector } from 'react-redux';
import { RootState } from 'index';

const GeneralSettings = () => {
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    /** Queries */
    /** Machinery on vessel */
    const { data: Machinary, isLoading: GeneralSettingLoading, isError: GeneralSettingError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.Machinary.key, VesselID],
            async () => { return await fetchMachinaryOptions(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );

    /** Assign initial values to formik object */
    const getInitailValueGeneralSettings = () => {
        if (Machinary?.length > 0 && Machinary[0].id > 0) {
            return Machinary
        } else {
            return []
        }
    }

    /** General Settings Formik Object */
    const GeneralSettingsFormik = {
        initialValues: {
            generalSettings: getInitailValueGeneralSettings()
        },
        validationSchema: Yup.object().shape({
            generalSettings: Yup.array(Yup.object({
                running_hour: Yup.boolean(),
                energy_parameter: Yup.boolean(),
                sfoc_required: Yup.boolean()
            }))
        })
    }

    return (
        <>
            <Card className='border-0'>
                {(GeneralSettingLoading) && <Loading message='Loading required data!' />}
                {(GeneralSettingError) && <ErrorComponent message='Unable to load required data!' />}
                {!(GeneralSettingLoading) && !(GeneralSettingError) &&
                    <Formik
                        onSubmit={(values: any, actions: any) => {
                            actions.setSubmitting(false);
                            const handleResponse = (response: any) => {
                                if (response.status === 200) {
                                    successToast("Data saved successfully!");
                                }
                            }

                            if (Machinary?.length > 0 && Machinary[0].id > 0) {
                                values.generalSettings.map((machine: any) => {
                                    apiGlobal.put(`/vessel_machinery_list/${machine.id}/`, machine).then(res => {
                                        handleResponse(res);
                                    }).catch(err => {
                                        if (errResponse.includes(err.response.status)) {
                                            errorToast("Internal error occured, please contact the admin")
                                        }
                                    })
                                })
                            }
                        }
                        }
                        initialValues={GeneralSettingsFormik.initialValues}
                        validationSchema={GeneralSettingsFormik.validationSchema}
                    >
                        {props => (
                            <Form onSubmit={props?.handleSubmit} noValidate autoComplete='off'>
                                <CardBody className='p-0'>
                                    <Row>
                                        <Col sm={12}>
                                            <div className="table-responsive p-0">
                                                <table className="table mb-2">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className='p-2 text-center align-middle sr-no-width'>#</th>
                                                            <th className='p-2 align-middle'>Vessel machinery name</th>
                                                            <th className='p-2 align-middle'>Machinery</th>
                                                            <th className='p-2 text-center align-middle'>Runnning hours</th>
                                                            <th className='p-2 text-center align-middle'>Energy parameter</th>
                                                            <th className='p-2 text-center align-middle'>SFOC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <FieldArray name='generalSettings'>
                                                            {() => (
                                                                <>
                                                                    {props?.values &&
                                                                        props?.values?.generalSettings.map((generalSettings: any, index: number) => {
                                                                            return (
                                                                                <tr key={generalSettings.id}>
                                                                                    <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                                    <td className='p-2 align-middle'>{generalSettings.vessel_machinery_name}</td>
                                                                                    <td className='p-2 align-middle'>{generalSettings.machinery}</td>
                                                                                    <td className='p-2 align-middle text-center'>
                                                                                        <Field name={`generalSettings.${index}.running_hour`}>
                                                                                            {({ field, form }: FieldProps) => (
                                                                                                <Input
                                                                                                    type='checkbox'
                                                                                                    id={`general-setting-${index}`}
                                                                                                    name={field.name}
                                                                                                    checked={field.value}
                                                                                                    onChange={(e: any) => {
                                                                                                        props?.handleChange(e);
                                                                                                    }}
                                                                                                    defaultValue={props.values?.generalSettings[index]?.running_hour}
                                                                                                />
                                                                                            )}
                                                                                        </Field>
                                                                                    </td>
                                                                                    <td className='p-2 align-middle text-center'>
                                                                                        <Field name={`generalSettings.${index}.energy_parameter`}>
                                                                                            {({ field, form }: FieldProps) => (
                                                                                                <Input
                                                                                                    type='checkbox'
                                                                                                    id={`general-setting-${index}`}
                                                                                                    name={field.name}
                                                                                                    checked={field.value}
                                                                                                    onChange={(e: any) => {
                                                                                                        props?.handleChange(e);

                                                                                                    }}
                                                                                                    defaultValue={props.values?.generalSettings[index]?.energy_parameter}
                                                                                                />
                                                                                            )}
                                                                                        </Field>
                                                                                    </td>
                                                                                    <td className='p-2 align-middle text-center'>
                                                                                        <Field name={`generalSettings.${index}.sfoc_required`}>
                                                                                            {({ field, form }: FieldProps) => (
                                                                                                <Input
                                                                                                    type='checkbox'
                                                                                                    id={`general-setting-${index}`}
                                                                                                    name={field.name}
                                                                                                    checked={field.value}
                                                                                                    onChange={(e: any) => {
                                                                                                        props?.handleChange(e);
                                                                                                    }}
                                                                                                    defaultValue={props.values?.generalSettings[index]?.sfoc_required}
                                                                                                />
                                                                                            )}
                                                                                        </Field>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            )}
                                                        </FieldArray>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter className='p-2'>
                                    <Button type="submit" color='primary' className='justify_right'>Save</Button>
                                </CardFooter>
                                <Row className='mt-2'>
                                    <FormValuesDebug values={[props?.values, props?.errors, GeneralSettingsFormik.initialValues]} />
                                </Row>
                            </Form>
                        )}
                    </Formik>
                }
            </Card >
        </>
    );
};
export default GeneralSettings;