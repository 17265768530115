import apiGlobal from '../../../global/api.global';
import React, { useEffect, useState } from 'react'
import { VesselMachineryList } from '../VesselConfiguration.model';
import { useSelector } from 'react-redux';
import { RootState } from '../../../';

const FuelConsumer = () => {
    const [vesselMachineries, setVesselMachineries] = useState<Array<VesselMachineryList>>([]);
    const { VesselID } = useSelector((state: RootState) => state.Reporting);


    useEffect(() => {
        const loadOptions = async () => {
            try {
                const response = await apiGlobal.get(`vessel_machinery_fc_group/get_vessel_machinery_fc_group_by_vessel/?vessel_id=${VesselID}`);
                setVesselMachineries(response.data);
                return response.data;
            } catch (error) {
                console.log(error);
            }
        }; loadOptions();
    }, [VesselID])

    return (
        <>
            <div className="table-responsive mb-5">
                <table className="table">
                    <thead className="table-light">
                        <tr>
                            <th>Sr. No.</th>
                            <th>Machinery name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vesselMachineries.length > 0 ?
                            vesselMachineries.map((vesselMachinery: any, index: number) => {
                                return (
                                    <tr key={vesselMachinery.id}>
                                        <td>{index + 1}</td>
                                        <td>{vesselMachinery.vessel_machinery_fc_group_name}</td>
                                    </tr>
                                )
                            })
                            : <tr>
                                <td>No machineries added</td>
                            </tr>}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default FuelConsumer