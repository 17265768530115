export interface Inputs {
    id?: string
    name?: string | any
    placeholder?: string
    label?: string
    table_columns?: boolean
    table_columns_sequence?: number
    className?: string
}

export interface CPWarranties {
    ordered_speed: string | null
    fuel_type_name: string | null
    fuel_consumed: string | null
}

export const Forms: { [x: string]: Inputs[] } =
{
    cp_warranties: [
        {
            name: "ordered_speed",
            label: "Ordered speed",
            table_columns: false,
        },
        {
            name: "fuel_type_name",
            label: "Fuel type",
            table_columns: true,
            table_columns_sequence: 1,
        },
        {
            name: "fuel_consumed",
            label: "Ordered fuel consumed",
            table_columns: true,
            table_columns_sequence: 2,
            className: "asteric",
        },
    ]
}

type Form = 'cp_warranties'

export const getInputs = (section: Form) => {
    return {
        inputs: Forms[section],
    }
}