import React, { useState } from 'react';
import { CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import Vse from './Vse';
import SteamDumpValve from './SteamDumpValve';

const Other = () => {
    const [activeSettingsTab, setActiveSettingsTab] = useState('1');

    const toggle = (tab: any) => {
        if (activeSettingsTab !== tab) {
            setActiveSettingsTab(tab);
        }
    }
    return (
        <>
            <CardBody className='pb-0 pt-0'>
                <Nav tabs className='nav-tabs-custom nav-justified'>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === "1",
                            })}
                            onClick={() => {
                                toggle("1");
                            }}
                        >
                            VSE
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === "2",
                            })}
                            onClick={() => {
                                toggle("2");
                            }}
                        >
                            Steam Dump Valve
                        </NavLink>
                    </NavItem>
                </Nav>
            </CardBody>
            <TabContent activeTab={activeSettingsTab} className="p-3 text-muted">
                <TabPane tabId="1">
                    <Vse />
                </TabPane>
                <TabPane tabId="2">
                    <SteamDumpValve />
                </TabPane>
            </TabContent>
        </>
    )
}

export default Other