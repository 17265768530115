import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import { errorToast, successToast } from 'Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import { loadPortActivities, loadPortActivitiesObject } from 'VesselMaster/vesselMaster.hooks';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import apiGlobal from 'global/api.global';
import React from 'react'
import { useQuery } from 'react-query';
import { Row, Col, Label, Input, Button, Form, CardHeader, Card, CardBody, CardFooter } from 'reactstrap';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';

interface PortActivitiesComponentType {
    activeAddTab: number,
    toggleAddTab: any,
    VesselID: number,
    VoyageID: number,
    ReportID: number
}

const PortActivitiesComponent = ({
    activeAddTab,
    toggleAddTab,
    VesselID,
    VoyageID,
    ReportID }: PortActivitiesComponentType) => {
    /** Queries */
    /** Load Port Activities */
    const { data: PortActivities, isLoading: PortActivitiesLoading, isError: PortActivitiesError } = useQuery(
        [queryKeyes.masters.PortActivities.key],
        async () => {
            return await loadPortActivities();
        },
        { staleTime: Infinity }
    )
    /** Load Port Activities' object used for edit */
    const { data: PortActivitiesObject, isLoading: PortActivitiesObjectLoading, isError: PortActivitiesObjectError } = useQuery(
        [queryKeyes.vessel.PortActivitiesObject.key, VesselID, ReportID],
        async () => {
            return await loadPortActivitiesObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** Queries end */

    /** Assign values to initial object of Port Activities */
    const getInitialValues = () => {
        let obj: any[] = [];
        if (PortActivitiesObject?.length > 0 && PortActivitiesObject[0]?.id > 0) {
            return PortActivitiesObject;
        } else {
            if (obj !== undefined && PortActivities !== undefined && obj <= PortActivities.length) {
                PortActivities.map((activity: any) => {
                    obj.push({
                        is_port_activity: false,
                        comment: "",
                        port_activity_name: activity.port_activity_name,
                        port_activity: activity.id,
                        vessel_name: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID
                    })
                    return '';
                })
            }
            return obj;
        }
    }

    /** Port Activities' formik object */
    const PortActivitiesFormik = {
        initialValues: {
            portActivities: getInitialValues(),
        }
    }
    return (
        <Card className='p-0 mb-0'>
            <CardHeader className='p-2'>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Port Activities</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(PortActivitiesLoading || PortActivitiesObjectLoading) && <Loading message='Loading required data!' />}
            {PortActivitiesError && <ErrorComponent message='Unable to load required data!' />}
            {PortActivitiesObjectError && getInitialValues()}
            {!PortActivitiesLoading && !PortActivitiesError &&
                <Formik
                    onSubmit={(values: any, actions: any) => {
                        actions.setSubmitting(false);
                        const handleResponse = (response: any) => {
                            if (response.status === 201 || response.status === 200) {
                                successToast("Data saved successfully!");
                            }
                        }
                        if (PortActivitiesObject && PortActivitiesObject.length > 0) {
                            values.portActivities.map((val: any) => {
                                apiGlobal.put(`/lfonds_port_activity_detail/${val.id}/`, val).then(res => {
                                    handleResponse(res);
                                }).catch(err => {
                                    if (errResponse.includes(err.response.status)) {
                                        errorToast("Internal error occured, please contact the admin");
                                    }
                                })
                                return '';
                            })
                        }
                        apiGlobal.post(`/lfonds_port_activity_detail/`, values.portActivities).then(res => {
                            handleResponse(res);
                        }).catch(err => {
                            if (errResponse.includes(err.response.status)) {
                                errorToast("Internal error occured, please contact the admin");
                            }
                        })
                    }}
                    initialValues={PortActivitiesFormik.initialValues}
                >
                    {({ values, errors, handleSubmit, handleChange }:
                        { values: any, errors: any, handleSubmit: any, handleChange: any }) => (
                        <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                            <CardBody className='px-2 py-0 mt-2'>
                                <FieldArray name='portActivities'>
                                    {() => (
                                        <>
                                            {values && values?.portActivities?.map((activity: any, index: number) => {
                                                return (
                                                    <>
                                                        <Row key={index} className='mb-3'>
                                                            <Col lg={3}>
                                                                <Label>{activity.port_activity_name}</Label>
                                                            </Col>
                                                            <Col lg={1}>
                                                                <Field name={`portActivities.${index}.is_port_activity`}>
                                                                    {({ field, form }: FieldProps) => (
                                                                        <div className="square-switch sqswitch mt-3">
                                                                            <Input
                                                                                type="checkbox"
                                                                                switch="none"
                                                                                name={field.name}
                                                                                id={field.name}
                                                                                onChange={(e: any) => handleChange(e)}
                                                                                defaultChecked={values?.portActivities[index].is_port_activity}
                                                                            />
                                                                            <Label
                                                                                htmlFor={field.name}
                                                                                data-on-label='Yes'
                                                                                data-off-label='No'>
                                                                            </Label>
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </Col>
                                                            {values && values?.portActivities[index].is_port_activity === true &&
                                                                <Col lg={3}>
                                                                    <Field
                                                                        as="textarea"
                                                                        name={`portActivities.${index}.comment`}
                                                                        className='form-control'
                                                                        rows={4}
                                                                    />
                                                                </Col>
                                                            }
                                                        </Row>
                                                    </>
                                                )
                                            })}
                                        </>
                                    )}
                                </FieldArray>
                            </CardBody>
                            <CardFooter className='p-2'>
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5 ">
                                        <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={(e) => {
                                            toggleAddTab(activeAddTab + 1);
                                        }}>Next <i className="bx bx-chevron-right ms-1" /></Button>
                                        <Button type="button" color="primary" className="btn_size_cstm" onClick={(e) => {
                                            toggleAddTab(activeAddTab - 1);
                                        }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug values={[values, errors, PortActivitiesFormik.initialValues]} />
                        </Form>
                    )}
                </Formik>
            }
        </Card>
    )
}

export default PortActivitiesComponent