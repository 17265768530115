import CIIAdjustmentFileUpload from 'Components/CIIAdjustmentFileUpload';
import DeletePopOver from 'Components/DeletePopOver';
import PopOver from 'Components/PopOver';
import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import ReportingCIIAdjustmentEndModal from 'Components/ReportingCIIAdjustmentEndModal';
import ToolTip from 'Components/ToolTip';
import React, { useEffect, useState } from 'react'
import { Row, Col, Button, CardBody, Card, CardHeader, CardFooter } from 'reactstrap';
import CIIAdjustmentModal from './CIIAdjustmentModal';
import { CIIAdjustmentType } from 'shared/dataTypes';
import apiGlobal from 'global/api.global';
import { errorToast, successToast } from 'Components/Toasts';
import { errResponse } from 'GenericForms/Helper';

const CIIAdjustmentComponent = ({
    record,
    VesselID,
    ReportID,
    activeTab,
    toggleTab
}: any) => {
    /** State variables */
    const [modalBackdropCIIStart, setModalBackDropCIIStart] = useState(false);
    const [modalBackdropCIIEnd, setModalBackDropCIIEnd] = useState(false);
    const [modalBackdropCIIUpload, setmodalBackdropCIIUpload] = useState(false);
    const [modalBackdropCIIResume, setmodalBackdropCIIResume] = useState(false);
    const [CIIAdjustmentEvents, setCIIAdjustmentEvents] = useState<Array<CIIAdjustmentType>>([]);
    const [CIIEventsList, setCIIEventsList] = useState<Array<any>>([]);
    const [eventState, setEventState] = useState<any>({});
    const [popoverState, setPopoverState] = useState(false);
    /** State variables end */

    /** useEffect */
    useEffect(() => {
        loadCIIAdjustmentEvents();
    }, [VesselID]);
    /** Load CII adjustment events from it's master */
    useEffect(() => {
        const loadCIIEventsList = async () => {
            await apiGlobal.get(`/cii_adjustment_events_master`).then(res => {
                setCIIEventsList(res.data);
                return res.data;
            }).catch(err => {
                if (errResponse.includes(err.response?.status)) {
                    errorToast("Internal error occured, please contact the admin");
                }
            })
        }; loadCIIEventsList();
    }, []);
    /** useEffect end */

    /** Load CII adjustment events of the selected vessel */
    const loadCIIAdjustmentEvents = async () => {
        await apiGlobal.get(`/vessel_reporting_cii_adjustment/get_cii_adjustment_by_vessel/?vessel_id=${VesselID}`).then(res => {
            setCIIAdjustmentEvents(res.data);
            return res.data;
        }).catch(err => {
            console.log(err);
        })
    }

    /** Functions to open-close modal */
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const togBackDropCIIStart = () => {
        setModalBackDropCIIStart(!modalBackdropCIIStart);
        loadCIIAdjustmentEvents();
        removeBodyCss();
    }

    const togBackDropCIIEnd = (event: any) => {
        setEventState(event)
        setModalBackDropCIIEnd(!modalBackdropCIIEnd);
        removeBodyCss();
    }

    const togBackDropCIIUpload = (event: any) => {
        setEventState(event)
        loadCIIAdjustmentEvents();
        setmodalBackdropCIIUpload(!modalBackdropCIIUpload);
        removeBodyCss();
    }

    const togBackDropCIIResume = (event: any) => {
        setEventState(event)
        loadCIIAdjustmentEvents();
        setmodalBackdropCIIResume(!modalBackdropCIIResume);
        removeBodyCss();
    }

    const togDeletePopover = (event: any) => {
        setEventState(event)
        setPopoverState(!popoverState);
        removeBodyCss();
    }

    /** Extra actions post request success */
    const handleResponse = (response: any) => {
        if (response.status === 201 || response.status === 200) {
            successToast("Data saved successfully!");
        }
    }

    /** Function to resume previously ended adjustment event */
    const resumeCIIEndEvent = async (event: any) => {
        event.cii_adjustment_end_event = null;
        event.cii_end_report = null;
        event.cii_end_date_time = null;
        event.cii_adjustment_status = true;
        try {
            const response = await apiGlobal.put(`/vessel_reporting_cii_adjustment/${event.id}/`, event);
            if (response.data) {
                handleResponse(response);
                setmodalBackdropCIIResume(false);
                loadCIIAdjustmentEvents();
            }
            return response.data;
        } catch (err) {
            return null;
        }
    }

    /** Function to delete CII Adjustment event */
    const handleAdjustmentEventDelete = (id: number) => {
        apiGlobal.delete(`/vessel_reporting_cii_adjustment/${id}/`).then(res => {
            if (res.status === 200 || res.status === 204) {
                successToast("Data deleted successfully!");
                loadCIIAdjustmentEvents();
                setPopoverState(false);
            }
        }).catch(err => {
            if (errResponse.includes(err.response.status)) {
                errorToast("Internal error occured, please contact the admin");
            }
        });
    }

    return (
        <>
            <Card className='p-0 mb-0 border-0'>
                <CardHeader className='p-2'>
                    <div className="text-center">
                        <Row>
                            <Col>
                                <h4 className="page_title pos-start mb-0">CII Adjustment Events</h4>
                                <p className="card-title-desc pos-start">All readings since last report</p>
                            </Col>
                            <Col>
                                <ReportDetailsHeader />
                            </Col>
                        </Row>
                    </div>
                </CardHeader>
                <CardBody className='deck-card-body mt-2'>
                    <Row className='mb-2'>
                        <Col lg={12}>
                            <Button
                                color='primary'
                                className='btn btn-primary justify_right'
                                onClick={togBackDropCIIStart}>
                                <i className="dripicons-plus font-size-16 align-middle me-2" />
                                Create New
                            </Button>
                            <CIIAdjustmentModal
                                state={modalBackdropCIIStart}
                                setState={setModalBackDropCIIStart}
                                ongoingEvents={CIIAdjustmentEvents.filter((item: any) => item.cii_adjustment_status === true)}
                                record={record}
                                loadCIIAdjustments={loadCIIAdjustmentEvents}
                                reportId={ReportID}
                                vesselId={VesselID}
                                adjustmentEvents={CIIAdjustmentEvents.filter((item: any) =>
                                    item.cii_begin_date_time <= record?.reporting_time_utc &&
                                    (item.cii_end_date_time >= record?.reporting_time_utc || item.cii_end_date_time === null))}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {CIIAdjustmentEvents.filter((item: any) =>
                            item.cii_begin_date_time <= record?.reporting_time_utc &&
                            (item.cii_end_date_time >= record?.reporting_time_utc || item.cii_end_date_time === null)).length > 0 ?
                            <div className="table-responsive">
                                <table className="table mb-2">
                                    <thead className="table-light">
                                        <tr>
                                            <th className='p-2 align-middle sr-no-width'>#</th>
                                            <th className='p-2 align-middle' style={{ width: '20%' }}>Current CII adjustment event(s)</th>
                                            <th className="p-2 align-middle text-center" style={{ width: '15%' }}>Start time</th>
                                            <th className='p-2 align-middle text-center' style={{ width: '15%' }}>End Time</th>
                                            <th className='p-2 align-middle text-center' style={{ width: '15%' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {CIIAdjustmentEvents.filter((item: any) =>
                                            item.cii_begin_date_time <= record?.reporting_time_utc &&
                                            (item.cii_end_date_time >= record?.reporting_time_utc || item.cii_end_date_time === null))
                                            .map((event: any, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                        <td className='p-2 align-middle'>{event.cii_adjustment_name}</td>
                                                        <td className="p-2 align-middle text-center">
                                                            {event?.cii_begin_date_time?.toString().substring(0, 10)}&nbsp;
                                                            {event?.cii_begin_date_time?.toString().substring(11, 16)}
                                                        </td>
                                                        <td className="p-2 align-middle text-center">
                                                            {event?.cii_end_date_time?.toString().substring(0, 10)}&nbsp;
                                                            {event?.cii_end_date_time?.toString().substring(11, 16)}
                                                        </td>
                                                        <td className='p-2 align-middle text-center'>{
                                                            <>
                                                                {event.cii_begin_date_time && event.cii_begin_date_time === record?.reporting_time_utc &&
                                                                    <>
                                                                        <i
                                                                            className='dripicons-trash icon_s18'
                                                                            id={`delete_cii_adjustment-${event?.id}`}
                                                                            onClick={() => togDeletePopover(event)}
                                                                        />
                                                                        <ToolTip target={`delete_cii_adjustment-${event?.id}`} message={`Delete ${event.cii_adjustment_name}`} />
                                                                    </>
                                                                }
                                                                {(!event.cii_end_date_time && event.cii_begin_date_time !== record?.reporting_time_utc &&
                                                                    <>
                                                                        <Button type="button" color="danger" onClick={() => togBackDropCIIEnd(event)}>End</Button>
                                                                        <ReportingCIIAdjustmentEndModal
                                                                            state={modalBackdropCIIEnd}
                                                                            setState={setModalBackDropCIIEnd}
                                                                            CIIData={event}
                                                                            CIIEvents={CIIEventsList}
                                                                            reportId={ReportID}
                                                                            record={record}
                                                                            target={`end-event-${index}`}
                                                                        />
                                                                    </>
                                                                )}
                                                                {(event.cii_end_date_time &&
                                                                    <>
                                                                        <ToolTip target='cii_documents' message="Upload CII documents" />
                                                                        <ToolTip target={`cii_event_resume-${event?.id}`} message="Resume event" />
                                                                        <i
                                                                            className="ml-1 dripicons-upload icon_s18 pointer"
                                                                            onClick={() => togBackDropCIIUpload(event)}
                                                                            id='cii_documents'
                                                                        />
                                                                        <i
                                                                            className="ml-1 bx bx-undo icon_s23 pointer"
                                                                            onClick={(e: any) => togBackDropCIIResume(event)}
                                                                            id={`cii_event_resume-${event?.id}`}
                                                                        />
                                                                    </>
                                                                )}
                                                            </>
                                                        }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                {eventState && eventState?.id > 0 &&
                                    <>
                                        <CIIAdjustmentFileUpload
                                            state={modalBackdropCIIUpload}
                                            event={eventState}
                                            togBackDropCIIUpload={togBackDropCIIUpload}
                                        />
                                        {popoverState === true &&
                                            <DeletePopOver
                                                target={`delete_cii_adjustment-${eventState?.id}`}
                                                onClick={() => handleAdjustmentEventDelete(eventState?.id)}
                                                state={popoverState}
                                                setState={setPopoverState}
                                            />
                                        }
                                        {modalBackdropCIIResume === true &&
                                            < PopOver
                                                target={`cii_event_resume-${eventState?.id}`}
                                                handleClick={() => resumeCIIEndEvent(eventState)}
                                                message={`Are you sure you want to resume ${eventState?.cii_adjustment_name} event?`}
                                                state={modalBackdropCIIResume}
                                                setState={setmodalBackdropCIIResume}
                                            />
                                        }
                                    </>
                                }
                            </div>
                            : <strong className="text-center mb-2">Currently no CII adjustment events are in progress</strong>
                        }
                    </Row>
                </CardBody>
                <CardFooter className='p-2 py-3'>
                    <Row className="ele_row1">
                        <div className="d-flex flex-wrap gap-5">
                            <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={(e) => {
                                toggleTab(activeTab + 1);
                            }}>Next <i className="bx bx-chevron-right ms-1" />
                            </Button>
                            <Button type="button" color="primary" className="btn_size_cstm" onClick={(e) => {
                                toggleTab(activeTab - 1);
                            }}><i className="bx bx-chevron-left me-1" /> Previous
                            </Button>
                        </div>
                    </Row>
                </CardFooter>
            </Card>
        </>
    )
}

export default CIIAdjustmentComponent