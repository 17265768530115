import React, { useState } from 'react';
import { CardBody, NavItem, TabContent, TabPane, NavLink, Container, Nav } from "reactstrap";
import Layout from "../../HorizontalMenu/Menu";
import Navbar from "../../HorizontalMenu/Navbar";
import classnames from "classnames";
import '../../global/GlobalCSS.css'
import VesselGeneralInfo from '../../VesselMaster/AdditionalInformation/VesselGeneralInfo/VesselGeneralInfo';
import SatelliteCommunication from './SatelliteComm';
import VesselPreviousNames from './PreviousNames';
import { useDispatch } from 'react-redux';
import { VesselState, setVesselState } from "../../Store/Generic/ReportingSlice";
import VesselDetailsHeader from '../../Components/VesselDetailsHeader';

const VesselAdditionalInformation = () => {
    const [activeSettingsTab, setActiveSettingsTab] = useState('1');
    const dispatch = useDispatch();

    const toggle = (tab: any) => {
        if (activeSettingsTab !== tab) {
            setActiveSettingsTab(tab);
        }
    }

    const handleVesselState = (record: VesselState) => {
        dispatch(setVesselState(record))
    }

    return (
        <>
            <React.Fragment>
                <Layout children={Navbar} />
                <div className="page-content">
                    <Container fluid>
                        <div className='ele_row1'>
                            <button color='primary' className='btn btn-primary mb-3' onClick={() => { handleVesselState(null) }}><i className="bx bx-chevron-left me-1" /> Back</button>
                            <div className='pos-end1'>
                                <VesselDetailsHeader />
                            </div>
                        </div>
                        <CardBody>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === "1",
                                        })}
                                        onClick={() => {
                                            toggle("1");
                                        }}
                                    >
                                        General information
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === "2",
                                        })}
                                        onClick={() => {
                                            toggle("2");
                                        }}
                                    >
                                        Previous names
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === "3",
                                        })}
                                        onClick={() => {
                                            toggle("3");
                                        }}
                                    >
                                        Satellite communication
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeSettingsTab} className="p-3 text-muted">
                                <TabPane tabId="1">
                                    <VesselGeneralInfo />
                                </TabPane>
                                <TabPane tabId="2">
                                    <VesselPreviousNames />
                                </TabPane>
                                <TabPane tabId="3">
                                    <SatelliteCommunication />
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Container>
                </div>
            </React.Fragment>
        </>
    )
}

export default VesselAdditionalInformation;