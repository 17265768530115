import React, { useState } from 'react';
import { CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import GeneralSettings from './GeneralSettings';
import CargoDischarging from './CargoDischarging';
import CargoCooling from './CargoCooling';
import CargoHeating from './CargoHeating';
import TankCleaning from './TankCleaning';
import ReeferContainer from './ReeferContainer';
import CargoReliquification from './CargoReliquification';
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import { VesselTypeConstant } from 'shared/constants';

const SpecialOperations = () => {
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const [activeSettingsTab, setActiveSettingsTab] = useState('1');
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [cargoDischarging, setCargoDischarging] = useState(false);
    const [cargoHeating, setCargoHeating] = useState(false);
    const [cargoCooling, setCargoCooling] = useState(false);
    const [refeerContainer, setReeferContainer] = useState(false);
    const [tankCleaning, setTankCleaning] = useState(false);
    const [cargoReliquification, setCargoReliquification] = useState(false);

    const toggle = (tab: any) => {
        if (activeSettingsTab !== tab) {
            setActiveSettingsTab(tab);
        }
    }

    return (
        <>
            <CardBody className='pb-0 pt-0'>
                <Nav tabs className='nav-tabs-custom nav-justified'>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === "1",
                            })}
                            onClick={() => {
                                toggle("1");
                            }}
                        >
                            General Settings
                        </NavLink>
                    </NavItem>
                    {vessel && vessel?.vessel_type === VesselTypeConstant.TANKER &&
                        <>
                            {cargoHeating &&
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === "4",
                                        })}
                                        onClick={() => {
                                            toggle("4");
                                        }}
                                    >
                                        Cargo Heating
                                    </NavLink>
                                </NavItem>}
                            {cargoDischarging &&
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === "2",
                                        })}
                                        onClick={() => {
                                            toggle("2");
                                        }}
                                    >
                                        Cargo Discharging
                                    </NavLink>
                                </NavItem>
                            }
                            {tankCleaning &&
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === "5",
                                        })}
                                        onClick={() => {
                                            toggle("5");
                                        }}
                                    >
                                        Tank Cleaning
                                    </NavLink>
                                </NavItem>
                            }
                        </>
                    }
                    {vessel && (vessel?.vessel_type === VesselTypeConstant.GAS_CARRIER ||
                        vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) &&
                        <>
                            {cargoCooling &&
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === "3",
                                        })}
                                        onClick={() => {
                                            toggle("3");
                                        }}
                                    >
                                        Cargo Cooling
                                    </NavLink>
                                </NavItem>}
                            {cargoReliquification &&
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === "7",
                                        })}
                                        onClick={() => {
                                            toggle("7");
                                        }}
                                    >
                                        Cargo Reliquification
                                    </NavLink>
                                </NavItem>
                            }
                        </>
                    }
                    {vessel && (vessel?.vessel_type === VesselTypeConstant.CONTAINER ||
                        vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) &&
                        <>
                            {refeerContainer &&
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === "6",
                                        })}
                                        onClick={() => {
                                            toggle("6");
                                        }}
                                    >
                                        Reefer Container
                                    </NavLink>
                                </NavItem>
                            }
                        </>
                    }
                </Nav>
            </CardBody>

            <TabContent activeTab={activeSettingsTab} className="p-3 text-muted">
                <TabPane tabId="1">
                    <GeneralSettings
                        setCargoDischarging={setCargoDischarging}
                        setCargoHeating={setCargoHeating}
                        setCargoCooling={setCargoCooling}
                        setReeferContainer={setReeferContainer}
                        setCargoReliquification={setCargoReliquification}
                        setTankCleaning={setTankCleaning}
                    />
                </TabPane>
                <TabPane tabId="2">
                    <CargoDischarging />
                </TabPane>
                <TabPane tabId="3">
                    <CargoCooling />
                </TabPane>
                <TabPane tabId="4">
                    <CargoHeating />
                </TabPane>
                <TabPane tabId="5">
                    <TankCleaning />
                </TabPane>
                <TabPane tabId="6">
                    <ReeferContainer />
                </TabPane>
                <TabPane tabId="7">
                    <CargoReliquification />
                </TabPane>
            </TabContent>
        </>
    )
}

export default SpecialOperations;