import apiGlobal from 'global/api.global';
import React from 'react';
import { Modal, Form, Label, Button } from 'reactstrap';

const ReportingCIIAdjustmentEndModal = (props: any) => {
    const {
        state,
        setState,
        CIIData,
        CIIEvents,
        reportId,
        record,
        target
    }: any = props;
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_backdrop() {
        setState(!state);
        removeBodyCss();
    }

    const CIIAdjustmentSubmit = async () => {
        CIIData.cii_adjustment_end_event = CIIEvents.filter((item: any) => item?.cii_adjustment === CIIData?.cii_adjustment && item.id !== CIIData.cii_adjustment_begin_event).map((endEvent: any) => { return endEvent.id })[0];
        CIIData.cii_end_date_time = record.reporting_time_utc;
        CIIData.cii_end_report = reportId;
        CIIData.cii_adjustment_status = false;
        try {
            const response = await apiGlobal.put(`/vessel_reporting_cii_adjustment/${CIIData.id}/`, CIIData);
            if (response.data) {
                tog_backdrop();
            }
            return response.data;
        } catch (err) {
            return null;
        }
    }

    return (
        <Modal
            size='lg'
            isOpen={state}
            toggle={() => {
                tog_backdrop();
            }}
            backdrop={"static"}
            id={target}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                    End {CIIData?.cii_adjustment_name} event
                </h5>
                <button
                    onClick={() => {
                        setState(false);
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Form className="needs-validation"
                    onSubmit={(e) => {
                        e.preventDefault();
                        CIIAdjustmentSubmit();
                        return false;
                    }}>
                    <Label>Are you sure you want to end {CIIData?.cii_adjustment_name} event?</Label><br />
                    <div className='justify_right'>
                        <Button type="submit" color="primary" className='mr-2 mt-3 popover_btn'
                        >Yes</Button>
                        <Button type="button" color="primary" className='mt-3' onClick={() => setState(false)} >No</Button>
                    </div>

                </Form>
            </div>
        </Modal>
    )
}

export default ReportingCIIAdjustmentEndModal