import React from 'react'
import { Col, Row } from 'reactstrap'

const BuildDetailsComponent = () => {
    return (
        <Row>
            <Col sm={12}>
                <b>Date:</b> {process.env?.REACT_APP_COMMIT_DATE ?? ''}<br />
                <b>Last Commit:</b><small> {process.env?.REACT_APP_COMMIT_MESSAGE ?? ''}</small><br />
                <b>Last Hash:</b> {process.env?.REACT_APP_COMMIT_HASH ?? ''}<br />
                <b>Branch:</b> {process.env?.REACT_APP_BRANCH ?? ''}<br />
            </Col>
        </Row>
    )
}

export default BuildDetailsComponent