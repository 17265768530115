import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Input, Label, Modal, Form, Button } from "reactstrap";
import * as Yup from "yup";
import { Formik, Field, FieldProps } from "formik";
import '../../../global/GlobalCSS.css';
import "flatpickr/dist/themes/material_blue.css";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import apiGlobal from '../../../global/api.global';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';
import { loadCountries, loadHullTypes, loadVesselGenInfoObject, loadVesselOwners } from 'VesselMaster/vesselMaster.hooks';
import { errorToast, successToast } from '../../../Components/Toasts';
import { customStyle } from '../../../shared/CommonCSS';
import { errResponse, formatBytes } from 'GenericForms/Helper';
import { VesselGenInfoMessages } from 'Components/ValidationErrorMessages';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { queryClient } from 'react-query/queryClient';
import { RootState } from 'index';
import Loading from 'Components/Loading';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';

const VesselGeneralInfo = () => {
    /** State variables start */
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [selectedFiles, setselectedFiles] = useState<any>([]);
    const [modal_backdrop, setmodal_backdrop] = useState(false);
    /** State variables end */

    /** Queries start */
    /** Vessel general info object used for edit */
    const { data: VesselGenInfoObject, isLoading: VesselGenInfoObjectLoading, isError: VesselGenInfoObjectError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.VesselGenInfoObject.key, VesselID],
            async () => { return await loadVesselGenInfoObject(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );
    /** Queries end */

    /**useEffect start */
    useEffect(() => {
        VesselGenInfoFormik.initialValues = getInitialValues();
    }, [VesselGenInfoObject])
    /** useEffect end */

    /** Assign initial values to formik object */
    const getInitialValues = () => {
        if (VesselGenInfoObject && VesselGenInfoObject[0]?.id > 0) {
            return VesselGenInfoObject[0];
        } else {
            return ({
                vessel_short_name: "",
                call_sign: "",
                date_of_built: null,
                official_number: "",
                hull_number: "",
                dead_weight: null,
                vessel_description: "",
                image: null,
                vessel_name: vessel?.id,
                country_name: null,
                port_of_registry: null,
                vessel_owner: null,
                hull_type_name: null,
                normal_ballast_fwd_draft: 0,
                normal_ballast_mid_draft: 0,
                normal_ballast_aft_draft: 0,
                normal_laden_fwd_draft: 0,
                normal_laden_mid_draft: 0,
                normal_laden_aft_draft: 0
            })
        }
    }

    /** Vessel General Info Formik Object */
    const VesselGenInfoFormik = {
        initialValues: getInitialValues(),
        validationSchema: Yup.object().shape({
            vessel_short_name: Yup.string().matches(/^[A-Za-z ]{2,25}$/, VesselGenInfoMessages.short_name),
            call_sign: Yup.string().matches(/^[A-Za-z0-9][a-zA-Z0-9-_.]{1,7}$/, VesselGenInfoMessages.call_sign),
            official_number: Yup.string().matches(/^[0-9]{1,10}$/, VesselGenInfoMessages.official_number),
            hull_number: Yup.string().matches(/^[A-Za-z0-9][a-zA-Z0-9-_.]{11}$/, "Please enter 12 aplphabets/digits only"),
            dead_weight: Yup.string().matches(/^[0-9]+$/, "Please enter digits only").required(VesselGenInfoMessages.dead_weight),
        }),
    }

    /** Load searched port */
    const searchPorts = async (port: any) => {
        if (port.length >= 3) {
            const response = await apiGlobal.get(`port_master/filter_by_port_name/?port_name=${port}`).then(res => {
                return res.data;
            }).catch(err => {
                if (errResponse.includes(err.response.status)) {
                    errorToast("Internal error occured, please contact the admin");
                }
            })
            return response;
        }
    }

    /** Load searched country */
    // const searchCountries = async (port: any) => {
    //     if (port.length >= 3) {
    //         const response = await apiGlobal.get(`port_master/filter_by_port_name/?port_name=${port}`).then(res => {
    //             return res.data;
    //         }).catch(err => {
    //             if (errResponse.includes(err.response.status)) {
    //                 errorToast("Internal error occured, please contact the admin");
    //             }
    //         })
    //         return response;
    //     }
    // }

    /** Open-close modal */
    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function handleAcceptedFiles(files: any) {
        files.map((file: any) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setselectedFiles(files);
    }

    return (
        <React.Fragment>
            {VesselGenInfoObjectLoading && <Loading message='Loading required info!' />}
            {VesselGenInfoObjectError && getInitialValues()}
            {!VesselGenInfoObjectLoading &&
                <Formik
                    initialValues={VesselGenInfoFormik.initialValues}
                    validationSchema={VesselGenInfoFormik.validationSchema}
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(false);
                        /** Post submit actions based on response */
                        const handleResponse = (response: any) => {
                            if (response.status === 201 || response.status === 200) {
                                successToast("Data saved successfully!");
                                queryClient.invalidateQueries(queryKeyes.vessel.VesselGenInfoObject.key);
                            }
                        }
                        values.image = selectedFiles;
                        if (VesselGenInfoObject && VesselGenInfoObject[0]?.id > 0) {
                            apiGlobal.put(`vessel_general_information${VesselGenInfoObject[0]?.id}/`, values).then(res => {
                                handleResponse(res);
                            }).catch((err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            }));
                        } else {
                            apiGlobal.post(`vessel_general_information/`, values).then(res => {
                                handleResponse(res);
                            }).catch((err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            }));
                        }
                    }}>
                    {props => (
                        <Form onSubmit={props?.handleSubmit} autoComplete='off' noValidate>
                            <Row className='mb-3'>
                                <Col lg={4}>
                                    <Label>Vessel name</Label>
                                    <h5>{vessel?.vessel_name}</h5>
                                </Col>
                                <Col lg={4}>
                                    <Label className='mb-0'>Vessel short name</Label>
                                    <Field
                                        type="text"
                                        name="vessel_short_name"
                                        id="vessel_short_name"
                                        className="form-control"
                                    />
                                    {props?.errors &&
                                        <Label className='error'>{props?.errors?.vessel_short_name as string}</Label>
                                    }
                                </Col>
                                <Col lg={4}>
                                    <Label>IMO number</Label>
                                    <h5>{vessel?.IMO_number}</h5>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col lg={4}>
                                    <Label>Vessel type</Label>
                                    <h5>{vessel?.vessel_type}</h5>
                                </Col>
                                <Col lg={4}>
                                    <Label>Vessel sub type name</Label>
                                    <h5>{vessel?.vessel_sub_type}</h5>
                                </Col>
                                <Col lg={4}>
                                    <Label className='mb-0'>Select flag administrator</Label>
                                    <Field name="country_name">
                                        {({ field }: FieldProps) => (
                                            <AsyncSelect
                                                name={field.name}
                                                cacheOptions
                                                defaultOptions
                                                loadOptions={loadCountries}
                                                getOptionLabel={(e: any) => e?.country_name}
                                                getOptionValue={(e: any) => e?.id}
                                                onChange={(e: any) => props?.setFieldValue(field.name, e.id)}
                                                menuPortalTarget={document.body}
                                                defaultValue={(VesselGenInfoObject && VesselGenInfoObject[0]?.id > 0) && {
                                                    id: VesselGenInfoObject[0]?.country_name,
                                                    country_name: VesselGenInfoObject[0]?.country
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col lg={4}>
                                    <Label className='mb-0'>Enter port of registry</Label>
                                    <Field name="port_of_registry">
                                        {({ field }: FieldProps) => (
                                            <AsyncSelect
                                                name={field.name}
                                                cacheOptions
                                                defaultOptions
                                                loadOptions={(e: any) => searchPorts(e)}
                                                getOptionLabel={(e: any) => e.port_name}
                                                getOptionValue={(e: any) => e.id}
                                                onChange={(selectedOption: any) => props?.setFieldValue(field.name, selectedOption.id)}
                                                menuPortalTarget={document.body}
                                                styles={customStyle}
                                                defaultValue={(VesselGenInfoObject && VesselGenInfoObject[0]?.id > 0) &&
                                                {
                                                    id: VesselGenInfoObject[0]?.port_of_registry,
                                                    port_name: VesselGenInfoObject[0]?.port_name
                                                }
                                                }
                                                noOptionsMessage={(e: any) => {
                                                    if (e?.inputValue?.toString()?.length > 2) {
                                                        return 'Please select the Other option and enter the port name in the textbox provided'
                                                    }
                                                    return 'Please enter the first 3 characters of port name';
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Col>
                                <Col lg={4}>
                                    <Label className='mb-0'>Call sign</Label>
                                    <Field
                                        type="text"
                                        name="call_sign"
                                        id="call_sign"
                                        className="form-control"
                                    />
                                    {props?.errors &&
                                        <Label className='error'>{props?.errors?.call_sign as string}</Label>
                                    }
                                </Col>
                                <Col lg={4}>
                                    <Label className='mb-0'>Select vessel owner</Label>
                                    <Field name="vessel_owner">
                                        {({ field }: FieldProps) => (
                                            <AsyncSelect
                                                name={field.name}
                                                cacheOptions
                                                defaultOptions
                                                loadOptions={loadVesselOwners}
                                                getOptionLabel={(e: any) => e.company_name}
                                                getOptionValue={(e: any) => e.id}
                                                onChange={(e: any) => props?.setFieldValue(field.name, e.id)}
                                                menuPortalTarget={document.body}
                                                defaultValue={(VesselGenInfoObject && VesselGenInfoObject[0]?.id > 0) && {
                                                    id: VesselGenInfoObject[0]?.vessel_owner,
                                                    company_name: VesselGenInfoObject[0]?.vessel_owner_name
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col lg={4}>
                                    <Label>Date of built</Label>
                                    <Field name="date_of_built">
                                        {({ field, form }: FieldProps) => (
                                            <input
                                                type="date"
                                                name={field.name}
                                                max={'9999-12-31T00:00'}
                                                className='datetimepicker text-uppercase form-control mt-0 w-12'
                                                defaultValue={VesselGenInfoObject[0]?.date_of_built ?? null}
                                                onChange={(e: any) => props?.handleChange(e)}
                                            />
                                        )}
                                    </Field>
                                </Col>
                                <Col lg={4}>
                                    <Label className='mb-0'>Official number</Label>
                                    <Field
                                        type="text"
                                        name="official_number"
                                        id="official_number"
                                        className="form-control"
                                    />
                                    {props?.errors &&
                                        <Label className='error'>{props?.errors?.official_number as string}</Label>
                                    }
                                </Col>
                                <Col lg={4}>
                                    <Label className='mb-0'>Select hull type</Label>
                                    <Field name="hull_type_name">
                                        {({ field }: FieldProps) => (
                                            <AsyncSelect
                                                name={field.name}
                                                cacheOptions
                                                defaultOptions
                                                loadOptions={loadHullTypes}
                                                getOptionLabel={(e: any) => e.hull_type_name}
                                                getOptionValue={(e: any) => e.id}
                                                onChange={(e: any) => props?.setFieldValue(field.name, e.id)}
                                                menuPortalTarget={document.body}
                                                defaultValue={(VesselGenInfoObject && VesselGenInfoObject[0]?.id > 0) && {
                                                    id: VesselGenInfoObject[0]?.hull_type_name,
                                                    hull_type_name: VesselGenInfoObject[0]?.hull_type
                                                }}
                                            />
                                        )}
                                    </Field>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col lg={4}>
                                    <Label className='mb-0'>Hull number</Label>
                                    <Field
                                        type="text"
                                        name="hull_number"
                                        id="hull_number"
                                        className="form-control"
                                    />
                                    {props?.errors &&
                                        <Label className='error'>{props?.errors?.hull_number as string}</Label>
                                    }
                                </Col>
                                <Col lg={4}>
                                    <Label className='mb-0'>Deadweight</Label>
                                    <Field
                                        type="text"
                                        name="dead_weight"
                                        id="dead_weight"
                                        className="form-control"
                                    />
                                    {props?.errors &&
                                        <Label className='error'>{props?.errors?.dead_weight as string}</Label>
                                    }
                                </Col>
                                <Col lg={4}>
                                    <Label className='mb-0'>Vessel description</Label>
                                    <Field name="port_of_registry">
                                        {({ field }: FieldProps) => (
                                            <Input
                                                name={field.name}
                                                type="textarea"
                                                id="vessel_description"
                                                className="form-control"
                                                rows={4}
                                            />
                                        )}
                                    </Field>
                                    {props?.errors &&
                                        <Label className='error'>{props?.errors?.vessel_description as string}</Label>
                                    }
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Row>
                                    <h5>Normal ballast draft</h5>
                                </Row>
                                <Row>
                                    <Col lg={4}>
                                        <Label className='mb-0'>Fwd draft</Label>
                                        <Field
                                            type="text"
                                            name="normal_ballast_fwd_draft"
                                            id="normal_ballast_fwd_draft"
                                            className="form-control"
                                        />
                                        {props?.errors &&
                                            <Label className='error'>{props?.errors?.normal_ballast_fwd_draft as string}</Label>
                                        }
                                    </Col>
                                    <Col lg={4}>
                                        <Label className='mb-0'>Mid draft</Label>
                                        <Field
                                            type="text"
                                            name="normal_ballast_mid_draft"
                                            id="normal_ballast_mid_draft"
                                            className="form-control"
                                        />
                                        {props?.errors &&
                                            <Label className='error'>{props?.errors?.normal_ballast_mid_draft as string}</Label>
                                        }
                                    </Col>
                                    <Col lg={4}>
                                        <Label className='mb-0'>Aft draft</Label>
                                        <Field
                                            type="text"
                                            name="normal_ballast_aft_draft"
                                            id="normal_ballast_aft_draft"
                                            className="form-control"
                                        />
                                        {props?.errors &&
                                            <Label className='error'>{props?.errors?.normal_ballast_aft_draft as string}</Label>
                                        }
                                    </Col>
                                </Row>
                            </Row>
                            <Row className='mb-3'>
                                <Row>
                                    <h5>Normal laden draft</h5>
                                </Row>
                                <Row>
                                    <Col lg={4}>
                                        <Label className='mb-0'>Fwd draft</Label>
                                        <Field
                                            type="text"
                                            name="normal_laden_fwd_draft"
                                            id="normal_laden_fwd_draft"
                                            className="form-control"
                                        />
                                        {props?.errors &&
                                            <Label className='error'>{props?.errors?.normal_laden_fwd_draft as string}</Label>
                                        }
                                    </Col>
                                    <Col lg={4}>
                                        <Label className='mb-0'>Mid draft</Label>
                                        <Field
                                            type="text"
                                            name="normal_laden_mid_draft"
                                            id="normal_laden_mid_draft"
                                            className="form-control"
                                        />
                                        {props?.errors &&
                                            <Label className='error'>{props?.errors?.normal_laden_mid_draft as string}</Label>
                                        }
                                    </Col>
                                    <Col lg={4}>
                                        <Label className='mb-0'>Aft draft</Label>
                                        <Field
                                            type="text"
                                            name="normal_laden_aft_draft"
                                            id="normal_laden_aft_draft"
                                            className="form-control"
                                        />
                                        {props?.errors &&
                                            <Label className='error'>{props?.errors?.normal_laden_aft_draft as string}</Label>
                                        }
                                    </Col>
                                </Row>
                            </Row>
                            <Row className='mb-3'>
                                <Col lg={4}>
                                    <Label
                                        onClick={() => {
                                            tog_backdrop();
                                        }}
                                        data-toggle="modal"
                                        className='mb-3 link_color_blue w_8'
                                        id='img_upload'
                                    >Upload vessel image</Label>
                                    <Modal
                                        isOpen={modal_backdrop}
                                        toggle={() => {
                                            tog_backdrop();
                                        }}
                                        backdrop={"static"}
                                        id="staticBackdrop"
                                    >
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="staticBackdropLabel">
                                                Upload image
                                            </h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                onClick={() => {
                                                    setmodal_backdrop(false);
                                                }}
                                                aria-label="Close"
                                            ></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="form-floating mb-3">
                                                <Dropzone
                                                    onDrop={acceptedFiles => {
                                                        handleAcceptedFiles(acceptedFiles);

                                                    }}
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div className="dropzone">
                                                            <div
                                                                className="dz-message needsclick mt-2"
                                                                {...getRootProps()}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <div className="mb-3">
                                                                    <i className="display-4 text-muted bx bx-cloud-upload" />
                                                                </div>
                                                                <h4>Click here to upload.</h4>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                <div className="dropzone-previews mt-3" id="file-previews">
                                                    {selectedFiles.map((f: any, i: number) => {
                                                        return (
                                                            <Card
                                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                key={i + "-file"}
                                                            >
                                                                <div className="p-2">
                                                                    <Row className="align-items-center">
                                                                        <Col className="col-auto">
                                                                            <img
                                                                                data-dz-thumbnail=""
                                                                                height="80"
                                                                                className="avatar-sm rounded bg-light"
                                                                                alt={f.name}
                                                                                src={f.preview}
                                                                            />
                                                                        </Col>
                                                                        <Col>
                                                                            <Link
                                                                                to="#"
                                                                                className="text-muted font-weight-bold"
                                                                            >
                                                                                {f.name}
                                                                            </Link>
                                                                            <p className="mb-0">
                                                                                <h5>{f.formattedSize}</h5>
                                                                                <strong>{f.formattedSize}</strong>
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Card>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                </Col>
                            </Row>
                            <Row>
                                <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                    <Button type="submit" color="primary" className='btn_size4_5_cstm'>Save</Button>
                                    <Button type="reset" color="danger" className='btn_size4_5_cstm'>Reset</Button>
                                </div>
                            </Row>
                            <FormValuesDebug values={[props?.values, props?.errors, VesselGenInfoFormik.initialValues]} />
                        </Form>
                    )}
                </Formik>
            }
        </React.Fragment>

    )
}

export default VesselGeneralInfo;