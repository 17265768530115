import ErrorComponent from 'Components/ErrorComponent'
import Loading from 'Components/Loading'
import ReportDetailsHeader from 'Components/ReportDetailsHeader'
import { errorToast, successToast } from 'Components/Toasts'
import * as Yup from "yup";
import { loadMachineryRunningHoursObject, loadVesselRunningHoursMachinery } from 'VesselMaster/vesselMaster.hooks'
import { Field, FieldArray, Formik } from 'formik'
import apiGlobal from 'global/api.global'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { Row, Col, Form, Label, Button, Card, CardHeader, CardBody, CardFooter, Input } from 'reactstrap'
import { queryKeyes } from 'shared/queryKeys'
import FormValuesDebug from 'utils/debugTools/FormValuesDebug'
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import { queryClient } from 'react-query/queryClient';
import { errResponse } from 'GenericForms/Helper';
import env from 'environment/env';
import ErrorTooltip from 'Components/ErrorTooltip';

interface RunningHoursComponentType {
    VesselID: number,
    VoyageID: number,
    ReportID: number,
    toggleTab: any,
    activeTab: any
}

const RunningHoursComponent = ({
    VesselID,
    VoyageID,
    ReportID,
    toggleTab,
    activeTab
}: RunningHoursComponentType) => {
    /** Queries */
    /** Load Vessel Machinary */
    const { data: RunningHrsMachineries, isLoading: RunningHrsMachineriesLoading, isError: RunningHrsMachineriesError } = useQuery(
        [queryKeyes.vessel.vesselRunningHoursMachinery.key, VesselID],
        async () => {
            return await loadVesselRunningHoursMachinery(VesselID);
        },
        { staleTime: Infinity }
    )
    /** Machinery running hours object used for edit */
    const { data: RunningHoursObject, isLoading: RunningHoursObjectLoading, isError: RunningHoursObjectError } = useQuery(
        [queryKeyes.vessel.RunningHoursObject.key, VesselID, ReportID],
        async () => {
            return await loadMachineryRunningHoursObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** Queries end */

    /** Assign initial values to formik object */
    const getInitialValues = () => {
        let obj: any[] = [];
        if (RunningHoursObject && RunningHoursObject?.length > 0) {
            return RunningHoursObject;
        } else if (RunningHrsMachineries !== undefined && RunningHrsMachineries.length > 0 && obj.length <= RunningHrsMachineries.length) {
            RunningHrsMachineries.map((machine: any) => {
                obj.push({
                    machinery_name: machine.machinery_name,
                    precedence_id: machine.precedence_id,
                    vessel_machinery: machine.id,
                    running_hours: null,
                    machinery: machine.vessel_machinery_name,
                    vessel_name: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID
                })
                return machine;
            })
            return obj;
        }
    }

    /** useEffect */
    useEffect(() => {
        RunningHoursFormik.initialValues.runningHrs = getInitialValues();
    }, [RunningHrsMachineries, RunningHoursObject])
    /** useEffect end */

    /** Running Hours Formik object */
    let RunningHoursFormik = {
        initialValues: {
            runningHrs: getInitialValues(),
        },
        validationSchema: Yup.object().shape({
               runningHrs: Yup.array().of(
            Yup.object().shape({
                running_hours: Yup.string()
                    .matches(/^(?:25(?:\.00?)?|(?:[0-1]?\d|2[0-4])(?:\.\d{1,2})?)$/, `${commonValidationMessages.max25}`)
                    .required(commonValidationMessages.required),
            })
        ),
        })
    }

    return (
        <Card className='p-0 mb-0 border-0'>
            <CardHeader className='p-2'>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Machinery Running Hours</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(RunningHrsMachineriesLoading || RunningHoursObjectLoading) && <Loading message='Loading required data!' />}
            {RunningHrsMachineriesError && <ErrorComponent message='Unable to load required data!' />}
            {RunningHoursObjectError && getInitialValues()}
            {!(RunningHrsMachineriesLoading || RunningHoursObjectLoading) && !RunningHrsMachineriesError &&
                <Formik
                    onSubmit={(values: any, actions: any) => {
                        actions.setSubmitting(false);
                        const handleResponse = (response: any) => {
                            if (response.status === 201 || response.status === 200) {
                                successToast("Data saved successfully!");
                                queryClient.invalidateQueries(queryKeyes.vessel.RunningHoursObject.key);
                                if (env?.form_validation === true) {
                                    toggleTab(activeTab + 1);
                                }
                            }
                        }
                        /** Running hours submit */
                        if (RunningHoursObject?.length > 0 && RunningHoursObject[0].id > 0) {
                            values.runningHrs.map((val: any) => {
                                apiGlobal.put(`/machinery_running_hours/${val.id}/`, val).then(res => {
                                    handleResponse(res);
                                }).catch(err => {
                                    if (errResponse.includes(err.response.status)) {
                                        errorToast("Internal error occured, please contact the admin");
                                    }
                                })
                                return '';
                            })
                        } else {
                            apiGlobal.post(`/machinery_running_hours/`, values.runningHrs).then(res => {
                                handleResponse(res);
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin")
                                }
                            })
                        }
                    }}
                    initialValues={RunningHoursFormik.initialValues}
                    validationSchema={RunningHoursFormik.validationSchema}
                >
                    {({ values, errors, handleSubmit, handleChange, setErrors, touched, handleBlur}: { values: any, errors: any, handleSubmit: any, handleChange: any, setErrors: any, handleBlur: any, touched: any}) => (
                        <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                            <CardBody className='engineer-card-body'>
                                <Row>
                                    <Col sm={{ size: 6, offset: 3 }}>
                                        <div className="table-responsive p-0">
                                            <table className="table mb-2 mt-2">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className='align-middle p-2 sr-no-width'>#</th>
                                                        <th className='align-middle p-2'>Machinery</th>
                                                        <th className='align-middle p-2 text-center asteric' style={{ width: '35%' }}>Running hours</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <FieldArray name='runningHrs'>
                                                        {() => (
                                                            <>
                                                                {values && values?.runningHrs?.sort((a: any, b: any) => a.machinery_name - b.machinery_name).map((hrs: any, index: any) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className='align-middle p-2 text-center'>{index + 1}</td>
                                                                            <td className='align-middle p-2'>{hrs.machinery}</td>
                                                                            <td className='align-middle p-2 text-center'>
                                                                                <div className='d-inline-block'>
                                                                                    <div className="input-group">
                                                                                        <Field name={`runningHrs.${values.runningHrs.indexOf(hrs)}.running_hours`}>
                                                                                        {()=>(
                                                                                            <Input
                                                                                            type='text'
                                                                                            name={`runningHrs.${values.runningHrs.indexOf(hrs)}.running_hours`}
                                                                                            id={`running_hours-${values.runningHrs.indexOf(hrs)}`}
                                                                                            className="form-control max-width-7 text-right"
                                                                                            onBlur={handleBlur}
                                                                                            onChange={(e: any)=>handleChange(e)}
                                                                                            defaultValue={values.runningHrs[(values.runningHrs.indexOf(hrs))]?.running_hours}
                                                                                        />)}</Field>
                                                                                        <div className="input-group-text round_border">hr</div>
                                                                                    </div>
                                                                                </div>
                                                                                {errors?.runningHrs && touched?.runningHrs && touched?.runningHrs[(values.runningHrs.indexOf(hrs))]?.running_hours
                                                                                && errors?.runningHrs[(values.runningHrs.indexOf(hrs))]?.running_hours && env?.form_validation === true &&
                                                                                    <ErrorTooltip
                                                                                        target={`running_hours-${values.runningHrs.indexOf(hrs)}`}
                                                                                        message={errors?.runningHrs[(values.runningHrs.indexOf(hrs))]?.running_hours}
                                                                                        open={(errors?.runningHrs && errors?.runningHrs[(values.runningHrs.indexOf(hrs))]?.running_hours) ? true : false}
                                                                                    />
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className='p-2 py-3'>
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5">
                                        <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={(e) => {
                                            if (env?.form_validation === false) {
                                                toggleTab(activeTab + 1);
                                            }
                                        }}>Next <i className="bx bx-chevron-right ms-1" /></Button>
                                        <Button type="button" color="primary" className="btn_size_cstm" onClick={(e) => {
                                            setErrors({})
                                            toggleTab(activeTab - 1);
                                        }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug values={[values, errors, RunningHoursFormik.initialValues]} />
                        </Form>
                    )}
                </Formik>
            }
        </Card>
    )
}

export default RunningHoursComponent