import { Field, FieldProps, useFormikContext } from 'formik';
import React from 'react'
import { Input } from 'reactstrap';

const SpOpEnergyConsumptionComponent = ({
    energyConsumptions,
    handleChange,
    AuxEngineMaxSFOC,
    EnergyObject,
    loadwsum
}: any) => {
    const { values }: { values: any } = useFormikContext();
    return (
        <div className='d-inline-block'>
            <div className="input-group">
                <Field name={`withkWhMeter.functional.${values.withkWhMeter.functional.indexOf(energyConsumptions)}.energy_consumed`}>
                    {({ field, form }: FieldProps) => (
                        <Input
                            type="text"
                            className="form-control max-width-7 text-right"
                            id={energyConsumptions.fuel_type}
                            name={field.name}
                            onBlur={(e: any) => {
                                handleChange(e);
                                form.setFieldValue(`withkWhMeter.functional.${values?.withkWhMeter?.functional?.indexOf(energyConsumptions)}.equivalent_fuel_consumption`,
                                    (AuxEngineMaxSFOC?.max_eedi_eexi_technical_file * (e.target.value) / 1000000)?.toFixed(4));
                            }}
                            defaultValue={
                                values?.withkWhMeter?.functional[values?.withkWhMeter?.functional?.indexOf(energyConsumptions)]?.energy_consumed
                            }
                            disabled={values?.withkWhMeter?.functional[values?.withkWhMeter?.functional?.indexOf(energyConsumptions)]?.is_kwh_meter_not_functional === true ? true : false}
                            value={((EnergyObject && EnergyObject?.length > 0) && values?.withkWhMeter?.functional
                            [values?.withkWhMeter?.functional?.indexOf(energyConsumptions)]?.is_kwh_meter_not_functional === true) ?
                                values?.withkWhMeter?.functional
                                [values?.withkWhMeter?.functional?.indexOf(energyConsumptions)]?.energy_consumed :
                                values?.withkWhMeter?.functional
                                [values?.withkWhMeter?.functional?.indexOf(energyConsumptions)]?.is_kwh_meter_not_functional === true ?
                                    loadwsum(values?.withkWhMeter?.nonfunctional?.filter((item: any) =>
                                        item?.vessel_machinery_name === energyConsumptions.vessel_machinery_ec_group), field.name, form)
                                    : null}
                        />
                    )}
                </Field>
                <div className="input-group-text">kWh</div>
            </div>
        </div>
    )
}

export default SpOpEnergyConsumptionComponent