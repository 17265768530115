import axios from "axios";
import { BaseURL } from "../../global/api.global";

export let verification = false;
export const loginApi = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  try {
    const respose = await axios.post(BaseURL + "/login/", {
      email: username,
      password,
    });
    if (respose.status === 200) {
      verification = true;
    } else {
      verification = false;
    }
    return respose.data;
  } catch (error) {
    throw new Error("Login failed");
  }
};
export const setAuthData = (data: any) => {
  if (data) {
    sessionStorage.setItem("authData", data);
  }
};
export const isLoggedIn = () => {
  const token = getAuthData("token");
  return !!token;
};

export const getAuthData = (param: string = "") => {
  let authString = sessionStorage.getItem("authData");
  if (authString) {
    let authArray = JSON.parse(authString) ?? [];
    if (param) {
      return authArray[param] ?? [];
    }
    return authArray;
  }
  return null;
};

export const clearLogout = () => {
  localStorage.clear();
  sessionStorage.clear();
  // sessionStorage.setItem("authData", { roles: [] } as any);
};

export const hasRole = (role: string) => {
  const roles: any[] = getAuthData("roles");
  return roles.some(
    (item: { role_constant: string }) => item.role_constant === role
  );
};
