import React, { useState } from 'react';
import { CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import MachineryMaster from './MachineryMaster';
import FuelConsumption from './FuelConsumer';
import EnergyConsumer from './EnergyConsumer';

const VesselMachineryConfig = () => {
    const [activeSettingsTab, setActiveSettingsTab] = useState('1');

    const toggle = (tab: any) => {
        if (activeSettingsTab !== tab) {
            setActiveSettingsTab(tab);
        }
    }

    return (
        <>
            <CardBody className='pb-0 pt-0'>
                <Nav tabs className='nav-tabs-custom nav-justified'>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === "1",
                            })}
                            onClick={() => {
                                toggle("1");
                            }}
                        >
                            Vessel machinery List
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === "2",
                            })}
                            onClick={() => {
                                toggle("2");
                            }}
                        >
                            Fuel Consumer
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === "3",
                            })}
                            onClick={() => {
                                toggle("3");
                            }}
                        >
                            Energy Consumer
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === "4",
                            })}
                            onClick={() => {
                                toggle("4");
                            }}
                        >
                            Other
                        </NavLink>
                    </NavItem>
                </Nav>
            </CardBody>
            <TabContent activeTab={activeSettingsTab} className="p-3 text-muted">
                <TabPane tabId="1" className='m-0'>
                    <MachineryMaster />
                </TabPane>
                <TabPane tabId="2">
                    <FuelConsumption />
                </TabPane>
                <TabPane tabId="3">
                    <EnergyConsumer />
                </TabPane>
            </TabContent>
        </>
    )
}

export default VesselMachineryConfig