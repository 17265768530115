import React, { useEffect, useState } from 'react'
import { Label, Modal } from 'reactstrap';
import '../global/GlobalCSS.css';

const SubmitErrorPopup = (props: any) => {
    /** State varibles */
    const [modalState, setModalState] = useState(false);
    const { state, modalId } = props;
    /** State varibles end */

    /** useEffect */
    useEffect(() => {
        setModalState(state);
    }, [state])
    /** useEffect end */

    /** Open close modal */
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_backdrop() {
        setModalState(false);
        removeBodyCss();
    }
    return (

        <Modal
            isOpen={modalState}
            toggle={() => {
                tog_backdrop();
            }}
            backdrop={"static"}
            id={modalId}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                    Report
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                        setModalState(false);
                    }}
                    aria-label="Close"
                ></button>
            </div>
            <div className="modal-body">
                <div className='text-center'><i className='bx bx-error-circle submitErrorIcon' /></div>
                <div className='text-center'>
                    <Label className='icon_s20'>Please submit earlier reports first!</Label>
                </div>
            </div>
        </Modal >
    )
}

export default SubmitErrorPopup