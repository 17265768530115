import React from 'react'
import { Roles } from 'shared/constants'
import { hasRole } from 'utils/auth/authUtils'

const N2AdjustmentTableHeadComponent = () => {
    return (
        <thead className="table-light">
            <tr>
                <th className='p-2 align-middle sr-no-width'>#</th>
                <th className='p-2 align-middle'>Duration</th>
                {!(hasRole(Roles.CHIEFENGINEER)) &&
                    <th className='p-2 align-middle text-center' colSpan={2} style={{ width: '40%' }}>Actions</th>
                }
            </tr>
        </thead>
    )
}

export default N2AdjustmentTableHeadComponent