import React from 'react'

function VesselTableHeadComponent() {
    return (
        <thead className="table-light">
            <tr>
                <th className='p-2 align-middle sr-no-width'>#</th>
                <th className='p-2 align-middle' style={{ width: '15%' }}>Vessel report name</th>
                <th className='p-2 align-middle text-center' style={{ width: '12%'}}>Report type</th>
                <th className='p-2 align-middle text-center' style={{ width: '15%'}}>Reporting time</th>
                <th className='p-2 align-middle text-center' style={{ width: '20%' }} colSpan={5}>Actions</th>
            </tr>
        </thead>
    )
}

export default VesselTableHeadComponent