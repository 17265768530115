import ErrorComponent from 'Components/ErrorComponent';
import ErrorTooltip from 'Components/ErrorTooltip';
import Loading from 'Components/Loading';
import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import { errorToast, successToast } from 'Components/Toasts';
import ToolTip from 'Components/ToolTip';
import { TooltipMsg } from 'Components/ToolTipMessage';
import { commonValidationMessages, WeatherDataValidationMessages } from 'Components/ValidationErrorMessages';
import { calculateTimeDurationBetwnReports, errResponse } from 'GenericForms/Helper';
import { loadCurrentDirectionOptions, loadWeatherData } from 'VesselMaster/vesselMaster.hooks';
import env from 'environment/env';
import { Field, FieldProps, Formik } from 'formik';
import apiGlobal from 'global/api.global';
import React, { useEffect } from 'react'
import { useQuery } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import AsyncSelect from 'react-select/async';
import { Row, Col, Form, Input, Label, Button, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { customStyle } from 'shared/CommonCSS';
import { ReportingEvents, ReportingOpConstant } from 'shared/constants';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import * as Yup from 'yup';

interface WeatherDataType {
    lastReocrd: any,
    record: any,
    vessel: any,
    VesselID: number,
    VoyageID: number,
    ReportID: number,
    activeTab: number,
    toggleTab: any
}

const WeatherData = ({
    lastReocrd,
    record,
    vessel,
    VesselID,
    VoyageID,
    ReportID,
    activeTab,
    toggleTab
}: WeatherDataType) => {
    /** Fields' visibility logic */
    const fieldVisibility: any = {
        steamingTime: [
            ReportingEvents.NOON_RFA_AS, ReportingEvents.EOSP, ReportingEvents.HASP
        ],
        distanceTravelled: [
            ReportingEvents.HEAVE_ANCHOR, ReportingEvents.DEPARTURE_FROM_BERTH
        ],
        avgGroundSpeed: [
            ReportingEvents.NOON_RFA_AS, ReportingEvents.EOSP, ReportingEvents.HASP
        ],
        avgWaterSpeed: [
            ReportingEvents.NOON_RFA_AS, ReportingEvents.EOSP, ReportingEvents.HASP, ReportingEvents.AT_BERTH,
            ReportingEvents.DEPARTURE_FROM_BERTH
        ],
        windDirection: [
            ReportingEvents.NOON_RFA_AS, ReportingEvents.EOSP, ReportingEvents.HASP, ReportingEvents.AT_BERTH,
            ReportingEvents.DEPARTURE_FROM_BERTH, ReportingEvents.NOON_FWE_IP, ReportingEvents.DROP_ANCHOR, ReportingEvents.DRIFTING_IP,
            ReportingEvents.DEPARTURE_FROM_DRIFTING_IP, ReportingEvents.HEAVE_ANCHOR, ReportingEvents.NOON_SBE_IP, ReportingEvents.BOSP,
            ReportingEvents.DRIFTING_AS, ReportingEvents.DEPARTURE_FROM_DRIFTING_AS, ReportingEvents.ROSP, ReportingEvents.NOON_FWE_AS,
            ReportingEvents.NOON_SBE_AS
        ],
        windSpeed: [
            ReportingEvents.NOON_RFA_AS, ReportingEvents.EOSP, ReportingEvents.HASP, ReportingEvents.AT_BERTH,
            ReportingEvents.DEPARTURE_FROM_BERTH, ReportingEvents.NOON_FWE_IP, ReportingEvents.DROP_ANCHOR, ReportingEvents.DRIFTING_IP,
            ReportingEvents.DEPARTURE_FROM_DRIFTING_IP, ReportingEvents.HEAVE_ANCHOR, ReportingEvents.NOON_SBE_IP, ReportingEvents.BOSP,
            ReportingEvents.DRIFTING_AS, ReportingEvents.DEPARTURE_FROM_DRIFTING_AS, ReportingEvents.ROSP, ReportingEvents.NOON_FWE_AS,
            ReportingEvents.NOON_SBE_AS
        ],
        beaufortScale: [
            ReportingEvents.NOON_RFA_AS, ReportingEvents.EOSP, ReportingEvents.HASP, ReportingEvents.AT_BERTH,
            ReportingEvents.DEPARTURE_FROM_BERTH, ReportingEvents.NOON_FWE_IP, ReportingEvents.DROP_ANCHOR, ReportingEvents.DRIFTING_IP,
            ReportingEvents.DEPARTURE_FROM_DRIFTING_IP, ReportingEvents.HEAVE_ANCHOR, ReportingEvents.NOON_SBE_IP, ReportingEvents.BOSP,
            ReportingEvents.DRIFTING_AS, ReportingEvents.DEPARTURE_FROM_DRIFTING_AS, ReportingEvents.ROSP, ReportingEvents.NOON_FWE_AS,
            ReportingEvents.NOON_SBE_AS
        ],
        swellDirection: [
            ReportingEvents.NOON_RFA_AS, ReportingEvents.EOSP, ReportingEvents.HASP, ReportingEvents.NOON_FWE_IP, ReportingEvents.DROP_ANCHOR,
            ReportingEvents.DRIFTING_IP, ReportingEvents.DEPARTURE_FROM_DRIFTING_IP, ReportingEvents.HEAVE_ANCHOR, ReportingEvents.NOON_SBE_IP,
            ReportingEvents.BOSP, ReportingEvents.DRIFTING_AS, ReportingEvents.DEPARTURE_FROM_DRIFTING_AS, ReportingEvents.ROSP,
            ReportingEvents.NOON_FWE_AS, ReportingEvents.NOON_SBE_AS
        ],
        swellHeight: [
            ReportingEvents.NOON_RFA_AS, ReportingEvents.EOSP, ReportingEvents.HASP, ReportingEvents.NOON_FWE_IP, ReportingEvents.DROP_ANCHOR,
            ReportingEvents.DRIFTING_IP, ReportingEvents.DEPARTURE_FROM_DRIFTING_IP, ReportingEvents.HEAVE_ANCHOR, ReportingEvents.NOON_SBE_IP,
            ReportingEvents.BOSP, ReportingEvents.DRIFTING_AS, ReportingEvents.DEPARTURE_FROM_DRIFTING_AS, ReportingEvents.ROSP,
            ReportingEvents.NOON_FWE_AS, ReportingEvents.NOON_SBE_AS
        ],
        oceanCurrentSpeed: [
            ReportingEvents.NOON_RFA_AS, ReportingEvents.EOSP, ReportingEvents.HASP, ReportingEvents.NOON_FWE_IP, ReportingEvents.DROP_ANCHOR,
            ReportingEvents.DRIFTING_IP, ReportingEvents.DEPARTURE_FROM_DRIFTING_IP, ReportingEvents.HEAVE_ANCHOR, ReportingEvents.NOON_SBE_IP,
            ReportingEvents.BOSP, ReportingEvents.DRIFTING_AS, ReportingEvents.DEPARTURE_FROM_DRIFTING_AS, ReportingEvents.ROSP,
            ReportingEvents.NOON_FWE_AS, ReportingEvents.NOON_SBE_AS
        ],
        oceanWaveHeight: [
            ReportingEvents.NOON_RFA_AS, ReportingEvents.EOSP, ReportingEvents.HASP, ReportingEvents.NOON_FWE_IP, ReportingEvents.DROP_ANCHOR,
            ReportingEvents.DRIFTING_IP, ReportingEvents.DEPARTURE_FROM_DRIFTING_IP, ReportingEvents.HEAVE_ANCHOR, ReportingEvents.NOON_SBE_IP,
            ReportingEvents.BOSP, ReportingEvents.DRIFTING_AS, ReportingEvents.DEPARTURE_FROM_DRIFTING_AS, ReportingEvents.ROSP,
            ReportingEvents.NOON_FWE_AS, ReportingEvents.NOON_SBE_AS
        ],
        seaForce: [
            ReportingEvents.NOON_RFA_AS, ReportingEvents.EOSP, ReportingEvents.HASP, ReportingEvents.NOON_FWE_IP, ReportingEvents.DROP_ANCHOR,
            ReportingEvents.DRIFTING_IP, ReportingEvents.DEPARTURE_FROM_DRIFTING_IP, ReportingEvents.HEAVE_ANCHOR, ReportingEvents.NOON_SBE_IP,
            ReportingEvents.BOSP, ReportingEvents.DRIFTING_AS, ReportingEvents.DEPARTURE_FROM_DRIFTING_AS, ReportingEvents.ROSP,
            ReportingEvents.NOON_FWE_AS, ReportingEvents.NOON_SBE_AS
        ],
        oceanCurrentDirection: [
            ReportingEvents.NOON_RFA_AS, ReportingEvents.EOSP, ReportingEvents.HASP, ReportingEvents.NOON_FWE_IP, ReportingEvents.DROP_ANCHOR,
            ReportingEvents.DRIFTING_IP, ReportingEvents.DEPARTURE_FROM_DRIFTING_IP, ReportingEvents.HEAVE_ANCHOR, ReportingEvents.NOON_SBE_IP,
            ReportingEvents.BOSP, ReportingEvents.DRIFTING_AS, ReportingEvents.DEPARTURE_FROM_DRIFTING_AS, ReportingEvents.ROSP,
            ReportingEvents.NOON_FWE_AS, ReportingEvents.NOON_SBE_AS
        ],
        badWeather: [
            ReportingEvents.NOON_RFA_AS, ReportingEvents.EOSP, ReportingEvents.HASP, ReportingEvents.AT_BERTH,
            ReportingEvents.DEPARTURE_FROM_BERTH, ReportingEvents.NOON_FWE_IP, ReportingEvents.DROP_ANCHOR, ReportingEvents.DRIFTING_IP,
            ReportingEvents.DEPARTURE_FROM_DRIFTING_IP, ReportingEvents.HEAVE_ANCHOR, ReportingEvents.NOON_SBE_IP, ReportingEvents.BOSP,
            ReportingEvents.DRIFTING_AS, ReportingEvents.DEPARTURE_FROM_DRIFTING_AS, ReportingEvents.ROSP, ReportingEvents.NOON_FWE_AS,
            ReportingEvents.NOON_SBE_AS
        ],
        airTemperature: [
            ReportingEvents.NOON_RFA_AS, ReportingEvents.EOSP, ReportingEvents.HASP, ReportingEvents.AT_BERTH,
            ReportingEvents.DEPARTURE_FROM_BERTH, ReportingEvents.NOON_FWE_IP, ReportingEvents.DROP_ANCHOR, ReportingEvents.DRIFTING_IP,
            ReportingEvents.DEPARTURE_FROM_DRIFTING_IP, ReportingEvents.HEAVE_ANCHOR, ReportingEvents.NOON_SBE_IP, ReportingEvents.BOSP,
            ReportingEvents.DRIFTING_AS, ReportingEvents.DEPARTURE_FROM_DRIFTING_AS, ReportingEvents.ROSP, ReportingEvents.NOON_FWE_AS,
            ReportingEvents.NOON_SBE_AS
        ],
        seaTemperature: [
            ReportingEvents.NOON_RFA_AS, ReportingEvents.EOSP, ReportingEvents.HASP, ReportingEvents.AT_BERTH,
            ReportingEvents.DEPARTURE_FROM_BERTH, ReportingEvents.NOON_FWE_IP, ReportingEvents.DROP_ANCHOR, ReportingEvents.DRIFTING_IP,
            ReportingEvents.DEPARTURE_FROM_DRIFTING_IP, ReportingEvents.HEAVE_ANCHOR, ReportingEvents.NOON_SBE_IP, ReportingEvents.BOSP,
            ReportingEvents.DRIFTING_AS, ReportingEvents.DEPARTURE_FROM_DRIFTING_AS, ReportingEvents.ROSP, ReportingEvents.NOON_FWE_AS,
            ReportingEvents.NOON_SBE_AS
        ],
        gyroCourse: [
            ReportingEvents.NOON_RFA_AS, ReportingEvents.EOSP, ReportingEvents.HASP, ReportingEvents.BOSP, ReportingEvents.ROSP
        ],
        barometricPressure: [
            ReportingEvents.NOON_RFA_AS, ReportingEvents.EOSP, ReportingEvents.HASP, ReportingEvents.AT_BERTH,
            ReportingEvents.DEPARTURE_FROM_BERTH, ReportingEvents.NOON_FWE_IP, ReportingEvents.DROP_ANCHOR, ReportingEvents.DRIFTING_IP,
            ReportingEvents.DEPARTURE_FROM_DRIFTING_IP, ReportingEvents.HEAVE_ANCHOR, ReportingEvents.NOON_SBE_IP, ReportingEvents.BOSP,
            ReportingEvents.DRIFTING_AS, ReportingEvents.DEPARTURE_FROM_DRIFTING_AS, ReportingEvents.ROSP, ReportingEvents.NOON_FWE_AS,
            ReportingEvents.NOON_SBE_AS
        ],
    }

    const calculateSteamingTime = () => {
        let steamingTime = calculateTimeDurationBetwnReports(lastReocrd?.reporting_time_utc, record?.reporting_time_utc);
        steamingTime = ((!isNaN(steamingTime)) ? steamingTime : 0);
        if (record?.reporting_operation_mode === ReportingOpConstant.SEA_PASSAGE) {
            return steamingTime;
        } else {
            return 0;
        }
    }

    /** Queries start */
    /** ETAD object used for edit */
    const { data: WeatherDataObject, isLoading: WeatherDataObjectLoading, isError: WeatherDataObjectError }:
        { data: any, isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.WeatherDataObject.key, VesselID, ReportID],
            async () => {
                return await loadWeatherData(VesselID, ReportID);
            },
            { staleTime: Infinity }
        )
    /** Queries end */

    /** Assign values to initial object of Weather data */
    const getInitialValues = () => {
        if (WeatherDataObject?.length > 0 && WeatherDataObject[0].id > 0) {
            return WeatherDataObject[0];
        } else {
            let obj: any;
            obj = {
                steaming_time: calculateSteamingTime(),
                distance_travelled: null,
                avg_ground_speed: null as number,
                avg_water_speed: null,
                wind_direction: null,
                wind_speed: null,
                barometric_pressure: null,
                swell_height: null,
                swell_direction: null,
                ocean_current_speed: null,
                ocean_wave_height: null,
                ocean_current_direction: null,
                sea_force: null,
                beaufort_scale: null,
                air_temperature: null,
                sea_temperature: null,
                period_in_bad_weather: 0,
                gyro_course: null,
                vessel_name: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID
            }
            return obj;
        }
    }

    /** UseEffect */
    useEffect(() => {
        WeatherDataFormik.initialValues = getInitialValues();
    }, [WeatherDataObject, getInitialValues])
    /** UseEffect end */

    /** Weather Data's formik object */
    const WeatherDataFormik = {
        initialValues: getInitialValues(),
        validationSchema: Yup.object({
            distance_travelled: Yup.string()
                .matches(/^\d{0,3}(?:\.\d{1,2})?$/, `${commonValidationMessages.before3after2}`)
                .when('$fieldVisibility', (field: any, schema) => {
                    return !fieldVisibility.distanceTravelled?.includes(record?.reporting_event)
                        ? schema.required(commonValidationMessages.required)
                        : schema.nullable();
                }),

            avg_ground_speed: Yup.number().nullable(),
            wind_direction: Yup.number()
                .when('$fieldVisibility', (fieldVisibility: any, schema) => {
                    return fieldVisibility.windDirection?.includes(record?.reporting_event)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }),
            avg_water_speed: Yup.string()
                .matches(/^[0-9]{0,2}(\.[0-9]{1,2})?$/, commonValidationMessages.before2after2)
                .when('$fieldVisibility', (fieldVisibility: any, schema) => {
                    return !fieldVisibility.avgWaterSpeed?.includes(record?.reporting_event)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }),
            wind_speed: Yup.string()
                .matches(/^[0-9]{0,2}(\.[0-9]{1,2})?$/, commonValidationMessages.before2after2)
                .when('$fieldVisibility', (fieldVisibility: any, schema) => {
                    return fieldVisibility.windSpeed?.includes(record?.reporting_event)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }),
            beaufort_scale: Yup.number().nullable(),
            swell_direction: Yup.number()
                .when('$fieldVisibility', (fieldVisibility: any, schema) => {
                    return fieldVisibility.swellDirection?.includes(record?.reporting_event)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }),
            swell_height: Yup.string()
                .matches(/^[0-9]{0,2}(\.[0-9]{1,2})?$/, commonValidationMessages.before2after2)
                .when('$fieldVisibility', (field: any, schema) => {
                    return !fieldVisibility.swellHeight?.includes(record?.reporting_event)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }),
            ocean_current_speed: Yup.string()
                .matches(/^[0-9]{0,2}(\.[0-9]{1,2})?$/, commonValidationMessages.before2after2)
                .when('$fieldVisibility', (field: any, schema) => {
                    return !fieldVisibility.oceanCurrentSpeed?.includes(record?.reporting_event)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }),
            ocean_wave_height: Yup.string()
                .matches(/^[0-9]{0,2}(\.[0-9]{1,2})?$/, commonValidationMessages.before2after2)
                .when('$fieldVisibility', (field: any, schema) => {
                    return !fieldVisibility.oceanWaveHeight?.includes(record?.reporting_event)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }),
            sea_force: Yup.number().nullable(),
            ocean_current_direction: Yup.number()
                .when('$fieldVisibility', (field: any, schema) => {
                    return !fieldVisibility.oceanCurrentDirection?.includes(record?.reporting_event)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }),
            period_in_bad_weather: Yup.string()
                .matches(/^[0-9]{0,2}(\.[0-9]{1,2})?$/, commonValidationMessages.before2after2)
                .when('$fieldVisibility', (fieldVisibility: any, schema) => {
                    return fieldVisibility.badWeather?.includes(record?.reporting_event)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }),
            air_temperature: Yup.string()
                .when('$fieldVisibility', (fieldVisibility: any, schema) => {
                    return fieldVisibility.airTemperature?.includes(record?.reporting_event)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }),
            sea_temperature: Yup.string()
                .matches(/^-?[0-9]{0,2}(\.[0-9]{1,2})?$/, `${commonValidationMessages.before2after1}`)
                .when('$fieldVisibility', (fieldVisibility: any, schema) => {
                    return fieldVisibility.seaTemperature?.includes(record?.reporting_event)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }),
            gyro_course: Yup.string()
                .matches(/^(?:\d{1,2}|[1-2]\d{2}|3[0-5]\d|360)$/, `${WeatherDataValidationMessages.gyro_course}`)
                .when('$fieldVisibility', (field: any, schema) => {
                    return !fieldVisibility.gyroCourse?.includes(record?.reporting_event)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }),
            barometric_pressure: Yup.number()
                .when('$fieldVisibility', (fieldVisibility: any, schema) => {
                    return fieldVisibility.barometricPressure?.includes(record?.reporting_event)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }),
        }),
    }

    const calculateBeaufortScale = (e: any, setFieldValue: any) => {
        let value = parseInt(e.target.value);
        let beaufortScale = 0;
        if (parseInt(e.target.value) === 0) {
            beaufortScale = 0
        } else if (1 <= value && value <= 3) {
            beaufortScale = 1
        } else if (4 <= value && value <= 6) {
            beaufortScale = 2
        } else if (7 <= value && value <= 10) {
            beaufortScale = 3
        } else if (11 <= value && value <= 16) {
            beaufortScale = 4
        } else if (17 <= value && value <= 21) {
            beaufortScale = 5
        } else if (22 <= value && value <= 27) {
            beaufortScale = 6
        } else if (28 <= value && value <= 33) {
            beaufortScale = 7
        } else if (34 <= value && value <= 40) {
            beaufortScale = 8
        } else if (41 <= value && value <= 47) {
            beaufortScale = 9
        } else if (48 <= value && value <= 55) {
            beaufortScale = 10
        } else if (56 <= value && value <= 63) {
            beaufortScale = 11
        } else if (64 <= value && value <= 71) {
            beaufortScale = 12
        }
        setFieldValue('beaufort_scale', beaufortScale);
    }

    const calculateSeaForce = (e: any, setFieldValue: any) => {
        let value = parseFloat(e.target.value);
        let seaForce = 0;
        if (value < 0) {
            seaForce = 0;
        } else if (0 <= value && value <= 0.1) {
            seaForce = 1;
        } else if (0.11 <= value && value <= 0.5) {
            seaForce = 2;
        } else if (0.51 <= value && value <= 1.25) {
            seaForce = 3;
        } else if (1.26 <= value && value <= 2.5) {
            seaForce = 4;
        } else if (2.51 <= value && value <= 4.00) {
            seaForce = 5;
        } else if (4.01 <= value && value <= 6.00) {
            seaForce = 6;
        } else if (6.01 <= value && value <= 9.00) {
            seaForce = 7;
        } else if (9.01 <= value && value <= 14.00) {
            seaForce = 8;
        } else if (14.00 < value) {
            seaForce = 9;
        }
        setFieldValue('sea_force', seaForce);
    }

    return (
        <Card className='p-0 mb-0 border-0'>
            <CardHeader className='p-2'>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Weather Data</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                        <Col className='align-middle'>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {WeatherDataObjectLoading && <Loading message='Loading required data!' />}
            {WeatherDataObjectError && <ErrorComponent message='Unable to load required data!' />}
            {!WeatherDataObjectLoading &&
                <Formik
                    onSubmit={(values: any, actions: any) => {
                        actions.setSubmitting(false);
                        if (values?.distance_travelled === null) {
                            values.distance_travelled = 0;
                        }
                        const handleResponse = (response: any) => {
                            if (response.status === 201 || response.status === 200) {
                                successToast("Data saved successfully!");
                            }
                        }
                        if (WeatherDataObject && WeatherDataObject[0]?.id) {
                            apiGlobal.put(`weather_data/${values.id}/`, values).then(res => {
                                if (env?.form_validation === true) {
                                    toggleTab(activeTab + 1);
                                }
                                handleResponse(res);
                                queryClient.invalidateQueries(queryKeyes.vessel.WeatherDataObject.key);
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            });
                        } else {
                            const res = apiGlobal.post(`weather_data/`, values).then(res => {
                                if (env?.form_validation === true) {
                                    toggleTab(activeTab + 1);
                                }
                                handleResponse(res);
                                queryClient.invalidateQueries(queryKeyes.vessel.WeatherDataObject.key);
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            });
                            return res;
                        }
                    }}
                    initialValues={WeatherDataFormik.initialValues}
                    validationSchema={WeatherDataFormik.validationSchema}
                >
                    {props => (
                        <Form onSubmit={props?.handleSubmit} autoComplete='off' noValidate>
                            <CardBody className='mt-2 deck-card-body'>
                                <Row>
                                    {fieldVisibility.steamingTime.includes(record && record?.reporting_event) &&
                                        <Col lg={3}>
                                            <Label className="asteric mb-0" for="steaming_time">
                                                Steaming time<i className='bx bx-info-circle ml-2p' id='steaming_time_msg' />
                                            </Label>
                                            <ToolTip target='steaming_time_msg'
                                                message={`${TooltipMsg.Weather_Data.filter((item: any) => item.target === 'steaming_time_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <div className="mb-2 input-group">
                                                <Field
                                                    type="text"
                                                    className="form-control text-right max-width-7"
                                                    id="steaming_time"
                                                    name="steaming_time"
                                                    disabled
                                                />
                                                <div className="input-group-text round_border">min</div>
                                            </div>
                                        </Col>
                                    }
                                    {!fieldVisibility.distanceTravelled.includes(record && record?.reporting_event) &&
                                        <Col lg={3}>
                                            <Label className="asteric mb-0" for="distance_travelled">
                                                Distance travelled<i className='bx bx-info-circle ml-2p' id='distance_travelled_msg' />
                                            </Label>
                                            <ToolTip target='distance_travelled_msg'
                                                message={`${TooltipMsg.Weather_Data.filter((item: any) => item.target === 'distance_travelled_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <div className="mb-2 input-group">
                                                <Field name="distance_travelled">
                                                    {({ field, form }: FieldProps) => (
                                                        <>
                                                            <Input
                                                                type="text"
                                                                className="form-control text-right max-width-7"
                                                                id="distance_travelled"
                                                                name="distance_travelled"
                                                                onChange={(e: any) => {
                                                                    if (e.target.value) {
                                                                        props.handleChange(e);
                                                                    } else {
                                                                        form.setFieldValue(field.name, null)
                                                                    }
                                                                    if (props?.values.steaming_time === 0 || Number.isNaN(props?.values.steaming_time)) {
                                                                        props?.setFieldValue('avg_ground_speed', 0);
                                                                    } else {
                                                                        props?.setFieldValue
                                                                            ('avg_ground_speed', (e.target.value * 60 / props?.values.steaming_time).toFixed(2));
                                                                    }
                                                                }}
                                                                onBlur={(e: any) => {
                                                                    props?.handleBlur(e)
                                                                    if (e.target.value) {
                                                                        props.handleChange(e);
                                                                    } else {
                                                                        form.setFieldValue(field.name, null)
                                                                    }
                                                                    if (props?.values.steaming_time === 0 || Number.isNaN(props?.values.steaming_time)) {
                                                                        props?.setFieldValue('avg_ground_speed', 0);
                                                                    } else {
                                                                        props?.setFieldValue
                                                                            ('avg_ground_speed', (e.target.value * 60 / props?.values.steaming_time).toFixed(2));
                                                                    }
                                                                }}
                                                                defaultValue={props?.values?.distance_travelled}
                                                            />
                                                            {props?.errors && props?.touched?.distance_travelled &&
                                                                props?.errors?.distance_travelled && env?.form_validation === true &&
                                                                <ErrorTooltip
                                                                    target='distance_travelled'
                                                                    message={props?.errors?.distance_travelled}
                                                                    open={props?.errors && props?.errors?.distance_travelled ? true : false}
                                                                />
                                                            }
                                                        </>
                                                    )}
                                                </Field>
                                                <div className="input-group-text round_border">nm</div>
                                            </div>
                                        </Col>
                                    }
                                    {fieldVisibility.avgGroundSpeed.includes(record && record?.reporting_event) &&
                                        <Col md={3}>
                                            <Label className="mb-0" for="avg_ground_speed">
                                                Avg. ground speed
                                            </Label>
                                            <div className="mb-2 input-group">
                                                <Field
                                                    type="text"
                                                    className="form-control disabled text-right max-width-7"
                                                    id="avg_ground_speed"
                                                    name="avg_ground_speed"
                                                    disabled
                                                />
                                                <div className="input-group-text round_border">knots</div>
                                            </div>
                                        </Col>
                                    }
                                    {fieldVisibility.avgWaterSpeed.includes(record && record?.reporting_event) &&
                                        <Col md={3}>
                                            <Label className="mb-0" for="avg_water_speed">
                                                Avg. water speed
                                            </Label>
                                            <div className="mb-2 input-group">
                                                <Field
                                                    type="text"
                                                    className="form-control disabled text-right max-width-7"
                                                    id="avg_water_speed"
                                                    name="avg_water_speed"
                                                />
                                                <div className="input-group-text round_border">knots</div>
                                            </div>
                                            {props?.errors && props?.errors?.avg_water_speed && env?.form_validation === true &&
                                                <ErrorTooltip
                                                    target='avg_water_speed'
                                                    message={props?.errors?.avg_water_speed}
                                                    open={props?.errors && props?.errors?.avg_water_speed ? true : false}
                                                />
                                            }
                                        </Col>
                                    }
                                    {fieldVisibility.windDirection.includes(record && record?.reporting_event) &&
                                        <Col md={3}>
                                            <Label className="asteric mb-0" for='wind_direction'>
                                                Wind direction<i className='bx bx-info-circle ml-2p' id='wind_direction_msg' />
                                            </Label>
                                            <ToolTip target='wind_direction_msg'
                                                message={`${TooltipMsg.Weather_Data.filter((item: any) => item.target === 'wind_direction_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <div className="mb-2">
                                                <Field name="wind_direction">
                                                    {({ field }: FieldProps) => (
                                                        <>
                                                            <AsyncSelect
                                                                name={field.name}
                                                                inputId={field.name}
                                                                className='max-width-13'
                                                                cacheOptions
                                                                defaultOptions
                                                                loadOptions={loadCurrentDirectionOptions}
                                                                getOptionLabel={(e: any) => e.current_direction_name}
                                                                getOptionValue={(e: any) => e.id}
                                                                onBlur={props?.handleBlur}
                                                                onChange={(e: any) => props?.setFieldValue(field.name, e.id)}
                                                                menuPortalTarget={document.body}
                                                                styles={customStyle}
                                                                defaultValue={WeatherDataObject && WeatherDataObject[0]?.id &&
                                                                {
                                                                    id: WeatherDataObject[0]?.wind_direction,
                                                                    current_direction_name: WeatherDataObject[0]?.wind_direction_name
                                                                }}
                                                            />
                                                            {props?.errors && props?.touched?.wind_direction &&
                                                                props?.errors?.wind_direction && env?.form_validation === true &&
                                                                <ErrorTooltip
                                                                    target='wind_direction'
                                                                    message={props?.errors?.wind_direction}
                                                                    open={props?.errors && props?.errors?.wind_direction ? true : false}
                                                                />
                                                            }
                                                        </>
                                                    )}
                                                </Field>
                                            </div>
                                        </Col>
                                    }
                                    {fieldVisibility.windSpeed.includes(record && record?.reporting_event) &&
                                        <Col lg={3}>
                                            <Label className="asteric mb-0" for="wind_speed">
                                                Wind speed<i className='bx bx-info-circle ml-2p' id='wind_speed_msg' />
                                            </Label>
                                            <ToolTip target='wind_speed_msg'
                                                message={`${TooltipMsg.Weather_Data.filter((item: any) => item.target === 'wind_speed_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <div className="mb-2 input-group">
                                                <Field name="wind_speed">
                                                    {() => (
                                                        <Input
                                                            type="text"
                                                            className="form-control max-width-7 text-right"
                                                            id="wind_speed"
                                                            name="wind_speed"
                                                            onBlur={props?.handleBlur}
                                                            onChange={(e: any) => {
                                                                props?.handleChange(e);
                                                                calculateBeaufortScale(e, props?.setFieldValue);
                                                            }}
                                                            defaultValue={props?.values?.wind_speed}
                                                        />
                                                    )}
                                                </Field>
                                                <div className="input-group-text round_border">knots</div>
                                            </div>
                                            {props?.errors && props?.touched?.wind_speed &&
                                                props?.errors?.wind_speed && env?.form_validation === true &&
                                                <ErrorTooltip
                                                    target='wind_speed'
                                                    message={props?.errors?.wind_speed}
                                                    open={props?.errors && props?.errors?.wind_speed ? true : false}
                                                />
                                            }
                                        </Col>
                                    }
                                    {fieldVisibility.beaufortScale.includes(record && record?.reporting_event) &&
                                        <Col lg={3}>
                                            <Label className='mb-0' for="beaufort_scale">
                                                Beaufort scale<i className='bx bx-info-circle ml-2p' id='beaufort_scale_msg' />
                                            </Label>
                                            <ToolTip target='beaufort_scale_msg'
                                                message={`${TooltipMsg.Weather_Data.filter((item: any) => item.target === 'beaufort_scale_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <div className="mb-2 input-group">
                                                <Field
                                                    type="text"
                                                    className="form-control  max-width-7 text-right"
                                                    id="beaufort_scale"
                                                    name="beaufort_scale"
                                                    disabled
                                                />
                                                <div className="input-group-text round_border">bf</div>
                                            </div>
                                        </Col>
                                    }
                                    {fieldVisibility.windDirection.includes(record && record?.reporting_event) &&
                                        <Col lg={3}>
                                            <div className="mb-2">
                                                <Label className="asteric mb-0" for='swell_direction'>Swell direction
                                                    <i className='bx bx-info-circle ml-2p' id='swell_direction_msg' />
                                                </Label>
                                                <ToolTip target='swell_direction_msg'
                                                    message={`${TooltipMsg.Weather_Data.filter((item: any) => item.target === 'swell_direction_msg').map((tool: any) => { return tool.message })}`} />
                                                <Field name="swell_direction">
                                                    {({ field, form }: FieldProps) => (
                                                        <>
                                                            <AsyncSelect
                                                                name={field?.name}
                                                                inputId={field?.name}
                                                                className='max-width-13'
                                                                cacheOptions
                                                                defaultOptions={true}
                                                                loadOptions={loadCurrentDirectionOptions}
                                                                getOptionLabel={(e: any) => e?.current_direction_name}
                                                                getOptionValue={(e: any) => e?.id}
                                                                onBlur={props?.handleBlur}
                                                                onChange={(e: any) =>
                                                                    form?.setFieldValue(field?.name, e.id)
                                                                }
                                                                menuPortalTarget={document.body}
                                                                styles={customStyle}
                                                                defaultValue={WeatherDataObject && WeatherDataObject[0]?.id &&
                                                                {
                                                                    id: WeatherDataObject[0]?.swell_direction,
                                                                    current_direction_name: WeatherDataObject[0]?.swell_direction_name
                                                                }}
                                                            />
                                                            {props?.errors && props?.touched?.swell_direction &&
                                                                props?.errors?.swell_direction && env?.form_validation === true &&
                                                                <ErrorTooltip
                                                                    target='swell_direction'
                                                                    message={props?.errors?.swell_direction}
                                                                    open={props?.errors && props?.errors?.swell_direction ? true : false}
                                                                />
                                                            }
                                                        </>
                                                    )}
                                                </Field>
                                            </div>
                                        </Col>
                                    }
                                    {fieldVisibility.swellHeight.includes(record && record?.reporting_event) &&
                                        <Col lg={3}>
                                            <Label className="asteric mb-0" for="swell_height">
                                                Swell height<i className='bx bx-info-circle ml-2p' id='swell_height_msg' />
                                            </Label>
                                            <ToolTip target='swell_height_msg'
                                                message={`${TooltipMsg.Weather_Data.filter((item: any) => item.target === 'swell_height_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <div className="mb-2 input-group">
                                                <Field name="swell_height">
                                                    {() => (
                                                        <Input
                                                            type="text"
                                                            className="form-control max-width-7 text-right"
                                                            id="swell_height"
                                                            name="swell_height"
                                                            onBlur={props?.handleBlur}
                                                            onChange={(e: any) => props?.handleChange(e)}
                                                            defaultValue={props?.values?.swell_height}
                                                        />
                                                    )}
                                                </Field>
                                                <div className="input-group-text round_border">m</div>
                                            </div>
                                            {props?.errors && props?.touched?.swell_height && props?.errors?.swell_height && env?.form_validation === true &&
                                                <ErrorTooltip
                                                    target='swell_height'
                                                    message={props?.errors?.swell_height}
                                                    open={props?.errors && props?.errors?.swell_height ? true : false}
                                                />
                                            }
                                        </Col>
                                    }
                                    {fieldVisibility.oceanCurrentSpeed.includes(record && record?.reporting_event) &&
                                        <Col lg={3}>
                                            <Label className="asteric mb-0" for="ocean_current_speed">
                                                Ocean current's speed<i className='bx bx-info-circle ml-2p' id='ocean_current_speed_msg' />
                                            </Label>
                                            <ToolTip target='ocean_current_speed_msg'
                                                message={`${TooltipMsg.Weather_Data.filter((item: any) => item.target === 'ocean_current_speed_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <div className="mb-2 input-group">
                                                <Field name="ocean_current_speed">
                                                    {() => (
                                                        <Input
                                                            type="text"
                                                            className="form-control max-width-7 text-right"
                                                            id="ocean_current_speed"
                                                            name="ocean_current_speed"
                                                            onBlur={props?.handleBlur}
                                                            onChange={(e: any) => props?.handleChange(e)}
                                                            defaultValue={props?.values?.ocean_current_speed}
                                                        />
                                                    )}
                                                </Field>
                                                <div className="input-group-text round_border">knots</div>
                                            </div>
                                            {props?.errors && props?.touched?.ocean_current_speed
                                                && props?.errors?.ocean_current_speed && env?.form_validation === true &&
                                                <ErrorTooltip
                                                    target='ocean_current_speed'
                                                    message={props?.errors?.ocean_current_speed}
                                                    open={props?.errors && props?.errors?.ocean_current_speed ? true : false}
                                                />
                                            }
                                        </Col>
                                    }
                                    {fieldVisibility.oceanWaveHeight.includes(record && record?.reporting_event) &&
                                        <Col lg={3}>
                                            <Label className="asteric mb-0" for="ocean_wave_height">
                                                Ocean wave height<i className='bx bx-info-circle ml-2p' id='ocean_wave_height_msg'></i>
                                            </Label>
                                            <ToolTip target='ocean_wave_height_msg'
                                                message={`${TooltipMsg.Weather_Data.filter((item: any) => item.target === 'ocean_wave_height_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <div className="mb-2 input-group">
                                                <Field name="ocean_wave_height">
                                                    {({ field }: FieldProps) => (
                                                        <Input
                                                            type="text"
                                                            className="form-control max-width-7 text-right"
                                                            id="ocean_wave_height"
                                                            name={field.name}
                                                            onBlur={props?.handleBlur}
                                                            onChange={(e: any) => {
                                                                props?.handleChange(e);
                                                                calculateSeaForce(e, props?.setFieldValue)
                                                            }}
                                                            defaultValue={props?.values?.ocean_wave_height}
                                                        />
                                                    )}
                                                </Field>
                                                <div className="input-group-text round_border">m</div>
                                            </div>
                                            {props?.errors && props?.touched?.ocean_wave_height
                                                && props?.errors?.ocean_wave_height && env?.form_validation === true &&
                                                <ErrorTooltip
                                                    target='ocean_wave_height'
                                                    message={props?.errors?.ocean_wave_height}
                                                    open={props?.errors && props?.errors?.ocean_wave_height ? true : false}
                                                />
                                            }
                                        </Col>
                                    }
                                    {fieldVisibility.seaForce.includes(record && record?.reporting_event) &&
                                        <Col md={3}>
                                            <Label className="asteric mb-0" for="sea_force">
                                                Sea force
                                            </Label>
                                            <div className="mb-2 input-group">
                                                <Field
                                                    type="text"
                                                    className="form-control max-width-7 text-right"
                                                    id="sea_force"
                                                    name="sea_force"
                                                    disabled
                                                />
                                                <div className="input-group-text round_border">dss</div>
                                            </div>
                                        </Col>
                                    }
                                    {fieldVisibility.oceanCurrentDirection.includes(record && record?.reporting_event) &&
                                        <Col md={3}>
                                            <div className="mb-2">
                                                <Label className="asteric mb-0" for='ocean_current_direction'>Ocean current's direction
                                                    <i className='bx bx-info-circle ml-2p' id='ocean_current_direction_msg'></i></Label>
                                                <ToolTip target='ocean_current_direction_msg'
                                                    message={`${TooltipMsg.Weather_Data.filter((item: any) => item.target === 'ocean_current_direction_msg').map((tool: any) => { return tool.message })}`} />
                                                <Field name="ocean_current_direction">
                                                    {({ field }: FieldProps) => (
                                                        <>
                                                            <AsyncSelect
                                                                name={field.name}
                                                                inputId={field.name}
                                                                className='max-width-13'
                                                                cacheOptions
                                                                defaultOptions
                                                                loadOptions={loadCurrentDirectionOptions}
                                                                getOptionLabel={(e: any) => e.current_direction_name}
                                                                getOptionValue={(e: any) => e.id}
                                                                onBlur={props?.handleBlur}
                                                                onChange={(e: any) => props?.setFieldValue(field.name, e.id)}
                                                                menuPortalTarget={document.body}
                                                                styles={customStyle}
                                                                defaultValue={WeatherDataObject && WeatherDataObject[0]?.id &&
                                                                {
                                                                    id: WeatherDataObject[0]?.ocean_current_direction,
                                                                    current_direction_name: WeatherDataObject[0]?.ocean_current_direction_name
                                                                }}
                                                            />
                                                            {props?.errors && props?.touched?.ocean_current_direction
                                                                && props?.errors?.ocean_current_direction && env?.form_validation === true &&
                                                                <ErrorTooltip
                                                                    target='ocean_current_direction'
                                                                    message={props?.errors?.ocean_current_direction}
                                                                    open={props?.errors && props?.errors?.ocean_current_direction ? true : false}
                                                                />
                                                            }
                                                        </>
                                                    )}
                                                </Field>
                                            </div>
                                        </Col>
                                    }
                                    {fieldVisibility.badWeather.includes(record && record?.reporting_event) &&
                                        <Col lg={3}>
                                            <Label className="asteric mb-0" for="period_in_bad_weather">
                                                Period in bad weather<i className='bx bx-info-circle ml-2p' id='period_in_bad_weather_msg'></i>
                                            </Label>
                                            <ToolTip target='period_in_bad_weather_msg'
                                                message={`${TooltipMsg.Weather_Data.filter((item: any) => item.target === 'period_in_bad_weather_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <div className="mb-2 input-group">
                                                <Field name="period_in_bad_weather">
                                                    {() => (
                                                        <Input
                                                            type="text"
                                                            className="form-control max-width-7 text-right"
                                                            id="period_in_bad_weather"
                                                            name="period_in_bad_weather"
                                                            onBlur={props?.handleBlur}
                                                            onChange={(e: any) => props?.handleChange(e)}
                                                            defaultValue={props?.values?.period_in_bad_weather}
                                                        />
                                                    )}
                                                </Field>
                                                <div className="input-group-text round_border">hr</div>
                                            </div>
                                            {props?.errors && props?.touched?.period_in_bad_weather
                                                && props?.errors?.period_in_bad_weather && env?.form_validation === true &&
                                                <ErrorTooltip
                                                    target='period_in_bad_weather'
                                                    message={props?.errors?.period_in_bad_weather}
                                                    open={props?.errors && props?.errors?.period_in_bad_weather ? true : false}
                                                />
                                            }
                                        </Col>
                                    }
                                    {fieldVisibility.airTemperature.includes(record && record?.reporting_event) &&
                                        <Col lg={3}>
                                            <Label className="asteric mb-0" for="air_temperature">
                                                Air temperature<i className='bx bx-info-circle ml-2p' id='air_temperature_msg'></i>
                                            </Label>
                                            <ToolTip target='air_temperature_msg'
                                                message={`${TooltipMsg.Weather_Data.filter((item: any) => item.target === 'air_temperature_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <div className="mb-2 input-group">
                                                <Field name="air_temperature">
                                                    {() => (
                                                        <Input
                                                            type="text"
                                                            className="form-control max-width-7 text-right"
                                                            id="air_temperature"
                                                            name="air_temperature"
                                                            onBlur={props?.handleBlur}
                                                            onChange={(e: any) => props?.handleChange(e)}
                                                            defaultValue={props?.values?.air_temperature}
                                                        />
                                                    )}
                                                </Field>
                                                <div className="input-group-text round_border"><sup>o</sup>C</div>
                                            </div>
                                            {props?.errors && props?.touched?.air_temperature &&
                                                props?.errors?.air_temperature && env?.form_validation === true &&
                                                <ErrorTooltip
                                                    target='air_temperature'
                                                    message={props?.errors?.air_temperature}
                                                    open={props?.errors && props?.errors?.air_temperature ? true : false}
                                                />
                                            }
                                        </Col>
                                    }
                                    {fieldVisibility.seaTemperature.includes(record && record?.reporting_event) &&
                                        <Col lg={3}>
                                            <Label className="asteric mb-0" for="sea_temperature">
                                                Sea temperature<i className='bx bx-info-circle ml-2p' id='sea_temperature_msg'></i>
                                            </Label>
                                            <ToolTip target='sea_temperature_msg'
                                                message={`${TooltipMsg.Weather_Data.filter((item: any) => item.target === 'sea_temperature_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <div className="mb-2 input-group">
                                                <Field name="sea_temperature">
                                                    {() => (
                                                        <Input
                                                            type="text"
                                                            className="form-control max-width-7 text-right"
                                                            id="sea_temperature"
                                                            name="sea_temperature"
                                                            onBlur={props?.handleBlur}
                                                            onChange={(e: any) => props?.handleChange(e)}
                                                            defaultValue={props?.values?.sea_temperature}
                                                        />
                                                    )}
                                                </Field>
                                                <div className="input-group-text round_border"><sup>o</sup>C</div>
                                            </div>
                                            {props?.errors && props?.touched?.sea_temperature &&
                                                props?.errors?.sea_temperature && env?.form_validation === true &&
                                                <ErrorTooltip
                                                    target='sea_temperature'
                                                    message={props?.errors?.sea_temperature}
                                                    open={props?.errors && props?.errors?.sea_temperature ? true : false}
                                                />
                                            }
                                        </Col>
                                    }
                                    {fieldVisibility.gyroCourse.includes(record && record?.reporting_event) &&
                                        <Col lg={3}>
                                            <Label className="asteric mb-0" for="gyro_course">
                                                Gyro course<i className='bx bx-info-circle ml-2p' id='gyro_course_msg'></i>
                                            </Label>
                                            <ToolTip target='gyro_course_msg'
                                                message={`${TooltipMsg.Weather_Data.filter((item: any) => item.target === 'gyro_course_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <div className="mb-2 input-group">
                                                <Field name="gyro_course">
                                                    {() => (
                                                        <Input
                                                            type="text"
                                                            className="form-control max-width-7 text-right"
                                                            id="gyro_course"
                                                            name="gyro_course"
                                                            onBlur={props?.handleBlur}
                                                            onChange={(e: any) => props?.handleChange(e)}
                                                            defaultValue={props?.values?.gyro_course}
                                                        />
                                                    )}
                                                </Field>
                                                <div className="input-group-text round_border"><sup>o</sup>C</div>
                                            </div>
                                            {props?.errors && props?.touched?.gyro_course
                                                && props?.errors?.gyro_course && env?.form_validation === true &&
                                                <ErrorTooltip
                                                    target='gyro_course'
                                                    message={props?.errors?.gyro_course}
                                                    open={props?.errors && props?.errors?.gyro_course ? true : false}
                                                />
                                            }
                                        </Col>
                                    }
                                    {fieldVisibility.barometricPressure.includes(record && record?.reporting_event) &&
                                        <Col lg={3}>
                                            <Label className="asteric mb-0" for="barometric_pressure">
                                                Barometric pressure<i className='bx bx-info-circle ml-2p' id='barometric_pressure_msg'></i>
                                            </Label>
                                            <ToolTip target='barometric_pressure_msg'
                                                message={`${TooltipMsg.Weather_Data.filter((item: any) => item.target === 'barometric_pressure_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <div className="mb-2 input-group">
                                                <Field name="barometric_pressure">
                                                    {() => (
                                                        <Input
                                                            type="text"
                                                            className="form-control max-width-7 text-right"
                                                            id="barometric_pressure"
                                                            name="barometric_pressure"
                                                            onBlur={props?.handleBlur}
                                                            onChange={(e: any) => props?.handleChange(e)}
                                                            defaultValue={props?.values?.barometric_pressure}
                                                        />
                                                    )}
                                                </Field>
                                                <div className="input-group-text round_border">bar</div>
                                            </div>
                                            {props?.errors && props?.touched?.barometric_pressure
                                                && props?.errors?.barometric_pressure && env?.form_validation === true &&
                                                <ErrorTooltip
                                                    target='barometric_pressure'
                                                    message={props?.errors?.barometric_pressure}
                                                    open={props?.errors && props?.errors?.barometric_pressure ? true : false}
                                                />
                                            }
                                        </Col>
                                    }
                                </Row>
                            </CardBody>
                            <CardFooter className='p-2 py-3'>
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5">
                                        <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={() => {
                                            if (env?.form_validation === false) {
                                                toggleTab(activeTab + 1);
                                            }
                                        }}>Next <i className="bx bx-chevron-right ms-1" />
                                        </Button>
                                        <Button type="submit" color="primary" className="btn_size_cstm disabled" onClick={(e) => {
                                            toggleTab(activeTab - 1);
                                        }}><i className="bx bx-chevron-left me-1" /> Previous
                                        </Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug values={[props?.values, props?.errors, WeatherDataFormik.initialValues]} />
                        </Form>
                    )}
                </Formik >
            }
        </Card >
    )
}

export default WeatherData