import React from 'react';
import { Modal, Card, Row, Col, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { formatBytes } from 'GenericForms/Helper';

const ReportingFileUploadModal = (props: any) => {
    /** State varibles */
    const { state, setState, array, setArray, modalId, title, size, save } = props;
    /** State varibles end */

    /** Open close modal */
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_backdrop() {
        setState(!state);
        removeBodyCss();
    }

    /** Show uploaded files */
    function handleAcceptedFiles(files: any) {
        files.map((file: any) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setArray(files);
    }

    return (
        <Modal
            isOpen={state}
            toggle={() => {
                tog_backdrop();
            }}
            backdrop={"static"}
            id={modalId}
            size={size}
        >
            <div className="modal-header p-2">
                <h5 className="modal-title" id="staticBackdropLabel">
                    {title}
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                        setState(false);
                    }}
                    aria-label="Close"
                ></button>
            </div>
            <div className="modal-body p-2 form-floating">
                <Dropzone
                    onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles);

                    }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                            <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <i className="display-4 text-muted bx bx-cloud-upload" />
                                <h5>Click here to upload</h5>
                            </div>
                        </div>
                    )}
                </Dropzone>
                <div className="dropzone-previews mt-2" id="file-previews">
                    {array.map((f: any, i: number) => {
                        return (
                            <Card
                                className="mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                            >
                                <div className="p-2">
                                    <Row className="align-items-center">
                                        <Col className="col-auto">
                                            <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                            />
                                        </Col>
                                        <Col>
                                            <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                            >
                                                {f.name}
                                            </Link>
                                            <p className="mb-0">
                                                <strong>{f.formattedSize}</strong>
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        );
                    })}
                </div>
                <Row className='mt-2'>
                    <Col>
                        <Button className='btn justify_right' color='primary' onClick={save} >Save</Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default ReportingFileUploadModal