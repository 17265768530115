import Loading from 'Components/Loading'
import { errorToast } from 'Components/Toasts'
import { errResponse } from 'GenericForms/Helper'
import { loadVesselOtherSettingsOperation } from 'VesselMaster/vesselMaster.hooks'
import { Field, Formik } from 'formik'
import apiGlobal from 'global/api.global'
import { RootState } from 'index'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { queryClient } from 'react-query/queryClient'
import { useSelector } from 'react-redux'
import { Button, Form, Label, Row } from 'reactstrap'
import { queryKeyes } from 'shared/queryKeys'
import FormValuesDebug from 'utils/debugTools/FormValuesDebug'

const SteamDumpValve = () => {
    /** State variables */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [count, setCount] = useState(0);
    /** State variables end */

    /** Queries */
    /** Vessel's other setting;s object used for edit */
    const { data: OtherSettings, isLoading: OtherSettingsLoading, isError: OtherSettingsError }:
        { data: any, isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.VesselOtherSettingsObject.key, VesselID],
            async () => { return await loadVesselOtherSettingsOperation(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );
    /** End Queries */

    /** Assign initial values to formik object */
    const getInitialValues = () => {
        if (OtherSettings && OtherSettings[0]?.id > 0) {
            return OtherSettings[0];
        } else {
            return ({
                reefer_container_name: "",
                power_rating: null as number,
                is_steam_dump_valve_in_use: false,
                vessel: VesselID
            })
        }
    }

    /** Other Setting's formik object */
    const OtherSettingFormik = {
        initialValues: getInitialValues(),
    }
    return (
        <>
            {OtherSettingsLoading && <Loading message='Loading required data!' />}
            {OtherSettingsError && setCount(count + 1)}
            {!OtherSettingsLoading &&
                <Formik
                    key={count}
                    initialValues={OtherSettingFormik.initialValues}
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(false);
                        if (OtherSettings && OtherSettings[0]?.id > 0) {
                            apiGlobal.put(`/other_setting/${values?.id}/`, values).then(res => {
                                if (res.status === 200) {
                                    queryClient.invalidateQueries(queryKeyes.vessel.VesselOtherSettingsObject.key);
                                }
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            });
                        } else {
                            apiGlobal.post(`/other_setting/`, values).then(res => {
                                if (res.status === 201) {
                                    queryClient.invalidateQueries(queryKeyes.vessel.VesselOtherSettingsObject.key);
                                }
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            });
                        }
                    }}
                >
                    {props => (
                        <Form autoComplete="off" onSubmit={props?.handleSubmit} noValidate>
                            <div className='ele_row1'>
                                <Label className='mr-1'>Is Steam dump valve used?</Label>
                                <div className="form-check mb-3">
                                    <Field
                                        name='is_steam_dump_valve_in_use'
                                        className="form-check-input"
                                        type="checkbox"
                                        id='steamCheckbox'
                                    />
                                </div>
                            </div>
                            <Row>
                                <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                    <Button type="submit" color="primary" className='btn_size4_5_cstm'>Save</Button>
                                    <Button type="reset" color="danger" className='btn_size4_5_cstm'>Reset</Button>
                                </div>
                            </Row>
                            <FormValuesDebug values={[props?.values, props?.errors, OtherSettingFormik.initialValues]} />
                        </Form>
                    )}
                </Formik>
            }
        </>
    )
}

export default SteamDumpValve;
