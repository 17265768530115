import React, { useState, useEffect } from 'react';
import { Button, CardBody, Label, Popover, PopoverBody } from "reactstrap";
import '../global/GlobalCSS.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '..';
import { VesselState, getReportsAction, setVesselState, setReportID } from "../Store/Generic/ReportingSlice";
import VoyageInformation from '../VoyageInformation/VoyageInformation';
import ToolTip from '../Components/ToolTip';
import CaptainIcon from '../Media/DC9.png';
import EngineerIcon from '../Media/E4.png';
import CaptainBlue from '../Media/DC3.png';
import EditIcon from '../Media/ED2.png';
import EngineerBlue from '../Media/E5.png';
import { DO_ENGG_ReportingStatus, ReportingStatusConstant, Roles, VoyageConstant } from '../shared/constants';
import EditVesselReport from './EditVesselReport';
import { queryKeyes } from 'shared/queryKeys';
import { vesselVoyageReportingList } from 'VesselMaster/vesselMaster.hooks';
import { useQuery } from 'react-query';
import CreateVesselReport from './CreateVesselReport';
import apiGlobal from 'global/api.global';
import ViewReport from '../Media/VD2.png'
import ViewReportModal from '../Components/ViewReportModal';
import SubmitErrorPopup from 'Components/SubmitErrorPopup';
import { hasRole } from 'utils/auth/authUtils';
import { queryClient } from 'react-query/queryClient';
import ErrorComponent from 'Components/ErrorComponent';
import VesselTableHeadComponent from './VesselTableHeadComponent';

const OngoingReports = () => {
    //const [modal_backdrop_view, setmodal_backdrop_view] = useState(false);
    const [viewReport, setViewReport] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [submitPopover, setSubmitPopover] = useState(false);
    const [submitReport, setSubmitReport] = useState(0);
    const [reportId, setReportId] = useState<any>(0);
    const dispatch = useDispatch();

    // State Varibles for the Vessels 
    const { VesselState, Voyages, VoyageID, VesselID } = useSelector((state: RootState) => state.Reporting);
    const voyage = Voyages.find((rec: any) => rec.id === VoyageID);
    const handleVesselState = (reportId: number, record: VesselState) => {
        dispatch(setReportID(reportId))
        dispatch(setVesselState(record))
    }

    /**
     * fucntion returns Vessel Voyage Reporting information 
     * @param number VesselID
     * @param number VoyageID
     * @returns mix
     */
    const { data: reports, isLoading: reportsLoading, isError: reportsError } = useQuery(
        [queryKeyes.vessel.vesselVoyageReporting.key, VesselID, VoyageID],
        async () => { return await vesselVoyageReportingList(VesselID, VoyageID) },
        { staleTime: Infinity })

    useEffect(() => {
        dispatch(getReportsAction('vessel_reporting_information' as string));
    }, [dispatch]);

    useEffect(() => {
        queryClient.invalidateQueries(queryKeyes.vessel.vesselVoyageReporting.key);
    }, [])

    const handleViewChanges = (id: number) => {
        setViewReport(true);
        setReportId(id);
    }

    const handleReportSubmit = async (report: any): Promise<any | null> => {
        try {
            const check = await apiGlobal.get(`vessel_reporting_information/check_previous_reports/?vessel_id=${VesselID}&vessel_reporting_information_id=${report.id}`)
            if (check.data.result === true) {
                await apiGlobal.post(`/submit/`, ({
                    vessel_id: VesselID,
                    vessel_reporting_information_id: report.id
                })).then(res => {
                    apiGlobal.post(`/submit/`, ({
                        vessel_id: VesselID,
                        vessel_reporting_information_id: report.id
                    }));
                });
                report.vessel_reporting_status = ReportingStatusConstant.SENT;
                const res = await apiGlobal.put(`/vessel_reporting_information/${report.id}/`, report);
                vesselVoyageReportingList(VesselID, VoyageID);
                queryClient.invalidateQueries(queryKeyes.vessel.vesselVoyageReporting.key);
                setSubmitPopover(false);
                return res.data;
            } else {
                setReportId(report.id);
                setSubmitError(true);
                setSubmitPopover(false);
            }
        } catch (err) {
            console.error(err);
            return null;
        }
    };

    return (
        <React.Fragment>
            {(voyage && (voyage.voyage_status === VoyageConstant.ONGOING || voyage.voyage_status === VoyageConstant.NEWVOYAGE)) &&
                <div className='ele_row mb-2'>
                    <h4 id='owner_table' className='m-0'>Vessel Reporting Information</h4>
                    {
                        (((
                            hasRole(Roles.ES_ADMIN) ||
                            hasRole(Roles.MASTER)
                        )
                        )// && (reports && ((reports[reports?.length - 1]?.vessel_reporting_status === ReportingStatusConstant.SENT) || (reports && reports?.length === 0))))
                        ) &&
                        <button color='primary' className='btn btn-primary' onClick={() => { handleVesselState(-1, 'CREATE_VESSEL_REPORTING') }}>
                            <i className="dripicons-plus font-size-16 align-middle me-2" />
                            Create New
                        </button>
                    }
                </div>}
            <CardBody className='p-0'>
                <div className="table-responsive" id='owner_table'>
                    {reportsLoading && (
                        (<div
                            className="alert-border-left mb-0 alert alert-info alert-dismissible fade show"
                            role="alert">
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            <strong>Loading</strong> - Vessel reporting information</div>)
                    )}
                    {reportsError && <ErrorComponent message="Error loading required data!" />}
                    {((!reportsLoading && !reportsError) && reports.length > 0) &&
                        <table className="table mb-0">
                            <VesselTableHeadComponent />
                            <tbody>
                                {reports.length > 0 && reports?.sort((a: any, b: any) => b.id - a.id).filter((item: any) => item.vessel_reporting_status === ReportingStatusConstant.OPEN)
                                    .map((report: any, index: number) => (
                                        <>
                                            <tr key={report.id} className=''>
                                                <td className="p-2 align-middle text-center">{index + 1}</td>
                                                <td className="p-2 align-middle">{report.reporting_event_name}</td>
                                                <td className="p-2 align-middle text-center">{report.reporting_type}</td>
                                                <td className="p-2 align-middle text-center">{report.reporting_time_utc.slice(0, 10)} {report.reporting_time_utc.slice(11, 16)}</td>
                                                <td className="p-2 align-middle text-center">
                                                    {
                                                        <ToolTip target='view_details' message='View details' /> &&
                                                        (
                                                            hasRole(Roles.ES_ADMIN) ||
                                                            hasRole(Roles.MASTER) ||
                                                            hasRole(Roles.CHIEFENGINEER) ||
                                                            hasRole(Roles.MANAGER)
                                                        ) &&
                                                        <img
                                                            alt="View Report"
                                                            className='align-middle pointer'
                                                            src={ViewReport}
                                                            height="29"
                                                            id='view_details'
                                                            onClick={() => {
                                                                handleViewChanges(report.id)
                                                            }}
                                                        />
                                                    }
                                                </td>
                                                <td className="p-2 align-middle text-center">
                                                    {
                                                        <ToolTip target='edit_details' message='Edit reporting details' /> &&
                                                        (
                                                            hasRole(Roles.ES_ADMIN) ||
                                                            hasRole(Roles.MANAGER)
                                                        ) &&
                                                        (
                                                            <img
                                                                src={EditIcon}
                                                                alt="Edit Icon"
                                                                height="28"
                                                                className='pointer'
                                                                id='edit_details'
                                                                onClick={() => { handleVesselState(report.id, 'EDIT') }}
                                                            />
                                                        )
                                                    }
                                                </td>
                                                <td className="p-2 align-middle text-center">
                                                    {
                                                        (
                                                            hasRole(Roles.ES_ADMIN) ||
                                                            hasRole(Roles.MASTER)
                                                        ) &&
                                                        (<ToolTip target='DO_reporting' message='Deck officer reporting' /> &&
                                                            (report.officer_reporting_status === DO_ENGG_ReportingStatus.COMPLETE ?
                                                                <img
                                                                    alt="Deck Officer Report"
                                                                    className='pointer'
                                                                    src={CaptainBlue}
                                                                    height="30"
                                                                    id='DO_reporting'
                                                                    onClick={() => { handleVesselState(report.id, 'DO_REPORTING') }}
                                                                />
                                                                : <img
                                                                    alt="Deck Officer Report"
                                                                    className='pointer'
                                                                    src={CaptainIcon}
                                                                    height="30"
                                                                    id='DO_reporting'
                                                                    onClick={() => { handleVesselState(report.id, 'DO_REPORTING') }}
                                                                />)
                                                        )
                                                    }
                                                </td>
                                                <td className="p-2 align-middle text-center">
                                                    {
                                                        (
                                                            hasRole(Roles.ES_ADMIN) ||
                                                            hasRole(Roles.CHIEFENGINEER)
                                                        ) &&
                                                        (<ToolTip target='Engg_reporting' message='Engineer reporting' /> &&
                                                            report.engineer_reporting_status === DO_ENGG_ReportingStatus.COMPLETE ?
                                                            <img
                                                                alt="Engineer Report"
                                                                src={EngineerBlue}
                                                                className='pointer'
                                                                height="30"
                                                                id='Engg_reporting'
                                                                onClick={() => { handleVesselState(report.id, 'ENGG_REPORTING') }}
                                                            />
                                                            : <img
                                                                alt="Engineer Report"
                                                                src={EngineerIcon}
                                                                className='pointer'
                                                                height="30"
                                                                id='Engg_reporting'
                                                                onClick={() => { handleVesselState(report.id, 'ENGG_REPORTING') }}
                                                            />
                                                        )
                                                    }
                                                </td>
                                                <td className="p-2 align-middle text-center">
                                                    {
                                                        (
                                                            hasRole(Roles.ES_ADMIN) ||
                                                            hasRole(Roles.MASTER)
                                                        ) &&
                                                        (report.officer_reporting_status === DO_ENGG_ReportingStatus.COMPLETE && report.engineer_reporting_status === DO_ENGG_ReportingStatus.COMPLETE) &&
                                                        <>
                                                            <i className='bx bx-send icon_s23 align-middle pointer ms-2' onClick={() => {
                                                                setSubmitPopover(true);
                                                                setSubmitReport(report);
                                                                setReportId(report.id);
                                                            }} id={`sent_report_${report.id}`} />
                                                            {reportId === report.id && <SubmitErrorPopup state={submitError} modalId={(report.id).toString()} />}
                                                        </>
                                                    }
                                                    {reportId === report.id &&
                                                        <ViewReportModal
                                                            state={viewReport}
                                                            setState={setViewReport}
                                                            vesselId={VesselID}
                                                            reportId={reportId}
                                                            modalId={`${VesselID}${reportId}`}
                                                        />}
                                                </td>
                                            </tr >
                                        </>
                                    ))
                                }
                            </tbody>
                        </table>
                    }
                </div>
                {submitPopover &&
                    <div style={{ container: 'body' }}>
                        <Popover
                            placement="top"
                            isOpen={submitPopover}
                            target={`sent_report_${reportId}`}
                            toggle={() => {
                                setSubmitPopover(!submitPopover);
                            }}
                        >
                            <PopoverBody>
                                <Label><strong>Are you sure you want to submit the form?</strong></Label>
                                <Button type="button" color="primary" className='mr-1 mt-3 popover_btn' onClick={() => handleReportSubmit(submitReport)}
                                >Yes</Button>
                                <Button type="button" color="danger" className='mt-3' onClick={() => setSubmitPopover(false)}>No</Button>
                            </PopoverBody>
                        </Popover>
                    </div>
                }
            </CardBody>
            {VesselState === 'EDIT' && <EditVesselReport />}
            {VesselState === 'VOYAGE_REPORTING' && <VoyageInformation />}
            {VesselState === 'CREATE_VESSEL_REPORTING' && <CreateVesselReport />}
        </React.Fragment >
    )
}

export default OngoingReports